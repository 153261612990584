var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{ref:"context",attrs:{"overflow-scroll":"","max-height-if-outside-viewport":""}},[_c('div',{staticClass:"context__menu-title"},[_vm._v(_vm._s(_vm.field.name)+" ("+_vm._s(_vm.field.id)+")")]),_vm._v(" "),_c('ul',{staticClass:"context__menu"},[(
        _vm.$hasPermission(
          'database.table.field.update',
          _vm.field,
          _vm.database.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item"},[_c('a',{ref:"updateFieldContextLink",staticClass:"context__menu-item-link grid-view__description-options",on:{"click":function($event){return _vm.$refs.updateFieldContext.toggle(
            _vm.$refs.updateFieldContextLink,
            'bottom',
            'left',
            0
          )}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-edit-pencil"}),_vm._v("\n        "+_vm._s(_vm.$t('fieldContext.editField'))+"\n      ")]),_vm._v(" "),_c('UpdateFieldContext',{ref:"updateFieldContext",attrs:{"table":_vm.table,"view":_vm.view,"field":_vm.field,"all-fields-in-table":_vm.allFieldsInTable,"database":_vm.database},on:{"update":function($event){return _vm.$emit('update', $event)},"updated":function($event){return _vm.$refs.context.hide()}}})],1):_vm._e(),_vm._v(" "),(
        _vm.field.primary &&
        _vm.$hasPermission(
          'database.table.field.update',
          _vm.field,
          _vm.database.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.$refs.changePrimaryFieldModal.show()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-coins-swap"}),_vm._v("\n        "+_vm._s(_vm.$t('fieldContext.changePrimaryField'))+"\n      ")]),_vm._v(" "),_c('ChangePrimaryFieldModal',{ref:"changePrimaryFieldModal",attrs:{"all-fields-in-table":_vm.allFieldsInTable,"from-field":_vm.field,"table":_vm.table}})],1):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(
        !_vm.field.primary &&
        !_vm.syncedUniquePrimary &&
        _vm.$hasPermission(
          'database.table.field.delete',
          _vm.field,
          _vm.database.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item context__menu-item--with-separator"},[_c('a',{staticClass:"context__menu-item-link context__menu-item-link--delete",class:{ 'context__menu-item-link--loading': _vm.deleteLoading },on:{"click":function($event){return _vm.deleteField()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-bin"}),_vm._v("\n        "+_vm._s(_vm.$t('fieldContext.deleteField'))+"\n      ")])]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }