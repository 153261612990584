<template>
  <p class="ab-text">
    <slot />
  </p>
</template>

<script>
/**
 * @typedef ABImage
 */

export default {
  name: 'ABParagraph',
}
</script>
