export default function ({ $config, app }) {
  if ($config.METICULOUS_PROJECT_ID) {
    app.head.script.push({
      src: 'https://snippet.meticulous.ai/v1/meticulous.js',
      'data-project-id': $config.METICULOUS_PROJECT_ID,
      'data-is-production-environment': $config.METICULOUS_PRODUCTION
        ? 'true'
        : 'false',
    })
  }
}
