<template>
  <div ph-autocapture>
    <Header></Header>
    <nuxt />
    <Footer></Footer>
    <CookieNotification></CookieNotification>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Header from '@saas/components/Header'
import Footer from '@saas/components/Footer'
import CookieNotification from '@saas/components/CookieNotification'

export default {
  components: {
    Header,
    Footer,
    CookieNotification,
  },
  middleware: ['saasLayout', 'removeTrailingSlash'],
  head() {
    return {
      titleTemplate: '',
      htmlAttrs: {
        lang: 'en',
      },
      bodyAttrs: {
        class: ['saas-page', this.navOpen ? 'saas-menu--open' : ''],
      },
    }
  },
  computed: {
    ...mapGetters({
      navOpen: 'header/getNavOpen',
    }),
  },
}
</script>
