var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"context__form-container"},[_c('FormGroup',{attrs:{"small-label":"","label":_vm.$t('selectAIModelForm.AIType'),"error":_vm.$v.values.ai_generative_ai_type.$error,"required":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [(
          _vm.$v.values.ai_generative_ai_type.$dirty &&
          !_vm.$v.values.ai_generative_ai_type.required
        )?_c('div',[_vm._v("\n        "+_vm._s(_vm.$t('error.requiredField'))+"\n      ")]):_vm._e()]},proxy:true}])},[_c('Dropdown',{staticClass:"dropdown--floating",attrs:{"error":_vm.$v.values.ai_generative_ai_type.$errors,"fixed-items":true,"show-search":false},on:{"hide":function($event){return _vm.$v.values.ai_generative_ai_type.$touch()},"change":function($event){return _vm.$refs.aiModel.select(_vm.aIModelsPerType[0])}},model:{value:(_vm.values.ai_generative_ai_type),callback:function ($$v) {_vm.$set(_vm.values, "ai_generative_ai_type", $$v)},expression:"values.ai_generative_ai_type"}},_vm._l((_vm.aITypes),function(aiType){return _c('DropdownItem',{key:aiType.getType(),attrs:{"name":aiType.getName(),"value":aiType.getType()}})}),1)],1),_vm._v(" "),_c('FormGroup',{attrs:{"small-label":"","label":_vm.$t('selectAIModelForm.AIModel'),"error":_vm.$v.values.ai_generative_ai_model.$error,"required":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [(
          _vm.$v.values.ai_generative_ai_model.$dirty &&
          !_vm.$v.values.ai_generative_ai_model.required
        )?_c('div',[_vm._v("\n        "+_vm._s(_vm.$t('error.requiredField'))+"\n      ")]):_vm._e()]},proxy:true}])},[_c('Dropdown',{ref:"aiModel",staticClass:"dropdown--floating",attrs:{"error":_vm.$v.values.ai_generative_ai_model.$error,"fixed-items":true,"show-search":false},on:{"hide":function($event){return _vm.$v.values.ai_generative_ai_model.$touch()}},model:{value:(_vm.values.ai_generative_ai_model),callback:function ($$v) {_vm.$set(_vm.values, "ai_generative_ai_model", $$v)},expression:"values.ai_generative_ai_model"}},_vm._l((_vm.aIModelsPerType),function(aIType){return _c('DropdownItem',{key:aIType,attrs:{"name":aIType,"value":aIType}})}),1)],1),_vm._v(" "),_c('FormGroup',{attrs:{"small-label":"","label":_vm.$t('selectAIModelForm.temperatureLabel'),"help-icon-tooltip":_vm.$t('selectAIModelForm.temperatureDescription', { max: _vm.maxTemperature }),"error":_vm.$v.values.ai_temperature.$error,"required":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [(_vm.$v.values.ai_temperature.$error)?_c('div',[_vm._v("\n        "+_vm._s(_vm.temperatureErrorMessage)+"\n      ")]):_vm._e()]},proxy:true}])},[_c('FormInput',{attrs:{"step":0.1,"min":0,"max":_vm.maxTemperature,"type":"number","error":_vm.$v.values.ai_temperature.$error},on:{"blur":function($event){return _vm.$v.values.ai_temperature.$touch()}},model:{value:(_vm.temperature),callback:function ($$v) {_vm.temperature=$$v},expression:"temperature"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }