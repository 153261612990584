var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-preview__wrapper",class:`page-preview__wrapper--${_vm.deviceType.type}`,on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.actionSelectElement({ element: null })}}},[_c('PreviewNavigationBar',{style:({ maxWidth: _vm.maxWidth }),attrs:{"page":_vm.currentPage}}),_vm._v(" "),_c('div',{ref:"preview",staticClass:"page-preview",style:({ 'max-width': _vm.maxWidth })},[_c('div',{ref:"previewScaled",staticClass:"page-preview__scaled",attrs:{"tabindex":"0"},on:{"keydown":_vm.handleKeyDown}},[_c('ThemeProvider',{staticClass:"page"},[(_vm.headerElements.length !== 0)?[_c('header',{staticClass:"page__header",class:{
              'page__header--element-selected':
                _vm.pageSectionWithSelectedElement === 'header',
            }},_vm._l((_vm.headerElements),function(element,index){return _c('ElementPreview',{key:element.id,attrs:{"element":element,"is-first-element":index === 0,"is-copying":_vm.copyingElementIndex === index,"application-context-additions":{
                recordIndexPath: [],
              }},on:{"move":function($event){return _vm.moveElement($event)}}})}),1),_vm._v(" "),_c('div',{staticClass:"page-preview__separator"},[_c('span',{staticClass:"page-preview__separator-label"},[_vm._v("\n              "+_vm._s(_vm.$t('pagePreview.header'))+"\n            ")])])]:_vm._e(),_vm._v(" "),(_vm.elements.length === 0)?[_c('CallToAction',{staticClass:"page-preview__empty",attrs:{"icon":"baserow-icon-plus","icon-color":"neutral","icon-size":"large","icon-rounded":""},on:{"click":function($event){return _vm.$refs.addElementModal.show()}}},[_vm._v("\n            "+_vm._s(_vm.$t('pagePreview.emptyMessage'))+"\n          ")])]:[_c('div',{staticClass:"page__content",class:{
              'page__content--element-selected':
                _vm.pageSectionWithSelectedElement === 'content',
            }},_vm._l((_vm.elements),function(element,index){return _c('ElementPreview',{key:element.id,attrs:{"element":element,"is-first-element":index === 0 && _vm.headerElements.length === 0,"is-copying":_vm.copyingElementIndex === index,"application-context-additions":{
                recordIndexPath: [],
              }},on:{"move":function($event){return _vm.moveElement($event)}}})}),1)],_vm._v(" "),(_vm.footerElements.length !== 0)?[_c('div',{staticClass:"page-preview__separator"},[_c('span',{staticClass:"page-preview__separator-label"},[_vm._v("\n              "+_vm._s(_vm.$t('pagePreview.footer'))+"\n            ")])]),_vm._v(" "),_c('footer',{staticClass:"page__footer",class:{
              'page__footer--element-selected':
                _vm.pageSectionWithSelectedElement === 'footer',
            }},_vm._l((_vm.footerElements),function(element,index){return _c('ElementPreview',{key:element.id,attrs:{"element":element,"is-first-element":index === 0 &&
                _vm.headerElements.length === 0 &&
                _vm.elements.length === 0,"is-copying":_vm.copyingElementIndex === index,"application-context-additions":{
                recordIndexPath: [],
              }},on:{"move":function($event){return _vm.moveElement($event)}}})}),1)]:_vm._e()],2)],1),_vm._v(" "),_c('AddElementModal',{ref:"addElementModal",attrs:{"page":_vm.currentPage}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }