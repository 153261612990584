var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.tableLoading)?_c('ul',{staticClass:"header__filter header__filter--full-width"},[_c('li',{staticClass:"header__filter-item"},[_c('a',{ref:"stackedContextLink",staticClass:"header__filter-link",on:{"click":function($event){return _vm.$refs.stackedContext.toggle(
          _vm.$refs.stackedContextLink,
          'bottom',
          'left',
          4
        )}}},[_c('i',{staticClass:"header__filter-icon iconoir-nav-arrow-down"}),_vm._v(" "),_c('span',{staticClass:"header__filter-name"},[(_vm.view.single_select_field === null)?[_vm._v(_vm._s(_vm.$t('kanbanViewHeader.stackBy')))]:[_vm._v(_vm._s(_vm.$t('kanbanViewHeader.stackedBy', {
            fieldName: _vm.stackedByFieldName,
          })))]],2)]),_vm._v(" "),_c('Context',{ref:"stackedContext",attrs:{"overflow-scroll":"","max-height-if-outside-viewport":""}},[_c('KanbanViewStackedBy',{attrs:{"table":_vm.table,"view":_vm.view,"database":_vm.database,"fields":_vm.fields,"read-only":_vm.readOnly ||
          !_vm.$hasPermission(
            'database.table.view.update',
            _vm.view,
            _vm.database.workspace.id
          ),"store-prefix":_vm.storePrefix},on:{"refresh":function($event){return _vm.$emit('refresh', $event)}}})],1)],1),_vm._v(" "),(_vm.singleSelectFieldId !== -1)?_c('li',{staticClass:"header__filter-item"},[_c('a',{ref:"customizeContextLink",staticClass:"header__filter-link",on:{"click":function($event){return _vm.$refs.customizeContext.toggle(
          _vm.$refs.customizeContextLink,
          'bottom',
          'left',
          4
        )}}},[_c('i',{staticClass:"header__filter-icon iconoir-settings"}),_vm._v(" "),_c('span',{staticClass:"header__filter-name"},[_vm._v(_vm._s(_vm.$t('kanbanViewHeader.customizeCards')))])]),_vm._v(" "),_c('ViewFieldsContext',{ref:"customizeContext",attrs:{"database":_vm.database,"view":_vm.view,"fields":_vm.fields,"field-options":_vm.fieldOptions,"cover-image-field":_vm.view.card_cover_image_field,"allow-cover-image-field":true},on:{"update-all-field-options":_vm.updateAllFieldOptions,"update-field-options-of-field":_vm.updateFieldOptionsOfField,"update-order":_vm.orderFieldOptions,"update-cover-image-field":_vm.updateCoverImageField}})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }