/**
 * Redirect trailing slashes to non-trailing slash. Somehow, the Vue/Nuxt router
 * allows this, and these pages were causing an SEO problem. They now need to be
 * redirected to correct it.
 */
export default function ({ route, redirect }) {
  if (route.path !== '/' && route.path.endsWith('/')) {
    const path = route.path.slice(0, -1)
    const query = Object.keys(route.query).length ? route.query : undefined
    redirect(301, path, query)
  }
}
