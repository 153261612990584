<template>
  <div class="premium-modal__extra-actions">
    <div v-if="loading" class="loading"></div>
    <StartTrialModal
      ref="startTrialModal"
      :workspace="workspace"
      @success="$emit('hide')"
    />
    <Button
      v-if="canStartTrial"
      size="large"
      type="secondary"
      @click="$refs.startTrialModal.show()"
      >{{ $t('trials.freeTrial') }}
    </Button>
    <span v-if="canStartTrial" class="premium-modal__no-card"
      >{{ $t('premiumModal.noCardNeeded') }}
    </span>
  </div>
</template>

<script>
import StartTrialModal from '@saas/components/subscriptions/StartTrialModal'
import SubscriptionsService from '@saas/services/subscriptions'

export default {
  name: 'PremiumModalButton',
  components: { StartTrialModal },
  props: {
    name: {
      type: String,
      required: true,
    },
    workspace: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      usageData: undefined,
    }
  },
  async fetch() {
    try {
      const { data } = await SubscriptionsService(this.$client).getDashboard(
        this.workspace.id
      )
      this.usageData = data
    } catch (error) {
      // don't do anything
    } finally {
      this.loading = false
    }
  },
  computed: {
    workspaceUsage() {
      if (!this.usageData || this.loading) {
        return undefined
      }
      return this.usageData.find((u) => u.workspace_id === this.workspace.id)
    },
    canStartTrial() {
      if (!this.workspaceUsage || this.loading) {
        return false
      }
      return (
        this.workspaceUsage.license_type === null &&
        this.workspaceUsage.user_can_manage_subscription &&
        this.workspaceUsage.previous_trial === false
      )
    },
  },
}
</script>
