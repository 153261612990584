import { BaserowPlugin } from '@baserow/modules/core/plugins'
import SubscriptionMenuItem from '@saas/components/subscriptions/SubscriptionMenuItem.vue'
import DashboardWorkspacePlanBadge from '@saas/components/dashboard/DashboardWorkspacePlanBadge'
import DashboardWorkspaceRowUsage from '@saas/components/dashboard/DashboardWorkspaceRowUsage'
import AdditionalAuthControls from '@saas/components/auth/AdditionalAuthControls'
import PremiumModalButton from '@saas/components/subscriptions/PremiumModalButton'
import App from '@saas/components/App'
import SubscriptionsService from '@saas/services/subscriptions'
import NewsletterService from '@saas/services/newsletter'
import UserService from '@saas/services/user'
import { PremiumPlugin } from '@baserow_premium/plugins'
import { EnterprisePlugin } from '@baserow_enterprise/plugins'
import EnterpriseFeatures from '@baserow_enterprise/features'

export class SaaSPlugin extends BaserowPlugin {
  static getType() {
    return 'saas'
  }

  async userCreated(user, { $store, $client, $posthog }) {
    $posthog?.capture('user_signup', { via_frontend: true })

    // We can safely mark the terms and conditions as being accepted because the
    // user is forced to accept them during signup.
    $store.dispatch('auth/forceUpdateUserData', {
      saas: { accepted_terms_and_conditions: true },
    })

    try {
      await UserService($client).acceptTermsAndConditions()
    } catch (e) {}

    if (user.newsletterSignup) {
      try {
        await NewsletterService($client).subscribe(user.email)
        $posthog?.capture('newsletter_signup', { via_frontend: true })
      } catch (error) {}
    }
  }

  getUserContextComponents() {
    return [SubscriptionMenuItem]
  }

  getDashboardWorkspacePlanBadge() {
    return DashboardWorkspacePlanBadge
  }

  getDashboardWorkspaceRowUsageComponent() {
    return DashboardWorkspaceRowUsage
  }

  async fetchAsyncDashboardData(context, data, workspaceId) {
    const { app } = context
    const { data: usageData } = await SubscriptionsService(
      app.$client
    ).getDashboard(workspaceId)
    data.workspaceComponentArguments = { usageData }
    return data
  }

  mergeDashboardData(data, newData) {
    if (
      !data.workspaceComponentArguments ||
      !data.workspaceComponentArguments.usageData
    ) {
      return data
    }

    const usageData = data.workspaceComponentArguments.usageData

    if (
      newData.workspaceComponentArguments &&
      newData.workspaceComponentArguments.usageData
    ) {
      const newUsageData = newData.workspaceComponentArguments.usageData
      for (const workspaceUsageData of newUsageData) {
        const index = usageData.findIndex(
          (ud) => ud.workspace_id === workspaceUsageData.workspace_id
        )
        if (index >= 0) {
          usageData[index] = workspaceUsageData
        } else {
          usageData.push(workspaceUsageData)
        }
      }
    }

    return data
  }

  getRegisterComponent() {
    return AdditionalAuthControls
  }

  getAppLayoutComponent() {
    return App
  }
}

export class SaaSPremiumPlugin extends PremiumPlugin {
  getPremiumModalButtonsComponent() {
    return PremiumModalButton
  }
}

export class SaaSEnterprisePlugin extends EnterprisePlugin {
  getVisuallyHiddenFeatures() {
    return [EnterpriseFeatures.ENTERPRISE_SETTINGS, EnterpriseFeatures.SSO]
  }
}
