<template>
  <Modal :can-close="false" small>
    <h2 class="box__title">{{ $t('confirmTermsAndConditionsModal.title') }}</h2>
    <Error :error="error"></Error>
    <p>
      <i18n path="confirmTermsAndConditionsModal.message" tag="span">
        <a :href="url" target="_blank"
          >{{ $config.PUBLIC_WEB_FRONTEND_URL }}{{ url }}</a
        >
      </i18n>
    </p>
    <div class="align-right">
      <Button size="large" :loading="loading" @click="accept()">
        {{ $t('confirmTermsAndConditionsModal.accept') }}</Button
      >
    </div>
  </Modal>
</template>

<script>
import modal from '@baserow/modules/core/mixins/modal'
import error from '@baserow/modules/core/mixins/error'

import UserService from '@saas/services/user'

export default {
  name: 'ConfirmTermsAndConditionsModal',
  mixins: [modal, error],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    url() {
      return this.$nuxt.$router.resolve({
        name: 'terms',
      }).href
    },
  },
  methods: {
    async accept() {
      this.loading = true

      try {
        await UserService(this.$client).acceptTermsAndConditions()
        await this.$store.dispatch('auth/forceUpdateUserData', {
          saas: { accepted_terms_and_conditions: true },
        })
        this.hide()
      } catch (e) {
        this.handleError(e)
      }
      this.loading = false
    },
  },
}
</script>
