// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../baserow-saas/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"light-blue": "#f0f4fc",
	"light-green": "#ecfcf1",
	"light-cyan": "#cff5fa",
	"light-orange": "#fffbf0",
	"light-yellow": "#fffbf0",
	"light-red": "#fff2f0",
	"light-brown": "#f5e6dc",
	"light-purple": "#f9f1fd",
	"light-pink": "#f7e1f2",
	"light-gray": "#f5f5f5",
	"blue": "#dae4fd",
	"green": "#d0f6dc",
	"cyan": "#a0ebf5",
	"orange": "#fff4da",
	"yellow": "#ffe9b4",
	"red": "#ffdeda",
	"brown": "#f5ceb0",
	"purple": "#dfb9f7",
	"pink": "#f7b2e7",
	"gray": "#d7d8d9",
	"dark-blue": "#acc8f8",
	"dark-green": "#a0eeba",
	"dark-cyan": "#70e0ef",
	"dark-orange": "#ffe9b4",
	"dark-yellow": "#ffdd8f",
	"dark-red": "#ffbdb4",
	"dark-brown": "#f5c098",
	"dark-purple": "#cf96f2",
	"dark-pink": "#f285d9",
	"dark-gray": "#cdcecd",
	"darker-blue": "#689ef1",
	"darker-green": "#41dd75",
	"darker-cyan": "#41d6ea",
	"darker-orange": "#ffd269",
	"darker-yellow": "#ffd269",
	"darker-red": "#ff7b69",
	"darker-brown": "#f5a96e",
	"darker-purple": "#bf73ee",
	"darker-pink": "#ff6dde",
	"darker-gray": "#b5b5b7",
	"deep-dark-green": "#10bf4a",
	"deep-dark-orange": "#ffc744"
};
module.exports = ___CSS_LOADER_EXPORT___;
