<template>
  <div class="saas-dashboard__row-usage">
    <div class="saas-dashboard__row-usage-progress-bar">
      <div class="saas-dashboard__row-usage-text">
        {{ $t('common.rowUsage') }}
        <div class="saas-dashboard__row-usage-count">
          <strong>{{ workspaceUsage.row_count }}</strong>
          <span>/ {{ workspaceUsage.rows_quota }}</span>
        </div>
      </div>
      <ProgressBar
        :progress="workspaceUsage.row_count"
        :total="workspaceUsage.rows_quota"
        class="saas-dashboard__row-usage-progress-bar-component"
      />
    </div>

    <template
      v-if="
        workspaceUsage.license_type === null &&
        workspaceUsage.user_can_manage_subscription &&
        workspaceUsage.previous_trial === false
      "
    >
      <Button type="secondary" @click="$refs.startTrialModal.show()">
        {{ $t('trials.getFreeTrial') }}
      </Button>
      <StartTrialModal
        ref="startTrialModal"
        :workspace="workspace"
        @success="trialStarted()"
      />
    </template>
    <Button
      v-else-if="workspaceUsage.user_can_manage_subscription"
      type="secondary"
      @click="upgrade"
    >
      {{ $t('common.changePlan') }}</Button
    >

    <div class="tooltip tooltip--center saas-dashboard__row-usage-tooltip">
      <div class="tooltip__content saas-dashboard__usage-tooltip-content">
        <ul class="saas-dashboard__usage-tooltip-list">
          <li
            v-for="feature in features"
            :key="feature.name"
            class="saas-dashboard__usage-tooltip-list-item"
          >
            {{ feature.name }}
            <i
              class="saas-dashboard__usage-tooltip-list-item-icon"
              :class="{
                'saas-dashboard__usage-tooltip-list-item-icon--available iconoir-check':
                  feature.available,
                'saas-dashboard__usage-tooltip-list-item-icon--not-available iconoir-cancel':
                  !feature.available,
              }"
            ></i>
          </li>

          <li class="saas-dashboard__usage-tooltip-list-item">
            {{ $t('common.storageUsage') }}
            <span class="saas-dashboard__usage-tooltip-list-item-value">
              <strong
                >{{ storageUsageInGB(workspaceUsage)
                }}{{ $t('common.gb') }}</strong
              >
              /
              <small
                >{{ workspaceUsage.storage_quota }}{{ $t('common.gb') }}</small
              >
            </span>
          </li>
          <li class="saas-dashboard__usage-tooltip-list-item">
            {{ $t('common.rowUsage') }}
            <span class="saas-dashboard__usage-tooltip-list-item-value">
              <strong>{{ workspaceUsage.row_count }}</strong> /
              <small>{{ workspaceUsage.rows_quota }}</small>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@saas/components/ProgressBar'
import StartTrialModal from '@saas/components/subscriptions/StartTrialModal'
import usage from '@saas/mixins/usage'

export default {
  name: 'DashboardWorkspaceRowUsage',
  components: { ProgressBar, StartTrialModal },
  mixins: [usage],
  props: {
    workspace: {
      type: Object,
      required: true,
    },
    componentArguments: {
      type: Object,
      required: true,
    },
  },
  computed: {
    workspaceUsage() {
      return this.componentArguments.usageData.find(
        (u) => u.workspace_id === this.workspace.id
      )
    },
    features() {
      return [
        {
          name: this.$t('common.premiumFeatures'),
          available: this.workspaceUsage.premium,
        },
        {
          name: this.$t('common.aiFeatures'),
          available: this.workspaceUsage.premium,
        },
        {
          name: this.$t('common.prioritySupport'),
          available: this.workspaceUsage.priority_support,
        },
        {
          name: this.$t('common.roleBasedAccessControl'),
          available: this.workspaceUsage.rbac,
        },
      ]
    },
  },
  methods: {
    upgrade() {
      if (this.workspaceUsage.subscription_id) {
        this.$router.push({
          name: 'subscription',
          params: { subscriptionId: this.workspaceUsage.subscription_id },
        })
      } else {
        // It the workspace is on a free trial, then there is no subscription.
        this.$router.push({
          name: 'new-subscription',
        })
      }
    },
    trialStarted() {
      this.$emit('workspace-updated', this.workspace)
    },
  },
}
</script>
