var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({ height: '100%' })},[(_vm.deltaLeft || _vm.deltaWidth)?_c('div',{staticClass:"timeline-grid-row timeline-grid-row--shadow",style:({
      left: `${_vm.left + _vm.padLeft}px`,
      width: `${_vm.width - _vm.padWidth}px`,
    })}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"timeline-grid-row",class:{
      [`background-color--${_vm.backgroundColor || 'white'}`]: true,
      'timeline-grid-row--tiny': _vm.tooTiny,
    },style:(_vm.rowStyle),on:{"mousedown":_vm.start,"mouseup":function($event){!_vm.resizing ? _vm.up : null},"mouseover":function($event){_vm.hovering = true},"mouseleave":function($event){_vm.hovering = false}}},[(!_vm.tooTiny)?[(_vm.leftBorderColor)?_c('div',{staticClass:"left-border-decorator",class:`background-color--${_vm.leftBorderColor}`}):_c('div',{staticClass:"timeline-grid-row__spacer"})]:_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipText),expression:"tooltipText"}],staticClass:"timeline-grid-row__label-container",class:{ 'timeline-grid-row__label-container--tiny': _vm.tooTiny },attrs:{"tooltip-position":"bottom-cursor"}},[_c('div',{staticClass:"timeline-grid-row__label"},[_vm._v("\n        "+_vm._s(_vm.label)+"\n      ")])]),_vm._v(" "),(!_vm.tooTiny)?_c('div',{staticClass:"timeline-grid-row__spacer"}):_vm._e(),_vm._v(" "),_c('HorizontalResize',{directives:[{name:"show",rawName:"v-show",value:(!_vm.startDateFieldReadOnly && _vm.hovering && !_vm.dragging && !_vm.tooTiny),expression:"!startDateFieldReadOnly && hovering && !dragging && !tooTiny"}],staticClass:"timeline-grid-row__resize-handle timeline-grid-row__resize-handle--start",class:{
        'timeline-grid-row__resize-handle--white': _vm.backgroundColor === 'gray',
      },attrs:{"width":0,"min":-Infinity,"stop-propagation":true},on:{"move":function($event){return _vm.moveRowDate({ pos: 'start', resize: { width: $event } })},"update":function($event){return _vm.updateRowDate({ pos: 'start', resize: $event })}}}),_vm._v(" "),_c('HorizontalResize',{directives:[{name:"show",rawName:"v-show",value:(!_vm.endDateFieldReadOnly && _vm.hovering && !_vm.dragging && !_vm.tooTiny),expression:"!endDateFieldReadOnly && hovering && !dragging && !tooTiny"}],staticClass:"timeline-grid-row__resize-handle timeline-grid-row__resize-handle--end",class:{
        'timeline-grid-row__resize-handle--white': _vm.backgroundColor === 'gray',
      },attrs:{"width":0,"min":-Infinity,"stop-propagation":true},on:{"move":function($event){return _vm.moveRowDate({ pos: 'end', resize: { width: $event } })},"update":function($event){return _vm.updateRowDate({ pos: 'end', resize: $event })}}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }