var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"side-panels"},[_c('Tabs',{key:_vm.element?.id,attrs:{"full-height":""}},_vm._l((_vm.pageSidePanelTypes),function(pageSidePanelType){return _c('Tab',{key:pageSidePanelType.getType(),attrs:{"tooltip":_vm.element && pageSidePanelType.isDeactivated(_vm.element)
          ? pageSidePanelType.getDeactivatedText()
          : null,"title":pageSidePanelType.label,"disabled":!_vm.element || pageSidePanelType.isDeactivated(_vm.element)}},[(_vm.element)?_c('ReadOnlyForm',{attrs:{"read-only":!_vm.$hasPermission(
            'builder.page.element.update',
            _vm.element,
            _vm.workspace.id
          )}},[_c(pageSidePanelType.component,{tag:"component",staticClass:"side-panels__panel"})],1):_c('EmptySidePanelState')],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }