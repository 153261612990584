<template>
  <footer class="saas-footer-docs">
    <div class="saas-footer-docs__inner">
      <div class="saas-footer-docs__content">
        <nuxt-link :to="{ name: 'index' }" class="saas-footer-docs__logo">
          <img
            width="100"
            src="@baserow/modules/core/static/img/logo.svg"
            alt="Baserow logo"
          />
        </nuxt-link>

        <div class="saas-footer-docs__content-text">
          <span
            >© Copyright {{ new Date().getFullYear() }} Baserow All rights
            reserved.</span
          >

          <ul class="saas-footer-docs__content-links">
            <li>
              <nuxt-link
                class="saas-footer-docs__content-link"
                :to="{ name: 'terms' }"
                >Terms & conditions</nuxt-link
              >
            </li>
            <li>
              <nuxt-link
                class="saas-footer-docs__content-link"
                :to="{ name: 'privacy' }"
                >Privacy policy</nuxt-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterDocs',
  computed: {},
  watch: {},
  methods: {},
}
</script>
