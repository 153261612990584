export default ({ app: { router, $config, store } }, inject) => {
  const pwAuth = $config.PROFIT_WELL_PW_AUTH

  if (
    // This should only happen in the browser.
    !process.client ||
    // This should only happen if the profitwell auth is configured.
    !pwAuth ||
    // If profitwell has somehow already been loaded, then we don't have to execute
    // this.
    window.profitwell ||
    // This value is set by `modules/builder/plugins/router.js`, and will be true
    // if the routes are related to the tool and not the publisher website. We don't
    // want to load the script for published websites.
    (process.client && !window.__NUXT__.isWebFrontendHostname)
  ) {
    return
  }

  let hasBeenSet = false

  const setProfitwellUserIfNeeded = (profitwell) => {
    const isAuthenticated = store.getters['auth/isAuthenticated']
    const userEmail = store.getters['auth/getUsername']

    // The email address of the user should only be set once in profitwell.
    if (!hasBeenSet && profitwell && isAuthenticated) {
      profitwell('user_email', userEmail)
      hasBeenSet = true
    }
  }

  const loaded = (profitwell) => {
    inject('profitwell', profitwell)
    setProfitwellUserIfNeeded(profitwell)
  }

  // Catch when `profitwell` is set on the `window` object. This happens directly
  // after the script loads.
  let _windowProfitwell
  Object.defineProperty(window, 'profitwell', {
    configurable: true,
    enumerable: true,
    get() {
      return _windowProfitwell
    },
    set(newVal) {
      _windowProfitwell = newVal
      loaded(newVal)
    },
  })

  // The script is added according to their documentation here:
  // https://www.paddle.com/help/profitwell-metrics/setup/install-engagement/profitwell-snippet-spa.
  const script = document.createElement('script')
  script.id = 'profitwell-js'
  script.setAttribute('data-pw-auth', pwAuth)
  script.async = true

  const code = `
    (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
    a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
    s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
    })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');`

  try {
    script.appendChild(document.createTextNode(code))
  } catch (e) {
    script.text = code
  }

  document.body.appendChild(script)

  // It could happen that the user is not signed in on pageload. We will therefore
  // check after each route if the profitwell user must be set. This will then also
  // happen after signing in.
  router.afterEach(() => {
    setProfitwellUserIfNeeded(window.profitwell)
  })
}
