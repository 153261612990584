var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-view__meta-controls"},[_c('SwitchInput',{staticClass:"margin-bottom-3",attrs:{"small":"","value":_vm.view.receive_notification_on_submit},on:{"input":function($event){return _vm.$emit('updated-form', { receive_notification_on_submit: $event })}}},[_vm._v(_vm._s(_vm.$t('formSidebar.notifyUserOnSubmit')))]),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-3",attrs:{"small-label":"","label":_vm.$t('formViewMetaControls.whenSubmittedLabel'),"required":""}},[_c('ul',{staticClass:"choice-items choice-items--inline"},[_c('li',[_c('a',{staticClass:"choice-items__link",class:{
            active: _vm.view.submit_action === 'MESSAGE',
            disabled: _vm.readOnly,
          },on:{"click":function($event){!_vm.readOnly &&
              _vm.view.submit_action !== 'MESSAGE' &&
              _vm.$emit('updated-form', { submit_action: 'MESSAGE' })}}},[_c('span',[_vm._v(_vm._s(_vm.$t('formViewMetaControls.showMessage')))]),_vm._v(" "),(_vm.view.submit_action === 'MESSAGE')?_c('i',{staticClass:"choice-items__icon-active iconoir-check-circle"}):_vm._e()])]),_vm._v(" "),_c('li',[_c('a',{staticClass:"choice-items__link",class:{
            active: _vm.view.submit_action === 'REDIRECT',
            disabled: _vm.readOnly,
          },on:{"click":function($event){!_vm.readOnly &&
              _vm.view.submit_action !== 'REDIRECT' &&
              _vm.$emit('updated-form', { submit_action: 'REDIRECT' })}}},[_c('span',[_vm._v(_vm._s(_vm.$t('formViewMetaControls.urlRedirect')))]),_vm._v(" "),(_vm.view.submit_action === 'REDIRECT')?_c('i',{staticClass:"choice-items__icon-active iconoir-check-circle"}):_vm._e()])])])]),_vm._v(" "),(_vm.view.submit_action === 'MESSAGE')?_c('FormGroup',{attrs:{"label":_vm.$t('formViewMetaControls.theMessage'),"small-label":"","required":""}},[_c('FormTextarea',{staticClass:"form-view__meta-message-textarea",attrs:{"placeholder":_vm.$t('formViewMetaControls.theMessage'),"rows":3,"disabled":_vm.readOnly},on:{"blur":function($event){return _vm.$emit('updated-form', {
          submit_action_message: _vm.submit_action_message,
        })}},model:{value:(_vm.submit_action_message),callback:function ($$v) {_vm.submit_action_message=$$v},expression:"submit_action_message"}})],1):_vm._e(),_vm._v(" "),(_vm.view.submit_action === 'REDIRECT')?_c('FormGroup',{attrs:{"small-label":"","error":_vm.$v.submit_action_redirect_url.$error,"error-message":_vm.redirectURLErrorMessage,"label":_vm.$t('formViewMetaControls.theURL'),"helper-text":_vm.$t('formViewMeta.includeRowId'),"required":""}},[_c('FormInput',{attrs:{"placeholder":_vm.$t('formViewMetaControls.theURL'),"disabled":_vm.readOnly,"error":_vm.$v.submit_action_redirect_url.$error},on:{"blur":function($event){;[
          _vm.$v.submit_action_redirect_url.$touch(),
          !_vm.$v.submit_action_redirect_url.$error &&
            _vm.$emit('updated-form', {
              submit_action_redirect_url: _vm.submit_action_redirect_url,
            }),
        ]}},model:{value:(_vm.submit_action_redirect_url),callback:function ($$v) {_vm.submit_action_redirect_url=$$v},expression:"submit_action_redirect_url"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }