import { LicenseAuthorityAdminType } from '@saas/adminTypes'
import headerStore from '@saas/store/header'
import saasLayoutStore from '@saas/store/saasLayout'
import {
  SaaSPlugin,
  SaaSPremiumPlugin,
  SaaSEnterprisePlugin,
} from '@saas/plugins'

import cs from '@saas/locales/cs.json'
import de from '@saas/locales/de.json'
import en from '@saas/locales/en.json'
import es from '@saas/locales/es.json'
import fr from '@saas/locales/fr.json'
import it from '@saas/locales/it.json'
import nl from '@saas/locales/nl.json'
import ptBr from '@saas/locales/pt_BR.json'
import {
  AdvancedLicenseType,
  AdvancedFreeTrialLicenseType,
  AllSaasUsersLicenseType,
  SaasStaffMembersLicense,
} from '@saas/licenseTypes'
import { SaaSOpenAIGenerativeAIModelType } from '@saas/generativeAIModelTypes'

export default (context) => {
  const { store, app, isDev } = context

  store.registerModule('header', headerStore)
  store.registerModule('saasLayout', saasLayoutStore)

  app.$registry.register('plugin', new SaaSPlugin(context))
  app.$registry.unregister('plugin', 'premium')
  app.$registry.register('plugin', new SaaSPremiumPlugin(context))
  app.$registry.register('plugin', new SaaSEnterprisePlugin(context))

  app.$registry.register('admin', new LicenseAuthorityAdminType(context))
  app.$registry.register('license', new AllSaasUsersLicenseType(context))
  app.$registry.register('license', new SaasStaffMembersLicense(context))
  app.$registry.register('license', new AdvancedLicenseType(context))
  app.$registry.register('license', new AdvancedFreeTrialLicenseType(context))

  app.$registry.register(
    'generativeAIModel',
    new SaaSOpenAIGenerativeAIModelType(context)
  )

  // Allow locale file hot reloading
  if (isDev && app.i18n) {
    const { i18n } = app
    i18n.mergeLocaleMessage('cs', cs)
    i18n.mergeLocaleMessage('en', en)
    i18n.mergeLocaleMessage('fr', fr)
    i18n.mergeLocaleMessage('nl', nl)
    i18n.mergeLocaleMessage('de', de)
    i18n.mergeLocaleMessage('es', es)
    i18n.mergeLocaleMessage('it', it)
    i18n.mergeLocaleMessage('ptBr', ptBr)
  }

  // When deleting a workspace that has an active subscription.
  app.$clientErrorMap.setError(
    'ERROR_GROUP_HAS_SUBSCRIPTION',
    app.i18n.t('clientHandler.workspaceHasSubscriptionTitle'),
    app.i18n.t('clientHandler.workspaceHasSubscriptionDescription')
  )

  // When the user tried to delete his account that still has an active subscription.
  app.$clientErrorMap.setError(
    'ERROR_USER_HAS_SUBSCRIPTION',
    app.i18n.t('clientHandler.userHasSubscriptionTitle'),
    app.i18n.t('clientHandler.userHasSubscriptionDescription')
  )

  app.$clientErrorMap.setError(
    'ERROR_GROUP_ADMIN_HAS_SUBSCRIPTION',
    app.i18n.t('clientHandler.workspaceAdminHasSubscriptionTitle'),
    app.i18n.t('clientHandler.workspaceAdminHasSubscriptionDescription')
  )

  app.$clientErrorMap.setError(
    'ERROR_SUBSCRIPTION_LICENSE_CANT_BE_DELETED',
    app.i18n.t('clientHandler.subscriptionLicenseCantBeDeletedTitle'),
    app.i18n.t('clientHandler.subscriptionLicenseCantBeDeletedTitleDescription')
  )
}
