var render = function render(_c,_vm){return (
    !_vm.props.row ||
    !_vm.props.row._.metadata.row_comment_count ||
    !_vm.injections.$hasPermission(
      'database.table.list_comments',
      _vm.props.table,
      _vm.props.workspaceId
    )
  )?_c('GridViewRowExpandButton',_vm._g({attrs:{"row":_vm.props.row}},_vm.listeners)):_c('a',{staticClass:"row-comments-expand-button",attrs:{"title":_vm.props.row._.metadata.row_comment_count + ' comments'},on:{"click":function($event){_vm.listeners['edit-modal'] && _vm.listeners['edit-modal']()}}},[(_vm.props.row._.metadata.row_comment_count < 100)?[_vm._v("\n    "+_vm._s(_vm.props.row._.metadata.row_comment_count)+"\n  ")]:_c('i',{staticClass:"row-comments-expand-button__icon iconoir-multi-bubble"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }