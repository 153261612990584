var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calendar-view"},[_c('CalendarMonth',{attrs:{"fields":_vm.fields,"store-prefix":_vm.storePrefix,"loading":_vm.loading,"read-only":_vm.readOnly,"database":_vm.database,"table":_vm.table,"view":_vm.view},on:{"edit-row":function($event){return _vm.openRowEditModal($event)},"create-row":_vm.openCreateRowModal,"row-context":function($event){return _vm.showRowContext($event.event, $event.row)}}}),_vm._v(" "),_c('RowCreateModal',{ref:"rowCreateModal",attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view,"primary-is-sortable":true,"visible-fields":_vm.visibleCardFields,"hidden-fields":_vm.hiddenFields,"show-hidden-fields":_vm.showHiddenFieldsInRowModal,"all-fields-in-table":_vm.fields},on:{"toggle-hidden-fields-visibility":function($event){_vm.showHiddenFieldsInRowModal = !_vm.showHiddenFieldsInRowModal},"created":_vm.createRow,"order-fields":_vm.orderFields,"toggle-field-visibility":_vm.toggleFieldVisibility,"field-updated":function($event){return _vm.$emit('refresh', $event)},"field-deleted":function($event){return _vm.$emit('refresh')}}}),_vm._v(" "),_c('RowEditModal',{ref:"rowEditModal",attrs:{"enable-navigation":"","database":_vm.database,"table":_vm.table,"view":_vm.view,"all-fields-in-table":_vm.fields,"primary-is-sortable":false,"visible-fields":_vm.visibleCardFields,"hidden-fields":_vm.hiddenFields,"rows":_vm.allRows,"read-only":_vm.readOnly ||
      !_vm.$hasPermission(
        'database.table.update_row',
        _vm.table,
        _vm.database.workspace.id
      ),"show-hidden-fields":_vm.showHiddenFieldsInRowModal},on:{"hidden":function($event){return _vm.$emit('selected-row', undefined)},"toggle-hidden-fields-visibility":function($event){_vm.showHiddenFieldsInRowModal = !_vm.showHiddenFieldsInRowModal},"update":_vm.updateValue,"order-fields":_vm.orderFields,"toggle-field-visibility":_vm.toggleFieldVisibility,"field-updated":function($event){return _vm.$emit('refresh', $event)},"field-deleted":function($event){return _vm.$emit('refresh')},"field-created":function($event){_vm.fieldCreated($event)
      _vm.showHiddenFieldsInRowModal = true},"field-created-callback-done":_vm.afterFieldCreatedUpdateFieldOptions,"navigate-previous":function($event){return _vm.$emit('navigate-previous', $event)},"navigate-next":function($event){return _vm.$emit('navigate-next', $event)}}}),_vm._v(" "),_c('Context',{ref:"cardContext",staticClass:"context__menu-wrapper",attrs:{"overflow-scroll":true,"hide-on-scroll":true,"hide-on-resize":true,"max-height-if-outside-viewport":true}},[_c('ul',{staticClass:"context__menu"},[(
          !_vm.readOnly &&
          _vm.$hasPermission(
            'database.table.delete_row',
            _vm.table,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link js-ctx-delete-row",on:{"click":function($event){return _vm.deleteRow(_vm.selectedRow)}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-bin"}),_vm._v("\n          "+_vm._s(_vm.$t('gridView.deleteRow'))+"\n        ")])]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }