var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inline-block"},[_c('a',{ref:"button_user_source_users",staticClass:"header__filter-link",on:{"click":function($event){return _vm.$refs['user_source_users_context'].toggle(
        _vm.$refs['button_user_source_users'],
        'bottom',
        'left',
        4
      )}}},[_c('i',{staticClass:"header__filter-icon iconoir-user"}),_vm._v(" "),_c('span',{staticClass:"header__filter-name"},[_vm._v("\n      "+_vm._s(_vm.$t('userSelector.viewAs', {
          user: _vm.isAuthenticated
            ? _vm.loggedUser.username
            : _vm.$t('userSelector.anonymous'),
        }))+"\n      "+_vm._s(_vm.userRole)+"\n    ")])]),_vm._v(" "),_c('UserSourceUsersContext',{ref:"user_source_users_context"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }