export default (client) => {
  return {
    acceptTermsAndConditions() {
      return client.post('/saas/user/accept-terms-and-conditions/')
    },
    addAdditionalInformation({
      country,
      team,
      companySize,
      companyName,
      role,
    }) {
      return client.post('/saas/user/add-additional-information/', {
        country,
        team,
        company_size: companySize,
        company_name: companyName,
        role,
      })
    },
  }
}
