<template>
  <div>
    <form @submit.prevent="submit">
      <FormGroup
        :label="$t('additionalSignupInformation.whichTeam')"
        :error="$v.values.team.$error"
        required
        small-label
        class="margin-bottom-2"
      >
        <Dropdown
          v-model="values.team"
          :class="{ 'dropdown--error': $v.values.team.$error }"
          @hide="$v.values.team.$touch()"
        >
          <DropdownItem
            v-for="choice in teamChoices"
            :key="choice.value"
            :name="choice.name"
            :value="choice.value"
          ></DropdownItem>
        </Dropdown>

        <template #error>
          <span v-if="$v.values.team.$dirty && !$v.values.team.required">{{
            $t('error.requiredField')
          }}</span>
        </template>
      </FormGroup>

      <FormGroup
        v-show="!isPersonal"
        :label="$t('additionalSignupInformation.companySize')"
        :error="$v.values.companySize.$error"
        required
        small-label
        class="margin-bottom-2"
      >
        <Dropdown
          v-model="values.companySize"
          :class="{ 'dropdown--error': $v.values.companySize.$error }"
          @hide="$v.values.companySize.$touch()"
        >
          <DropdownItem
            v-for="choice in companySizes"
            :key="choice.value"
            :name="choice.name"
            :value="choice.value"
          ></DropdownItem>
        </Dropdown>
        <template #error>
          <span
            v-if="
              $v.values.companySize.$dirty && !$v.values.companySize.required
            "
          >
            {{ $t('error.requiredField') }}</span
          >
        </template>
      </FormGroup>

      <FormGroup
        v-show="!isPersonal && !isSmallCompany"
        :label="$t('additionalSignupInformation.country')"
        :error="$v.values.country.$error"
        small-label
        class="margin-bottom-2"
        required
      >
        <Dropdown
          v-model="values.country"
          :class="{ 'dropdown--error': $v.values.country.$error }"
          @hide="$v.values.country.$touch()"
        >
          <DropdownItem
            v-for="country in countries"
            :key="country"
            :name="country"
            :value="country"
          ></DropdownItem>
        </Dropdown>

        <template #error>
          <span
            v-if="$v.values.country.$dirty && !$v.values.country.required"
            >{{ $t('error.requiredField') }}</span
          >
        </template>
      </FormGroup>

      <FormGroup
        v-show="!isPersonal && !isSmallCompany"
        :label="$t('additionalSignupInformation.companyName')"
        :error="$v.values.companyName.$error"
        small-label
        required
        class="margin-bottom-2"
      >
        <FormInput
          v-model="values.companyName"
          type="text"
          size="large"
          :error="$v.values.companyName.$error"
          @blur="$v.values.companyName.$touch()"
        ></FormInput>
        <template #error>
          <span
            v-if="
              $v.values.companyName.$dirty && !$v.values.companyName.required
            "
          >
            {{ $t('error.requiredField') }}</span
          >
          <span
            v-if="
              $v.values.companyName.$dirty && !$v.values.companyName.maxLength
            "
          >
            {{ $t('error.maxLength', { max: 100 }) }}
          </span>
        </template>
      </FormGroup>

      <FormGroup
        v-show="!isPersonal && !isSmallCompany"
        :label="$t('additionalSignupInformation.role')"
        :error="$v.values.role.$error"
        small-label
        required
      >
        <FormInput
          v-model="values.role"
          type="text"
          size="large"
          :error="$v.values.role.$error"
          @blur="$v.values.role.$touch()"
        ></FormInput>
        <template #error>
          <span v-if="$v.values.role.$dirty && !$v.values.role.required">
            {{ $t('error.requiredField') }}</span
          >
          <span v-if="$v.values.role.$dirty && !$v.values.role.maxLength">
            {{ $t('error.maxLength', { max: 100 }) }}
          </span>
        </template>
      </FormGroup>
    </form>
  </div>
</template>

<script>
import { maxLength, requiredIf } from 'vuelidate/lib/validators'
import { countryList } from '@baserow/modules/core/utils/countries'
import { getTeamChoices, getCompanySizeChoices } from '@saas/utils/sales'
import form from '@baserow/modules/core/mixins/form'

export default {
  name: 'AdditionalInformationForm',
  mixins: [form],
  props: {
    // Will make all fields in the form required
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      allowedValues: ['country', 'team', 'companySize', 'companyName', 'role'],
      values: {
        country: '',
        team: '',
        companySize: '',
        companyName: '',
        role: '',
      },
    }
  },
  computed: {
    countries() {
      return countryList
    },
    teamChoices() {
      return getTeamChoices(this)
    },
    companySizes() {
      return getCompanySizeChoices(this)
    },
    isPersonal() {
      return ['', 'Other / Personal'].includes(this.values.team)
    },
    isSmallCompany() {
      return ['', '0 - 10 employees'].includes(this.values.companySize)
    },
  },
  validations: {
    values: {
      companyName: {
        maxLength: maxLength(100),
        required: requiredIf(function (values) {
          return this.required && !this.isPersonal && !this.isSmallCompany
        }),
      },
      role: {
        maxLength: maxLength(100),
        required: requiredIf(function (values) {
          return this.required && !this.isPersonal && !this.isSmallCompany
        }),
      },
      team: {
        required: requiredIf(function (values) {
          return this.required
        }),
      },
      companySize: {
        required: requiredIf(function (values) {
          return this.required && !this.isPersonal
        }),
      },
      country: {
        required: requiredIf(function (values) {
          return this.required && !this.isPersonal && !this.isSmallCompany
        }),
      },
    },
  },
}
</script>
