<template>
  <div v-if="visible" class="cookie-notification">
    <p class="cookie-notification__text">
      This website uses cookies to ensure you get the best experience on our
      website.
    </p>
    <div class="cookie-notification__actions">
      <nuxt-link :to="{ name: 'privacy' }">More information</nuxt-link>
      <Button icon="iconoir-cookie" @click.prevent="hide()"> Got it </Button>
    </div>
  </div>
</template>

<script>
const cookieName = 'cookieConsent'

export default {
  data() {
    return {
      visible: false,
    }
  },
  mounted() {
    if (this.$cookies.get(cookieName) === undefined) {
      this.visible = true
    }
  },
  methods: {
    hide() {
      const expires = 1000 * 60 * 60 * 24 * 365
      this.$cookies.set(cookieName, new Date().getTime(), {
        path: '/',
        expires: new Date(Date.now() + expires),
      })
      this.visible = false
    },
  },
}
</script>
