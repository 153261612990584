import { render, staticRenderFns } from "./layouts.saas.96aaff18.vue?vue&type=template&id=76c47903"
import script from "./layouts.saas.96aaff18.vue?vue&type=script&lang=js"
export * from "./layouts.saas.96aaff18.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports