import { render, staticRenderFns } from "./layouts.docs.f30c63a2.vue?vue&type=template&id=1885071e"
import script from "./layouts.docs.f30c63a2.vue?vue&type=script&lang=js"
export * from "./layouts.docs.f30c63a2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports