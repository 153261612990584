var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline-grid",style:({
    height: `${_vm.gridHeight}px`,
    width: `${_vm.gridWidth}px`,
  })},[_vm._l((_vm.columnsBuffer),function({ position, item: col },index){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(col !== undefined),expression:"col !== undefined"}],key:`c-${index}`,staticClass:"timeline-grid__column",class:{ 'timeline-grid__column--alt': col?.altColor },style:({
        position: 'absolute',
        left: `${position.left}px`,
        width: `${_vm.columnWidth}px`,
        height: `${_vm.gridHeight}px`,
      })})]}),_vm._v(" "),(_vm.offsetNow)?_c('div',{style:({
      position: 'absolute',
      left: `${_vm.offsetNow}px`,
      height: `${_vm.gridHeight}px`,
    })},[_c('div',{staticClass:"timeline-grid__now-cursor",style:({ height: `${_vm.minGridHeight}px` })})]):_vm._e(),_vm._v(" "),_vm._l((_vm.rowsBuffer),function({ position, item: row },index){return [(row)?_c('div',{key:`r-${index}`,staticClass:"timeline-grid__row-lane",style:({
        height: `${_vm.rowHeight}px`,
        top: `${position.top}px`,
      })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isValidRow(row)),expression:"isValidRow(row)"}],staticClass:"timeline-grid__row"},[_c('TimelineGridRow',_vm._b({attrs:{"label":_vm.getRowLabel(row),"start-date":_vm.getRowDateValue(row, _vm.startDateField)?.format(),"end-date":_vm.getRowDateValue(row, _vm.endDateField)?.format(),"timezone":_vm.timezone,"start-date-field-read-only":_vm.readOnly || _vm.startDateFieldReadOnly,"end-date-field-read-only":_vm.readOnly || _vm.endDateFieldReadOnly,"date-only-fields":_vm.dateOnlyFields},on:{"edit-row":function($event){return _vm.$emit('edit-row', row)},"updating-row":function($event){return _vm.$emit('updating-row', { row, value: $event })},"update-row":function($event){return _vm.updateRow(row, $event)}}},'TimelineGridRow',_vm.getRowStyleProps(row),false))],1),_vm._v(" "),(!_vm.isValidRow(row))?_c('TimelineGridShowRowButton',{staticClass:"timeline-grid__show-row-button timeline-grid__show-row-button--goto-start",attrs:{"label":_vm.getRowLabel(row),"date":null,"timezone":_vm.timezone,"icon":"iconoir-expand"},on:{"mousedown":function($event){return _vm.$emit('edit-row', row)}}}):(_vm.showGotoStartButton(row))?_c('TimelineGridShowRowButton',{staticClass:"timeline-grid__show-row-button timeline-grid__show-row-button--goto-start",attrs:{"label":_vm.getRowLabel(row),"date":_vm.getRowDateValue(row, _vm.startDateField).format(),"timezone":_vm.timezone},on:{"mousedown":function($event){return _vm.scrollToStart(row, $event)}}}):_vm._e(),_vm._v(" "),(_vm.showGotoEndButton(row))?_c('TimelineGridShowRowButton',{staticClass:"timeline-grid__show-row-button timeline-grid__show-row-button--goto-end",attrs:{"label":_vm.getRowLabel(row),"date":_vm.getRowDateValue(row, _vm.endDateField).format(),"timezone":_vm.timezone,"tooltip-position":`bottom-left`,"icon":"iconoir-nav-arrow-right"},on:{"mousedown":function($event){return _vm.scrollToEnd(row, $event)}}}):_vm._e()],1):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }