var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-view__sidebar"},[_c('div',{staticClass:"form-view__sidebar-fields"},[_c('div',{staticClass:"form-view__sidebar-fields-head"},[_c('div',{staticClass:"form-view__sidebar-fields-title"},[_vm._v("Fields")]),_vm._v(" "),(!_vm.readOnly && !_vm.isDeactivated)?_c('ul',{staticClass:"form-view__sidebar-fields-actions"},[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.fields.length > 0),expression:"fields.length > 0"}]},[_c('a',{on:{"click":function($event){return _vm.updateFieldOptionsOfFields(
                _vm.view,
                _vm.fields,
                { enabled: true },
                true
              )}}},[_vm._v(_vm._s(_vm.$t('formSidebar.actions.addAll')))])]),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.enabledFields.length > 0),expression:"enabledFields.length > 0"}]},[_c('a',{on:{"click":function($event){return _vm.updateFieldOptionsOfFields(
                _vm.view,
                _vm.enabledFields,
                { enabled: false },
                true
              )}}},[_vm._v(_vm._s(_vm.$t('formSidebar.actions.removeAll')))])])]):_vm._e()]),_vm._v(" "),(_vm.fields.length > 0)?_c('div',{staticClass:"form-view__sidebar-fields-list"},_vm._l((_vm.fields),function(field){return _c('FormViewSidebarField',{directives:[{name:"sortable",rawName:"v-sortable",value:({
          enabled: !_vm.readOnly && !_vm.isDeactivated,
          id: field.id,
          update: _vm.order,
        }),expression:"{\n          enabled: !readOnly && !isDeactivated,\n          id: field.id,\n          update: order,\n        }"}],key:field.id,attrs:{"field":field,"read-only":_vm.readOnly || _vm.isDeactivated},on:{"updated-field-options":function($event){return _vm.updateFieldOptionsOfField(_vm.view, field, $event)}}})}),1):_c('p',{staticClass:"form-view__sidebar-fields-description"},[_vm._v("\n      "+_vm._s(_vm.$t('formSidebar.fieldsDescription'))+"\n    ")]),_vm._v(" "),(!_vm.readOnly && !_vm.isDeactivated)?_c('div',[_c('span',{ref:"createFieldContextLink"},[_c('ButtonText',{attrs:{"icon":"iconoir-plus"},on:{"click":function($event){return _vm.$refs.createFieldContext.toggle(_vm.$refs.createFieldContextLink)}}},[_vm._v("\n          "+_vm._s(_vm.$t('formSidebar.actions.addField'))+"\n        ")])],1),_vm._v(" "),_c('CreateFieldContext',{ref:"createFieldContext",attrs:{"table":_vm.table,"view":_vm.view,"all-fields-in-table":_vm.allFieldsInTable,"database":_vm.database},on:{"field-created":function($event){return $event.callback()}}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-view__sidebar-prefill-or-hide-link"},[_c('a',{on:{"click":_vm.showFormPrefillOrHideModal}},[_c('i',{staticClass:"iconoir-chat-bubble-question"}),_vm._v("\n      "+_vm._s(_vm.$t('formSidebar.prefillOrHideInfoLink'))+"\n    ")]),_vm._v(" "),_c('FormPrefillOrHideModal',{ref:"formPrefillOrHideModal"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }