var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}}},[_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('iframeElementForm.sourceTypeLabel'),"small-label":"","required":""}},[_c('RadioGroup',{attrs:{"options":_vm.iframeSourceTypeOptions,"type":"button"},model:{value:(_vm.values.source_type),callback:function ($$v) {_vm.$set(_vm.values, "source_type", $$v)},expression:"values.source_type"}})],1),_vm._v(" "),(_vm.values.source_type === _vm.IFRAME_SOURCE_TYPES.URL)?_c('FormGroup',{key:"url",staticClass:"margin-bottom-2",attrs:{"small-label":"","label":_vm.$t('iframeElementForm.urlLabel'),"required":""},scopedSlots:_vm._u([{key:"helper",fn:function(){return [_vm._v(_vm._s(_vm.$t('iframeElementForm.urlHelp')))]},proxy:true}],null,false,3411928783)},[_c('InjectedFormulaInput',{attrs:{"placeholder":_vm.$t('iframeElementForm.urlPlaceholder')},model:{value:(_vm.values.url),callback:function ($$v) {_vm.$set(_vm.values, "url", $$v)},expression:"values.url"}})],1):_vm._e(),_vm._v(" "),(_vm.values.source_type === _vm.IFRAME_SOURCE_TYPES.EMBED)?_c('FormGroup',{key:"embed",staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('iframeElementForm.embedLabel'),"small-label":"","required":""}},[_c('InjectedFormulaInput',{attrs:{"placeholder":_vm.$t('iframeElementForm.embedPlaceholder')},model:{value:(_vm.values.embed),callback:function ($$v) {_vm.$set(_vm.values, "embed", $$v)},expression:"values.embed"}})],1):_vm._e(),_vm._v(" "),_c('FormGroup',{attrs:{"label":_vm.$t('iframeElementForm.heightLabel'),"small-label":"","required":"","error-message":_vm.$v.values.height.$dirty && !_vm.$v.values.height.required
        ? _vm.$t('error.requiredField')
        : !_vm.$v.values.height.integer
        ? _vm.$t('error.integerField')
        : !_vm.$v.values.height.minValue
        ? _vm.$t('error.minValueField', { min: 1 })
        : !_vm.$v.values.height.maxValue
        ? _vm.$t('error.maxValueField', { max: 2000 })
        : ''}},[_c('FormInput',{attrs:{"type":"number","placeholder":_vm.$t('iframeElementForm.heightPlaceholder'),"to-value":(value) => parseInt(value)},model:{value:(_vm.values.height),callback:function ($$v) {_vm.$set(_vm.values, "height", $$v)},expression:"values.height"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }