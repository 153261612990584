var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasPermission)?_c('nuxt-link',{attrs:{"to":{
    name: 'workspace-audit-log',
    params: {
      workspaceId: _vm.workspace.id,
    },
  }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isExactActive }){return [_c('li',{staticClass:"tree__item",class:{
      'tree__item--loading': _vm.loading,
      'tree__action--deactivated': _vm.deactivated,
      active: isExactActive,
    }},[_c('div',{staticClass:"tree__action"},[(_vm.deactivated)?_c('a',{staticClass:"tree__link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.enterpriseModal.show()}}},[_c('i',{staticClass:"tree__icon iconoir-lock"}),_vm._v(" "),_c('span',{staticClass:"tree__link-text"},[_vm._v(_vm._s(_vm.$t('auditLogSidebarWorkspace.title')))])]):_c('a',{staticClass:"tree__link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"tree__icon baserow-icon-history"}),_vm._v(" "),_c('span',{staticClass:"tree__link-text"},[_vm._v(_vm._s(_vm.$t('auditLogSidebarWorkspace.title')))])])]),_vm._v(" "),_c('EnterpriseModal',{ref:"enterpriseModal",attrs:{"workspace":_vm.workspace,"name":_vm.$t('auditLogSidebarWorkspace.title')}})],1)]}}],null,false,3269258677)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }