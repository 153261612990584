var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gallery-view"},[(
      !_vm.readOnly &&
      // Can't create rows in a table data sync table.
      !_vm.table.data_sync &&
      _vm.$hasPermission(
        'database.table.create_row',
        _vm.table,
        _vm.database.workspace.id
      )
    )?_c('ButtonFloating',{attrs:{"icon":"iconoir-plus","position":"fixed"},on:{"click":function($event){return _vm.$refs.rowCreateModal.show()}}}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"auto-scroll",rawName:"v-auto-scroll",value:({
      enabled: () => _vm.dragAndDropDraggingRow !== null,
      speed: 5,
      padding: 20,
    }),expression:"{\n      enabled: () => dragAndDropDraggingRow !== null,\n      speed: 5,\n      padding: 20,\n    }"}],ref:"scroll",staticClass:"gallery-view__scroll"},[_c('div',{staticClass:"gallery-view__cards",class:{
        'gallery-view__cards--dragging': _vm.dragAndDropDraggingRow !== null,
      },style:({
        height: _vm.height + 'px',
      })},_vm._l((_vm.buffer),function(slot){return _c('RowCard',{directives:[{name:"show",rawName:"v-show",value:(slot.item !== undefined),expression:"slot.item !== undefined"}],key:'card-' + slot.id,staticClass:"gallery-view__card",class:{
          'gallery-view__card--dragging': slot.item && slot.item._.dragging,
        },style:({
          width: _vm.cardWidth + 'px',
          height: slot.item === null ? _vm.cardHeight + 'px' : undefined,
          transform: `translateX(${slot.position.left || 0}px) translateY(${
            slot.position.top || 0
          }px)`,
        }),attrs:{"fields":_vm.cardFields,"row":slot.item || {},"workspace-id":_vm.database.workspace.id,"loading":slot.item === null,"cover-image-field":_vm.coverImageField,"decorations-by-place":_vm.decorationsByPlace},on:{"mousedown":function($event){_vm.rowDown(
            $event,
            slot.item,
            _vm.readOnly ||
              !_vm.$hasPermission(
                'database.table.move_row',
                _vm.table,
                _vm.database.workspace.id
              )
          )},"mousemove":function($event){return _vm.rowMoveOver($event, slot.item)},"mouseenter":function($event){return _vm.rowMoveOver($event, slot.item)}}})}),1)]),_vm._v(" "),(
      !_vm.readOnly &&
      _vm.$hasPermission(
        'database.table.create_row',
        _vm.table,
        _vm.database.workspace.id
      )
    )?_c('RowCreateModal',{ref:"rowCreateModal",attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view,"primary-is-sortable":true,"visible-fields":_vm.cardFields,"hidden-fields":_vm.hiddenFields,"show-hidden-fields":_vm.showHiddenFieldsInRowModal,"all-fields-in-table":_vm.fields},on:{"toggle-hidden-fields-visibility":function($event){_vm.showHiddenFieldsInRowModal = !_vm.showHiddenFieldsInRowModal},"created":_vm.createRow,"order-fields":_vm.orderFields,"toggle-field-visibility":_vm.toggleFieldVisibility,"field-updated":function($event){return _vm.$emit('refresh', $event)},"field-deleted":function($event){return _vm.$emit('refresh')}}}):_vm._e(),_vm._v(" "),_c('RowEditModal',{ref:"rowEditModal",attrs:{"enable-navigation":"","database":_vm.database,"table":_vm.table,"view":_vm.view,"all-fields-in-table":_vm.fields,"primary-is-sortable":true,"visible-fields":_vm.cardFields,"hidden-fields":_vm.hiddenFields,"rows":_vm.allRows,"read-only":_vm.readOnly ||
      !_vm.$hasPermission(
        'database.table.update_row',
        _vm.table,
        _vm.database.workspace.id
      ),"show-hidden-fields":_vm.showHiddenFieldsInRowModal},on:{"hidden":function($event){return _vm.$emit('selected-row', undefined)},"toggle-hidden-fields-visibility":function($event){_vm.showHiddenFieldsInRowModal = !_vm.showHiddenFieldsInRowModal},"update":_vm.updateValue,"order-fields":_vm.orderFields,"toggle-field-visibility":_vm.toggleFieldVisibility,"field-updated":function($event){return _vm.$emit('refresh', $event)},"field-deleted":function($event){return _vm.$emit('refresh')},"field-created":_vm.showFieldCreated,"field-created-callback-done":_vm.afterFieldCreatedUpdateFieldOptions,"navigate-previous":function($event){return _vm.$emit('navigate-previous', $event, _vm.activeSearchTerm)},"navigate-next":function($event){return _vm.$emit('navigate-next', $event, _vm.activeSearchTerm)},"refresh-row":_vm.refreshRow}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }