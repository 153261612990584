var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-view"},[_c('FormViewSidebar',{attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view,"fields":_vm.disabledFields,"enabled-fields":_vm.enabledFields,"all-fields-in-table":_vm.fields,"read-only":_vm.readOnly ||
      !_vm.$hasPermission(
        'database.table.view.update_field_options',
        _vm.view,
        _vm.database.workspace.id
      ),"store-prefix":_vm.storePrefix},on:{"ordered-fields":_vm.orderFields,"refresh":function($event){return _vm.$emit('refresh', $event)}}}),_vm._v(" "),_c('FormViewPreview',{attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view,"fields":_vm.enabledFields,"read-only":_vm.readOnly ||
      !_vm.$hasPermission(
        'database.table.view.update',
        _vm.view,
        _vm.database.workspace.id
      ),"store-prefix":_vm.storePrefix},on:{"ordered-fields":_vm.orderFields}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }