<template functional>
  <div class="card-boolean">
    <i v-if="!!props.value" class="iconoir-check"></i>
  </div>
</template>

<script>
export default {
  height: 13,
}
</script>
