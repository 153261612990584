var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{ref:"header",staticClass:"layout__col-2-1 header",class:{ 'header--overflow': _vm.headerOverflow }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableLoading),expression:"tableLoading"}],staticClass:"header__loading"}),_vm._v(" "),(!_vm.tableLoading)?_c('ul',{staticClass:"header__filter"},[(_vm.showLogo)?_c('li',{staticClass:"header__filter-item"},[_c('ExternalLinkBaserowLogo',{staticClass:"header__filter-logo"})],1):_vm._e(),_vm._v(" "),_c('li',{staticClass:"header__filter-item header__filter-item--grids"},[_c('a',{ref:"viewsSelectToggle",staticClass:"header__filter-link",class:{ 'header__filter-link--disabled': _vm.views === null },on:{"click":function($event){_vm.views !== null &&
              _vm.$refs.viewsContext.toggle(
                _vm.$refs.viewsSelectToggle,
                'bottom',
                'left',
                4
              )}}},[(_vm.hasSelectedView)?[_c('i',{staticClass:"header__filter-icon header-filter-icon--view",class:`${_vm.view._.type.colorClass} ${_vm.view._.type.iconClass}`}),_vm._v(" "),_c('span',{staticClass:"header__filter-name header__filter-name--forced"},[_c('EditableViewName',{ref:"rename",attrs:{"view":_vm.view}})],1),_vm._v(" "),_c('i',{staticClass:"header__sub-icon iconoir-nav-arrow-down"})]:(_vm.view !== null)?[_vm._v("\n            "+_vm._s(_vm.$t('table.chooseView'))+"\n            "),_c('i',{staticClass:"header__sub-icon iconoir-nav-arrow-down"})]:_vm._e()],2),_vm._v(" "),(_vm.views !== null)?_c('ViewsContext',{ref:"viewsContext",attrs:{"database":_vm.database,"table":_vm.table,"views":_vm.views,"read-only":_vm.readOnly,"header-overflow":_vm.headerOverflow},on:{"selected-view":function($event){return _vm.$emit('selected-view', $event)}}}):_vm._e()],1),_vm._v(" "),(_vm.hasSelectedView && !_vm.readOnly && _vm.showViewContext)?_c('li',{staticClass:"header__filter-item header__filter-item--no-margin-left"},[_c('a',{staticClass:"header__filter-link",on:{"click":function($event){return _vm.$refs.viewContext.toggle(
              $event.currentTarget,
              'bottom',
              'left',
              4
            )}}},[_c('i',{staticClass:"header__filter-icon baserow-icon-more-vertical"})]),_vm._v(" "),_c('ViewContext',{ref:"viewContext",attrs:{"database":_vm.database,"view":_vm.view,"table":_vm.table},on:{"enable-rename":function($event){return _vm.$refs.rename.edit()}}})],1):_vm._e(),_vm._v(" "),(
          _vm.hasSelectedView &&
          _vm.view._.type.canFilter &&
          (_vm.adhocFiltering ||
            _vm.$hasPermission(
              'database.table.view.create_filter',
              _vm.view,
              _vm.database.workspace.id
            ))
        )?_c('li',{staticClass:"header__filter-item"},[_c('ViewFilter',{attrs:{"view":_vm.view,"is-public-view":_vm.isPublic,"fields":_vm.fields,"read-only":_vm.adhocFiltering,"disable-filter":_vm.disableFilter},on:{"changed":function($event){return _vm.refresh()}}})],1):_vm._e(),_vm._v(" "),(
          _vm.hasSelectedView &&
          _vm.view._.type.canSort &&
          (_vm.adhocSorting ||
            _vm.$hasPermission(
              'database.table.view.create_sort',
              _vm.view,
              _vm.database.workspace.id
            ))
        )?_c('li',{staticClass:"header__filter-item"},[_c('ViewSort',{attrs:{"view":_vm.view,"fields":_vm.fields,"read-only":_vm.adhocSorting,"disable-sort":_vm.disableSort},on:{"changed":function($event){return _vm.refresh()}}})],1):_vm._e(),_vm._v(" "),(
          _vm.hasSelectedView &&
          _vm.view._.type.canGroupBy &&
          (_vm.readOnly ||
            _vm.$hasPermission(
              'database.table.view.create_group_by',
              _vm.view,
              _vm.database.workspace.id
            ))
        )?_c('li',{staticClass:"header__filter-item"},[_c('ViewGroupBy',{attrs:{"view":_vm.view,"fields":_vm.fields,"read-only":_vm.readOnly,"disable-group-by":_vm.disableGroupBy},on:{"changed":function($event){return _vm.refresh()}}})],1):_vm._e(),_vm._v(" "),(
          _vm.hasSelectedView &&
          _vm.view._.type.canShare &&
          !_vm.readOnly &&
          _vm.$hasPermission(
            'database.table.view.update_slug',
            _vm.view,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"header__filter-item"},[_c('ShareViewLink',{attrs:{"view":_vm.view,"read-only":_vm.readOnly}})],1):_vm._e(),_vm._v(" "),(
          _vm.hasCompatibleDecorator &&
          !_vm.readOnly &&
          _vm.$hasPermission(
            'database.table.view.decoration.update',
            _vm.view,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"header__filter-item"},[_c('ViewDecoratorMenu',{attrs:{"database":_vm.database,"view":_vm.view,"table":_vm.table,"fields":_vm.fields,"read-only":_vm.readOnly,"disable-sort":_vm.disableSort},on:{"changed":function($event){return _vm.refresh()}}})],1):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.tableLoading && _vm.hasSelectedView)?_c(_vm.getViewHeaderComponent(_vm.view),{tag:"component",attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view,"fields":_vm.fields,"read-only":_vm.readOnly,"store-prefix":_vm.storePrefix},on:{"refresh":_vm.refresh}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"layout__col-2-2 content"},[(_vm.hasSelectedView && !_vm.tableLoading)?_c(_vm.getViewComponent(_vm.view),{ref:"view",tag:"component",attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view,"loading":_vm.viewLoading,"fields":_vm.fields,"read-only":_vm.readOnly,"store-prefix":_vm.storePrefix},on:{"refresh":_vm.refresh,"selected-row":function($event){return _vm.$emit('selected-row', $event)},"navigate-previous":(row, activeSearchTerm) =>
          _vm.$emit('navigate-previous', row, activeSearchTerm),"navigate-next":(row, activeSearchTerm) =>
          _vm.$emit('navigate-next', row, activeSearchTerm)}}):_vm._e(),_vm._v(" "),(_vm.viewLoading)?_c('div',{staticClass:"loading-overlay"}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }