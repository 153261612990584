<template functional>
  <li
    class="saas-header__sub-nav-item"
    :class="
      props.description ? 'saas-header__sub-nav-item--with-description' : ''
    "
  >
    <component
      :is="props.to ? 'nuxt-link' : 'a'"
      :to="props.to"
      v-bind="
        props.to
          ? {}
          : {
              href: props.href,
              target: props.target,
              rel: props.rel,
            }
      "
      class="saas-header__sub-nav-link"
    >
      <div>
        <div class="saas-header__sub-nav-icon">
          <i :class="props.icon"></i>
        </div>
      </div>
      <div class="saas-header__sub-nav-name">
        <div class="saas-header__sub-nav-name-title">
          {{ props.title }}
        </div>
        <div
          v-if="props.description"
          class="saas-header__sub-nav-name-description"
        >
          {{ props.description }}
        </div>
      </div>
    </component>
  </li>
</template>

<script>
export default {
  name: 'HeaderSubNavItem',
  functional: true,
  props: {
    to: {
      required: false,
      type: [String, Object],
      default: () => '',
    },
    href: {
      required: false,
      type: [String],
      default: () => '',
    },
    target: {
      required: false,
      type: [String],
      default: () => '',
    },
    rel: {
      required: false,
      type: [String],
      default: () => '',
    },
    icon: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      required: false,
      type: String,
      default: () => '',
    },
  },
}
</script>
