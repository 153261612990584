var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-view-survey"},[(_vm.isDeactivated)?_c('div',{staticClass:"form-view-survey__deactivated"},[_c('div',[_c('div',{staticClass:"form-view-survey__deactivated-content"},[_c('div',{staticClass:"margin-bottom-2"},[_vm._v("\n          "+_vm._s(_vm.$t('formViewModePreviewSurvey.deactivated'))+"\n        ")]),_vm._v(" "),_c('Button',{attrs:{"type":"primary","icon":"iconoir-no-lock"},on:{"click":function($event){return _vm.$refs.premiumModal.show()}}},[_vm._v("\n          "+_vm._s(_vm.$t('formViewModePreviewSurvey.more'))+"\n        ")])],1),_vm._v(" "),_c('PremiumModal',{ref:"premiumModal",attrs:{"name":_vm.modeType.getName()}})],1)]):[_c('form',[_vm._l((_vm.fields),function(field,index){return _c('div',{key:field.id,staticClass:"form-view-survey__center",class:{
          'form-view-survey__center--before': index < _vm.questionIndex,
          'form-view-survey__center--after': index > _vm.questionIndex,
        }},[_c('div',{staticClass:"form-view-survey__center-inner-1"},[_c('div',{staticClass:"form-view-survey__center-inner-2"},[_c('FormViewField',{attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view,"field":field,"field-options":_vm.fieldOptions[field.id],"fields":_vm.fields,"read-only":_vm.readOnly,"add-order-buttons":true,"add-handle":false},on:{"hide":function($event){return _vm.hideField(_vm.view, field)},"updated-field-options":function($event){return _vm.updateFieldOptionsOfField(_vm.view, field, $event)}}}),_vm._v(" "),_c('div',{staticClass:"form-view__field-actions"},[(index < _vm.fields.length - 1)?_c('Button',{attrs:{"type":"primary","size":"large"},on:{"click":function($event){return _vm.next()}}},[_vm._v("Next")]):(index >= _vm.fields.length - 1)?[_c('Editable',{ref:"submitText",refInFor:true,staticClass:"button button--primary button--large",class:{
                    'form-view__submit-button-editing': _vm.editingSubmitText,
                  },attrs:{"value":_vm.view.submit_text},on:{"change":function($event){return _vm.updateForm({ submit_text: $event.value })},"editing":function($event){_vm.editingSubmitText = $event},"click":function($event){return _vm.next()}}}),_vm._v(" "),(!_vm.readOnly)?_c('a',{staticClass:"form-view__edit",class:{ 'form-view__edit--hidden': _vm.editingSubmitText },on:{"click":function($event){return _vm.$refs.submitText[0].edit()}}},[_c('i',{staticClass:"form-view__edit-icon iconoir-edit-pencil"})]):_vm._e()]:_vm._e()],2)],1)])])}),_vm._v(" "),_c('div',{staticClass:"form-view-survey__center",class:{
          'form-view-survey__center--after': _vm.questionIndex < _vm.fields.length,
        }},[_c('div',{staticClass:"form-view-survey__center-inner-1"},[_c('div',{staticClass:"form-view-survey__center-inner-2"},[(_vm.fields.length === 0)?_c('div',{staticClass:"form-view__no-fields"},[_vm._v("\n              "+_vm._s(_vm.$t('formViewModePreviewForm.noFields'))+"\n            ")]):_c('FormViewMetaControls',{attrs:{"view":_vm.view,"database":_vm.database,"read-only":_vm.readOnly},on:{"updated-form":function($event){return _vm.updateForm($event)}}})],1)])])],2),_vm._v(" "),_c('div',{staticClass:"form-view-survey__footer form-view-survey__footer--absolute",class:{ 'form-view-survey__footer--single': !_vm.view.show_logo }},[(_vm.view.show_logo)?_c('FormViewPoweredBy'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-view-survey__pagination"},[(!_vm.readOnly)?_c('a',{ref:"customizeContextLink",staticClass:"form-view-survey__order-fields",on:{"click":function($event){return _vm.$refs.customizeContext.toggle(
              _vm.$refs.customizeContextLink,
              'top',
              'left',
              4
            )}}},[_vm._v(_vm._s(_vm.$t('formViewModePreviewSurvey.orderFields')))]):_vm._e(),_vm._v(" "),_c('ViewFieldsContext',{ref:"customizeContext",attrs:{"database":_vm.database,"view":_vm.view,"fields":_vm.fields,"field-options":_vm.fieldOptions,"allow-hiding-fields":false},on:{"update-order":function($event){return _vm.$emit('ordered-fields', $event.order)}}}),_vm._v(" "),_c('div',{staticClass:"form-view-survey__pagination-buttons"},[_c('a',{staticClass:"form-view-survey__pagination-button",class:{
              'form-view-survey__pagination-button--disabled': !_vm.canPrevious,
            },on:{"click":_vm.previous}},[_c('i',{staticClass:"iconoir-nav-arrow-up"})]),_vm._v(" "),_c('a',{staticClass:"form-view-survey__pagination-button",class:{
              'form-view-survey__pagination-button--disabled': !_vm.canNext,
            },on:{"click":_vm.next}},[_c('i',{staticClass:"iconoir-nav-arrow-down"})])])],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }