var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":() => {
          if (_vm.deactivated) {
            _vm.$refs.enterpriseModal.show()
          } else {
            _vm.$refs.memberRolesModal.show()
          }
        }}},[_c('i',{staticClass:"context__menu-item-icon iconoir-community"}),_vm._v("\n      "+_vm._s(_vm.$t('memberRolesDatabaseContexItem.label'))+"\n      "),(_vm.deactivated)?_c('div',{staticClass:"deactivated-label"},[_c('i',{staticClass:"iconoir-lock"})]):_vm._e()])]),_vm._v(" "),_c('MemberRolesModal',{ref:"memberRolesModal",attrs:{"database":_vm.database,"table":_vm.table}}),_vm._v(" "),_c('EnterpriseModal',{ref:"enterpriseModal",attrs:{"name":_vm.$t('memberRolesTableContexItem.additionalRoles'),"workspace":_vm.database.workspace}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }