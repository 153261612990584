import { AdminType } from '@baserow/modules/core/adminTypes'

export class LicenseAuthorityAdminType extends AdminType {
  static getType() {
    return 'license-authority'
  }

  getIconClass() {
    return 'iconoir-home-shield'
  }

  getName() {
    return 'License authority'
  }

  getCategory() {
    const { i18n } = this.app
    return i18n.t('sidebar.licenses')
  }

  getRouteName() {
    return 'admin-license-authority'
  }

  getOrder() {
    return 10001
  }
}
