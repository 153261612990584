var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"margin-bottom-2"},[_vm._v("\n    "+_vm._s(_vm.$t('propertyOptionForm.formDescription'))+"\n  ")]),_vm._v(" "),_c('BaserowTable',{staticClass:"property-option-form__table",attrs:{"fields":_vm.fields,"rows":_vm.rows},scopedSlots:_vm._u([{key:"field-name",fn:function({ field }){return [_c('th',{key:field.__id__,staticClass:"baserow-table__header-cell",class:{
          'property-option-form__table-field': !field.isOption,
        }},[_vm._t("field-name",function(){return [_vm._v(_vm._s(field.name))]},{"field":field})],2)]}},{key:"cell-content",fn:function({ rowIndex, value, field }){return [(field.isOption)?_c('td',{key:field.name,staticClass:"baserow-table__cell property-option-form__table-option"},[_c('Checkbox',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            _vm.optionIsDisabled(_vm.rows[rowIndex], field.name)
              ? _vm.$t('propertyOptionForm.optionUnavailable')
              : null
          ),expression:"\n            optionIsDisabled(rows[rowIndex], field.name)\n              ? $t('propertyOptionForm.optionUnavailable')\n              : null\n          "}],attrs:{"tooltip-position":"top","checked":value,"disabled":_vm.optionIsDisabled(_vm.rows[rowIndex], field.name)},on:{"input":function($event){return _vm.onOptionChange(_vm.rows[rowIndex], field.property, $event)}}})],1):_c('td',{key:field.name,staticClass:"baserow-table__cell property-option-form__table-property"},[_c('span',{attrs:{"title":value}},[_vm._v(_vm._s(value))])])]}},{key:"empty-state",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.$t('propertyOptionForm.noPropertiesAvailable'))+"\n    ")]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }