var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filters__group-item"},[(_vm.groupNode.filters.length)?_c('div',{staticClass:"filters__group-item-filters"},_vm._l((_vm.groupNode.filtersOrdered()),function(filter,filterIndex){return _c('div',{key:filterIndex,staticClass:"filters__item-wrapper"},[_c('div',{staticClass:"filters__item filters__item--level-2"},[_c('ViewFilterFormOperator',{attrs:{"index":filterIndex,"filter-type":_vm.groupNode.group.filter_type,"disable-filter":_vm.disableFilter},on:{"updateFilterType":function($event){return _vm.$emit('updateFilterType', {
              value: $event,
              filterGroup: _vm.groupNode.group,
            })}}}),_vm._v(" "),_c('ViewFieldConditionItem',{ref:`condition-${filter.id}`,refInFor:true,attrs:{"filter":filter,"view":_vm.view,"is-public-view":_vm.isPublicView,"fields":_vm.fields,"disable-filter":_vm.disableFilter,"read-only":_vm.readOnly},on:{"updateFilter":function($event){return _vm.$emit('updateFilter', { filter, values: $event })},"deleteFilter":function($event){return _vm.$emit('deleteFilter', { filter, event: $event })}},scopedSlots:_vm._u([{key:"filterInputComponent",fn:function({ slotProps }){return [_vm._t("filterInputComponent",null,{"slotProps":slotProps})]}},{key:"afterValueInput",fn:function({ slotProps }){return [_vm._t("afterValueInput",null,{"slotProps":slotProps})]}}],null,true)})],1)])}),0):_vm._e(),_vm._v(" "),_vm._l((_vm.groupNode.children),function(subGroupNode,subGroupIndex){return _c('div',{key:_vm.groupNode.filters.length + subGroupIndex,staticClass:"filters__group-item-wrapper filters__group-item-wrapper--inner"},[_c('ViewFilterFormOperator',{attrs:{"index":_vm.groupNode.filters.length + subGroupIndex,"filter-type":_vm.groupNode.group.filter_type,"disable-filter":_vm.disableFilter},on:{"updateFilterType":function($event){return _vm.$emit('updateFilterType', {
          value: $event,
          filterGroup: _vm.groupNode.group,
        })}}}),_vm._v(" "),_c('ViewFieldConditionGroup',{attrs:{"group-node":subGroupNode,"disable-filter":_vm.disableFilter,"is-public-view":_vm.isPublicView,"read-only":_vm.readOnly,"fields":_vm.fields,"view":_vm.view,"can-add-filter-groups":false,"add-condition-string":_vm.addConditionString,"add-condition-group-string":_vm.addConditionGroupString},on:{"addFilter":function($event){return _vm.$emit('addFilter', $event)},"addFilterGroup":function($event){return _vm.$emit('addFilterGroup', $event)},"updateFilter":function($event){return _vm.$emit('updateFilter', $event)},"deleteFilter":function($event){return _vm.$emit('deleteFilter', $event)},"updateFilterType":function($event){return _vm.$emit('updateFilterType', $event)}}})],1)}),_vm._v(" "),(!_vm.disableFilter)?_c('div',{staticClass:"filters__group-item-actions"},[_c('ButtonText',{staticClass:"filters__group-item-action--add-filter",attrs:{"icon":"iconoir-plus"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('addFilter', { filterGroupId: _vm.groupNode.group.id })}}},[_vm._v("\n      "+_vm._s(_vm.addConditionLabel))]),_vm._v(" "),(_vm.canAddFilterGroups)?_c('ButtonText',{staticClass:"filters__group-item-action--add-filter-group",attrs:{"icon":"iconoir-plus"},on:{"click":function($event){$event.preventDefault();_vm.$emit('addFilterGroup', {
          filterGroupId: _vm.uuidv1(),
          parentGroupId: _vm.groupNode.group.id,
        })}}},[_vm._v("\n      "+_vm._s(_vm.addConditionGroupLabel))]):_vm._e()],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }