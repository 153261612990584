var render = function render(_c,_vm){return _c('li',{staticClass:"saas-header__sub-nav-item",class:_vm.props.description ? 'saas-header__sub-nav-item--with-description' : ''},[_c(_vm.props.to ? 'nuxt-link' : 'a',_vm._b({tag:"component",staticClass:"saas-header__sub-nav-link",attrs:{"to":_vm.props.to}},'component',
      _vm.props.to
        ? {}
        : {
            href: _vm.props.href,
            target: _vm.props.target,
            rel: _vm.props.rel,
          }
    ,false),[_c('div',[_c('div',{staticClass:"saas-header__sub-nav-icon"},[_c('i',{class:_vm.props.icon})])]),_vm._v(" "),_c('div',{staticClass:"saas-header__sub-nav-name"},[_c('div',{staticClass:"saas-header__sub-nav-name-title"},[_vm._v("\n        "+_vm._s(_vm.props.title)+"\n      ")]),_vm._v(" "),(_vm.props.description)?_c('div',{staticClass:"saas-header__sub-nav-name-description"},[_vm._v("\n        "+_vm._s(_vm.props.description)+"\n      ")]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }