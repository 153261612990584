export const state = () => ({
  navOpen: false,
  topBars: [],
})

export const mutations = {
  SET_NAV_OPEN(state, value) {
    state.navOpen = value
  },
  SET_TOP_BARS(state, value) {
    state.topBars = value
  },
}

export const actions = {
  setNavOpen({ commit }, value) {
    commit('SET_NAV_OPEN', value)
  },
  setTopBars({ commit, state }, value) {
    commit('SET_TOP_BARS', value)
  },
}

export const getters = {
  getNavOpen(state) {
    return state.navOpen
  },
  getTopBars(state) {
    return state.topBars
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
