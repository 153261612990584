export default (client) => {
  return {
    _applyFilters(
      url,
      {
        highlighted = false,
        category = false,
        releasesOnly = false,
        includeCategories = false,
      }
    ) {
      if (highlighted) {
        url += `&highlighted=${highlighted}`
      }
      if (category) {
        url += `&category=${category}`
      }
      if (releasesOnly) {
        url += `&releases_only=true`
      }
      if (includeCategories) {
        url += `&include_categories=true`
      }
      return url
    },
    getSummariesWithLimit(limit, filters) {
      const url = `/saas/blog/?limit=${limit}`
      return client.get(this._applyFilters(url, filters))
    },
    getSummariesPage(page, filters) {
      const url = `/saas/blog/?page=${page}`
      return client.get(this._applyFilters(url, filters))
    },
    get(slug) {
      return client.get(`/saas/blog/post/${slug}/`)
    },
  }
}
