import { render, staticRenderFns } from "./GenericViewAggregation.vue?vue&type=template&id=c8ec6928&functional=true"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../baserow-saas/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports