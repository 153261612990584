var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{staticClass:"select user-source-users-context",class:{ 'context--loading-overlay': _vm.state === 'loading' },attrs:{"max-height-if-outside-viewport":""},on:{"shown":_vm.shown}},[_c('SelectSearch',{attrs:{"placeholder":_vm.$t('userSourceUsersContext.searchPlaceholder')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('div',{directives:[{name:"auto-overflow-scroll",rawName:"v-auto-overflow-scroll"}],staticClass:"user-source-users-context__user-list"},[_c('ul',{staticClass:"select__items select__items--no-max-height"},[_c('li',{staticClass:"select__item",class:{
          'select__item--selected': !_vm.isAuthenticated,
          'select__item--loading':
            _vm.currentUser === null && _vm.state === 'loggingIn',
        }},[_c('a',{staticClass:"select__item-link"},[_c('Presentation',{attrs:{"title":_vm.$t('userSourceUsersContext.anonymous'),"size":"medium","initials":_vm._f("nameAbbreviation")(_vm.$t('userSourceUsersContext.anonymous'))},on:{"click":function($event){return _vm.selectUser()}}})],1)]),_vm._v(" "),_vm._l((_vm.userSources),function(userSource){return [(_vm.usersPerUserSources[userSource.id]?.length > 0)?[_c('li',{key:`user_source_${userSource.id}`,staticClass:"select__item-label"},[_vm._v("\n            "+_vm._s(userSource.name)+"\n          ")]),_vm._v(" "),_vm._l((_vm.usersPerUserSources[userSource.id]),function(user){return _c('li',{key:`user_source_${userSource.id}__${user.id}`,staticClass:"select__item",class:{
              'select__item--selected': _vm.isSelectedUser(userSource, user),
              'select__item--loading':
                _vm.isSelectedUser(userSource, user) && _vm.state === 'loggingIn',
            }},[_c('a',{staticClass:"select__item-link"},[_c('Presentation',{attrs:{"title":_vm.getUserTitle(user, userSource.name),"subtitle":user.email || _vm.$t('userSourceUsersContext.noEmail'),"size":"medium","initials":_vm._f("nameAbbreviation")(user.username ||
                  _vm.$t('userSourceUsersContext.unnamed'))},on:{"click":function($event){return _vm.selectUser(user)}}})],1)])})]:_vm._e()]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }