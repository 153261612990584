import BlogService from '@saas/services/blog'
import CmsService from '@saas/services/cms'

export const state = () => ({
  loaded: false,
  highlightedBlogSummaries: [],
  numberOfJobs: 0,
})

export const mutations = {
  SET_BLOG_POST_SUMMARIES(state, value) {
    state.highlightedBlogSummaries = value
  },
  SET_NUMBER_OF_JOBS(state, value) {
    state.numberOfJobs = value
  },
  SET_MENU_PAGES(state, value) {
    state.menuPages = value
  },
  SET_LOADED(state, value) {
    state.loaded = value
  },
}

export const actions = {
  async fetchAll({ commit }) {
    // The request can later be moved to the saas layout endpoint when the blog is
    // migrated to Wagtail.
    const { data } = await BlogService(this.$client).getSummariesWithLimit(8, {
      highlighted: true,
    })
    const { data: saasLayoutData } = await CmsService(
      this.$client
    ).getSaaSLayout()
    commit('SET_BLOG_POST_SUMMARIES', data.results)
    commit('SET_NUMBER_OF_JOBS', saasLayoutData.number_of_jobs)
    commit('SET_MENU_PAGES', saasLayoutData.menu_pages)
    commit('SET_LOADED', true)
  },
}

const sortPages = (pageA, pageB) => {
  const orderA = pageA.navigation_order || 0
  const orderB = pageB.navigation_order || 0
  return orderA - orderB
}

export const getters = {
  getHighlighted(state) {
    return state.highlightedBlogSummaries
  },
  getNumberOfJobs(state) {
    return state.numberOfJobs
  },
  getMenuPages(state) {
    return state.menuPages
  },
  getMenuPagesPerType(state) {
    return (type) =>
      state.menuPages.filter((page) => page.type === type).sort(sortPages)
  },
  getMenuChildPagesPerType(state) {
    return (type) => {
      const parents = state.menuPages.filter((page) => page.type === type)
      return state.menuPages
        .filter((page) => {
          return parents.some(
            (parent) =>
              page.path.startsWith(parent.path) && page.path !== parent.path
          )
        })
        .sort(sortPages)
    }
  },
  isLoaded(state) {
    return state.loaded
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
