<template>
  <div class="saas-progress-bar">
    <div
      class="saas-progress-bar__fill"
      :class="'saas-progress-bar__fill--' + colorClass"
      :style="{ width: `${percentage}%` }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      required: true,
      type: Number,
    },
    total: {
      required: true,
      type: Number,
    },
    baseColorClass: {
      required: false,
      type: String,
      default: 'primary',
    },
    overflowColorClass: {
      required: false,
      type: String,
      default: 'error',
    },
  },
  computed: {
    percentage() {
      return Math.round((this.progress / this.total) * 100)
    },
    isOverflow() {
      return this.progress > this.total
    },
    colorClass() {
      return this.isOverflow ? this.overflowColorClass : this.baseColorClass
    },
  },
}
</script>
