var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline-container"},[(_vm.firstVisibleDate)?_c('div',{staticClass:"timeline-container__header"},[_c('ViewDateIndicator',{attrs:{"date":_vm.firstVisibleDate,"format":_vm.headerFormat}}),_vm._v(" "),_c('div',{staticClass:"timeline-container__header-right"},[_c('ViewDateSelector',{attrs:{"selected-date":_vm.firstVisibleDate,"unit":_vm.timescale},on:{"date-selected":_vm.scrollDateIntoView}}),_vm._v(" "),_c('Button',{staticClass:"timeline-container__header-timescale",attrs:{"type":"secondary","size":"large","icon":`iconoir-nav-arrow-${
          _vm.$refs.timescaleContext?.isOpen() ? 'up' : 'down'
        }`},on:{"click":_vm.toggleTimescaleContext}},[_vm._v("\n        "+_vm._s(_vm.$t(`timelineTimescaleContext.${_vm.view.timescale}`))+"\n      ")]),_vm._v(" "),_c('TimelineTimescaleContext',{ref:"timescaleContext",attrs:{"timescale":_vm.view.timescale,"start-date-field":_vm.startDateField},on:{"select":_vm.updateTimescale}})],1)],1):_vm._e(),_vm._v(" "),_c('div',{ref:"gridHeader",staticClass:"timeline-container__grid-header"},[(_vm.gridReady)?_c('TimelineGridHeader',{attrs:{"columns-buffer":_vm.columnsBuffer,"column-count":_vm.columnCount,"column-width":_vm.columnWidth,"date-format":_vm.columnHeaderFormat}}):_vm._e()],1),_vm._v(" "),_c('div',{directives:[{name:"auto-scroll",rawName:"v-auto-scroll",value:({
      enabled: () =>
        _vm.enableAutoScroll && _vm.$refs.gridBody.scrollLeft < _vm.scrollLeftLimit,
      orientation: 'horizontal',
      speed: 5,
      padding: 20,
    }),expression:"{\n      enabled: () =>\n        enableAutoScroll && $refs.gridBody.scrollLeft < scrollLeftLimit,\n      orientation: 'horizontal',\n      speed: 5,\n      padding: 20,\n    }"}],ref:"gridBody",staticClass:"timeline-container__grid-body"},[(_vm.gridReady)?_c('TimelineGrid',{attrs:{"columns-buffer":_vm.columnsBuffer,"column-width":_vm.columnWidth,"column-count":_vm.columnCount,"column-unit":_vm.unit,"rows-buffer":_vm.rowsBuffer,"row-count":_vm.rowCount,"row-height":_vm.rowHeight,"min-grid-height":_vm.gridHeight,"start-date-field":_vm.startDateField,"end-date-field":_vm.endDateField,"visible-fields":_vm.visibleFields,"first-available-date":_vm.firstAvailableDate,"last-available-date":_vm.lastAvailableDate,"step":_vm.step,"read-only":_vm.readOnly ||
        !_vm.$hasPermission(
          'database.table.update_row',
          _vm.table,
          _vm.database.workspace.id
        ),"decorations-by-place":_vm.decorationsByPlace,"scroll-left":_vm.prevScrollLeft},on:{"scroll-to-date":_vm.scrollDateIntoView,"edit-row":function($event){return _vm.openRowEditModal($event)},"updating-row":function($event){_vm.enableAutoScroll = $event.value},"update-row":function($event){return _vm.updateRowDates($event)}}}):_vm._e()],1),_vm._v(" "),(
      !_vm.readOnly &&
      _vm.$hasPermission(
        'database.table.create_row',
        _vm.table,
        _vm.database.workspace.id
      )
    )?_c('ButtonFloating',{attrs:{"icon":"iconoir-plus","position":"fixed"},on:{"click":function($event){return _vm.$refs.rowCreateModal.show()}}}):_vm._e(),_vm._v(" "),_c('RowEditModal',{ref:"rowEditModal",attrs:{"enable-navigation":"","database":_vm.database,"table":_vm.table,"view":_vm.view,"all-fields-in-table":_vm.fields,"primary-is-sortable":true,"visible-fields":_vm.visibleFields,"hidden-fields":_vm.hiddenFields,"rows":_vm.rows,"read-only":_vm.readOnly ||
      !_vm.$hasPermission(
        'database.table.update_row',
        _vm.table,
        _vm.database.workspace.id
      ),"show-hidden-fields":_vm.showHiddenFieldsInRowModal},on:{"hidden":function($event){return _vm.$emit('selected-row', undefined)},"toggle-hidden-fields-visibility":function($event){_vm.showHiddenFieldsInRowModal = !_vm.showHiddenFieldsInRowModal},"update":_vm.updateValue,"order-fields":_vm.orderFields,"toggle-field-visibility":_vm.toggleFieldVisibility,"field-updated":function($event){return _vm.$emit('refresh', $event)},"field-deleted":function($event){return _vm.$emit('refresh')},"field-created":_vm.showFieldCreated,"field-created-callback-done":_vm.afterFieldCreatedUpdateFieldOptions,"navigate-previous":function($event){return _vm.$emit('navigate-previous', $event, _vm.activeSearchTerm)},"navigate-next":function($event){return _vm.$emit('navigate-next', $event, _vm.activeSearchTerm)},"refresh-row":_vm.refreshRow}}),_vm._v(" "),(
      !_vm.readOnly &&
      _vm.$hasPermission(
        'database.table.create_row',
        _vm.table,
        _vm.database.workspace.id
      )
    )?_c('RowCreateModal',{ref:"rowCreateModal",attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view,"primary-is-sortable":true,"visible-fields":_vm.visibleFields,"hidden-fields":_vm.hiddenFields,"show-hidden-fields":_vm.showHiddenFieldsInRowModal,"all-fields-in-table":_vm.fields},on:{"toggle-hidden-fields-visibility":function($event){_vm.showHiddenFieldsInRowModal = !_vm.showHiddenFieldsInRowModal},"created":_vm.createRow,"order-fields":_vm.orderFields,"toggle-field-visibility":_vm.toggleFieldVisibility,"field-updated":function($event){return _vm.$emit('refresh', $event)},"field-deleted":function($event){return _vm.$emit('refresh')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }