import { OpenAIModelType } from '@baserow/modules/core/generativeAIModelTypes'

export class SaaSOpenAIGenerativeAIModelType extends OpenAIModelType {
  static getType() {
    return 'Baserow'
  }

  getName() {
    const { i18n } = this.app
    return i18n.t('generativeAIModelType.baserow')
  }

  getSettings() {
    // Settings cannot be changed at workspace level for this AI model type.
    return null
  }

  getOrder() {
    return 5
  }
}
