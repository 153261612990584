var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();}}},[(_vm.isStyleAllowed('style_background'))?_c('FormSection',[(_vm.isStyleAllowed('style_background'))?_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('defaultStyleForm.backgroundLabel'),"small-label":"","required":""}},[_c('RadioGroup',{attrs:{"type":"button","options":_vm.backgroundTypes},model:{value:(_vm.values.style_background),callback:function ($$v) {_vm.$set(_vm.values, "style_background", $$v)},expression:"values.style_background"}})],1):_vm._e(),_vm._v(" "),(
        _vm.values.style_background === _vm.BACKGROUND_TYPES.COLOR &&
        _vm.isStyleAllowed('style_background_color')
      )?_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"small-label":"","required":""}},[_c('ColorInput',{attrs:{"small":"","color-variables":_vm.colorVariables},model:{value:(_vm.values.style_background_color),callback:function ($$v) {_vm.$set(_vm.values, "style_background_color", $$v)},expression:"values.style_background_color"}})],1):_vm._e(),_vm._v(" "),(_vm.isStyleAllowed('style_background_file'))?_c('FormGroup',{staticClass:"margin-top-2",attrs:{"label":_vm.$t('defaultStyleForm.backgroundImage'),"small-label":"","required":""}},[_c('ImageInput',{model:{value:(_vm.values.style_background_file),callback:function ($$v) {_vm.$set(_vm.values, "style_background_file", $$v)},expression:"values.style_background_file"}})],1):_vm._e(),_vm._v(" "),(
        _vm.isStyleAllowed('style_background_mode') &&
        _vm.values.style_background_file
      )?_c('FormGroup',{attrs:{"label":_vm.$t('defaultStyleForm.backgroundImageMode'),"small-label":"","required":""}},[_c('RadioGroup',{attrs:{"type":"button","options":_vm.backgroundModes},model:{value:(_vm.values.style_background_mode),callback:function ($$v) {_vm.$set(_vm.values, "style_background_mode", $$v)},expression:"values.style_background_mode"}})],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.isStyleAllowed('style_width'))?_c('FormSection',[_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('defaultStyleForm.widthLabel'),"small-label":"","required":""}},[_c('Dropdown',{model:{value:(_vm.values.style_width),callback:function ($$v) {_vm.$set(_vm.values, "style_width", $$v)},expression:"values.style_width"}},_vm._l((Object.values(_vm.WIDTH_TYPES)),function(type){return _c('DropdownItem',{key:type.value,attrs:{"name":_vm.$t(type.name),"value":type.value}})}),1)],1)],1):_vm._e(),_vm._v(" "),_vm._l((_vm.borders),function({ name, label }){return _c('StyleBoxForm',{key:name,attrs:{"title":label,"padding-is-allowed":_vm.isStyleAllowed(`style_padding_${name}`),"border-is-allowed":_vm.isStyleAllowed(`style_border_${name}`),"margin-is-allowed":_vm.isStyleAllowed(`style_margin_${name}`)},model:{value:(_vm.boxStyles[name]),callback:function ($$v) {_vm.$set(_vm.boxStyles, name, $$v)},expression:"boxStyles[name]"}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }