<template>
  <div>
    <header class="saas-header saas-header--docs">
      <div class="saas-header-docs__inner">
        <div class="saas-header__logo">
          <nuxt-link :to="{ name: 'index' }">
            <img
              class="saas-header__logo-image"
              src="@baserow/modules/core/static/img/logo.svg"
              alt="Baserow logo"
            />
          </nuxt-link>
          <span class="saas-header__logo-docs">Docs</span>
        </div>
        <nav class="saas-header__nav saas-header__nav--last">
          <ul class="saas-header__nav-list">
            <li class="saas-header__nav-item margin-right-2">
              <nuxt-link :to="{ name: 'index' }" class="saas-header__nav-link">
                Baserow homepage
              </nuxt-link>
            </li>
            <li
              v-if="!isAuthenticated"
              class="saas-header__nav-item margin-right-2"
            >
              <Button tag="nuxt-link" :to="{ name: 'login' }" type="secondary"
                >Login</Button
              >
            </li>
            <li v-if="!isAuthenticated" class="saas-header__nav-item">
              <Button tag="nuxt-link" :to="{ name: 'signup' }">Sign up</Button>
            </li>
            <li v-if="isAuthenticated" class="saas-header__nav-item">
              <Button
                tag="nuxt-link"
                :to="{ name: 'dashboard' }"
                type="secondary"
                >My dashboard</Button
              >
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderDocs',
  props: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },
  methods: {},
}
</script>
