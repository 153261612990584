var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{staticClass:"select elements-context",attrs:{"max-height-if-outside-viewport":""},on:{"shown":function($event){return _vm.shown()}}},[_c('div',{staticClass:"select__search"},[_c('i',{staticClass:"select__search-icon iconoir-search"}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"search",staticClass:"elements-context__search-input",attrs:{"type":"text","placeholder":_vm.$t('elementsContext.searchPlaceholder')},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})]),_vm._v(" "),_c('div',{staticClass:"elements-context__elements"},[(_vm.headerElementsVisible)?_c('ElementsList',{attrs:{"elements":_vm.headerElements,"filtered-search-elements":_vm.filteredHeaderElements},on:{"select":function($event){return _vm.selectElement($event)}}}):_vm._e(),_vm._v(" "),(_vm.contentElementsVisible)?_c('ElementsList',{attrs:{"elements":_vm.rootElements,"filtered-search-elements":_vm.filteredContentElements},on:{"select":function($event){return _vm.selectElement($event)}}}):_vm._e(),_vm._v(" "),(!_vm.contentElementsVisible && !_vm.isSearching)?_c('div',{staticClass:"elements-list elements-list--empty"},[_vm._v("\n      "+_vm._s(_vm.$t('elementsContext.noPageElements'))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.footerElementsVisible)?_c('ElementsList',{attrs:{"elements":_vm.footerElements,"filtered-search-elements":_vm.filteredFooterElements},on:{"select":function($event){return _vm.selectElement($event)}}}):_vm._e(),_vm._v(" "),(!_vm.elementsVisible && _vm.isSearching)?_c('div',{staticClass:"elements-list elements-list--empty"},[_vm._v("\n      "+_vm._s(_vm.$t('elementsContext.noElements'))+"\n    ")]):_vm._e()],1),_vm._v(" "),(
      _vm.$hasPermission('builder.page.create_element', _vm.currentPage, _vm.workspace.id)
    )?_c('div',{staticClass:"elements-context__footer"},[_c('div',{staticClass:"elements-context__footer-create"},[_c('AddElementButton',{class:{
          'margin-top-1': !_vm.elementsVisible,
        },on:{"click":function($event){return _vm.$refs.addElementModal.show()}}})],1)]):_vm._e(),_vm._v(" "),(
      _vm.$hasPermission('builder.page.create_element', _vm.currentPage, _vm.workspace.id)
    )?_c('AddElementModal',{ref:"addElementModal",attrs:{"page":_vm.currentPage},on:{"element-added":_vm.onElementAdded}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }