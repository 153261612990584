var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}}},[_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('pageVisibilitySettings.description'),"small-label":"","required":""}},[_c('div',[(_vm.showLoginPageAlert && _vm.showLogInPageWarning)?_c('Alert',{attrs:{"type":"warning"}},[_vm._t("title",function(){return [_vm._v(_vm._s(_vm.$t('pageVisibilitySettingsTypes.logInPageWarningTitle')))]}),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('pageVisibilitySettingsTypes.logInPagewarningMessage'))}})],2):(_vm.showLoginPageAlert && !_vm.showLogInPageWarning)?_c('Alert',{attrs:{"type":"info-primary"}},[_vm._t("title",function(){return [_vm._v(_vm._s(_vm.$t('pageVisibilitySettingsTypes.logInPageInfoTitle')))]}),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.$t('pageVisibilitySettingsTypes.logInPageInfoMessage', {
              logInPageName: _vm.loginPageName,
            })
          )}})],2):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"margin-top-1 visibility-form__visibility-all"},[_c('div',[_c('Radio',{attrs:{"value":_vm.visibilityAll},model:{value:(_vm.values.visibility),callback:function ($$v) {_vm.$set(_vm.values, "visibility", $$v)},expression:"values.visibility"}},[_vm._v("\n          "+_vm._s(_vm.$t('pageVisibilitySettings.allVisitors'))+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"margin-left-2 visibility-form__visibility-logged-in"},[_c('Expandable',{attrs:{"force-expanded":_vm.selectedVisibility === _vm.visibilityLoggedIn},scopedSlots:_vm._u([{key:"header",fn:function({ toggle }){return [_c('Radio',{attrs:{"value":_vm.visibilityLoggedIn},on:{"click":toggle},model:{value:(_vm.selectedVisibility),callback:function ($$v) {_vm.selectedVisibility=$$v},expression:"selectedVisibility"}},[_vm._v("\n              "+_vm._s(_vm.$t('pageVisibilitySettings.loggedInVisitors'))+"\n            ")])]}},{key:"default",fn:function(){return [_c('FormElement',{staticClass:"control visibility-form__expanded-form-element"},[_c('Dropdown',{attrs:{"placeholder":_vm.$t('visibilityForm.roleTypesHint')},model:{value:(_vm.selectedRoleType),callback:function ($$v) {_vm.selectedRoleType=$$v},expression:"selectedRoleType"}},_vm._l((_vm.roleTypeOptions),function(value,key){return _c('DropdownItem',{key:key,attrs:{"name":value,"value":key}})}),1),_vm._v(" "),(
                  _vm.allowAllRolesExceptSelected ||
                  _vm.disallowAllRolesExceptSelected
                )?_c('div',{staticClass:"visibility-form__role-list"},[(_vm.loadingRoles)?[_c('div',{staticClass:"loading margin-bottom-1"})]:[_vm._l((_vm.allRoles),function(roleName){return _c('div',{key:roleName,staticClass:"visibility-form__role-checkbox"},[_c('Checkbox',{attrs:{"checked":_vm.isChecked(roleName)},on:{"input":function($event){return _vm.checkRole(roleName)}}},[_vm._v("\n                      "+_vm._s(_vm.getRoleName(roleName))+"\n                    ")])],1)}),_vm._v(" "),_c('div',{staticClass:"visibility-form__actions"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.selectAllRoles.apply(null, arguments)}}},[_vm._v("\n                      "+_vm._s(_vm.$t('visibilityForm.rolesSelectAll'))+"\n                    ")]),_vm._v(" "),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.deselectAllRoles.apply(null, arguments)}}},[_vm._v("\n                      "+_vm._s(_vm.$t('visibilityForm.rolesDeselectAll'))+"\n                    ")])])]],2):_vm._e()],1)]},proxy:true}])})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }