var render = function render(_c,_vm){return _c('div',{staticClass:"grid-view__cell grid-field-many-to-many__cell"},[_c('div',{staticClass:"grid-field-many-to-many__list"},_vm._l((_vm.props.value),function(item){return _c('div',{key:item.id,staticClass:"grid-field-many-to-many__item"},[_c('span',{staticClass:"grid-field-many-to-many__name",class:{
          'grid-field-link-row__unnamed':
            item.value === null || item.value === '',
        },attrs:{"title":item.value}},[_vm._v("\n        "+_vm._s(item.value ||
          _vm.parent.$t('functionnalGridViewFieldLinkRow.unnamed', {
            value: item.id,
          }))+"\n      ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }