var render = function render(_c,_vm){return _c('div',{staticClass:"rating",class:[
    _vm.data.staticClass,
    `color--${_vm.props.color}`,
    _vm.props.readOnly ? '' : 'editing',
  ]},_vm._l((_vm.props.readOnly ? _vm.props.value : _vm.props.maxValue),function(index){return _c('i',{key:index,staticClass:"rating__star",class:{
      [`baserow-icon-${_vm.props.ratingStyle}`]: true,
      'rating__star--selected': index <= _vm.props.value,
    },on:{"click":function($event){!_vm.props.readOnly &&
        _vm.listeners['update'] &&
        _vm.listeners['update'](index === _vm.props.value ? 0 : index)}}})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }