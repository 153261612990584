var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{staticClass:"create-application-context",attrs:{"overflow-scroll":"","max-height-if-outside-viewport":""},on:{"shown":_vm.fetchRolesAndPermissions}},[(_vm.workspace._.additionalLoading)?_c('div',{staticClass:"loading margin-left-2 margin-top-2 margin-right-2 margin-bottom-2"}):_c('ul',{staticClass:"context__menu"},[_vm._l((_vm.applicationTypes),function(applicationType){return _c('li',{key:applicationType.getType(),staticClass:"context__menu-item"},[_c('a',{ref:'createApplicationModalToggle' + applicationType.getType(),refInFor:true,staticClass:"context__menu-item-link context__menu-item-link--with-desc",class:{
          disabled: !_vm.canCreateCreateApplication,
        },on:{"click":function($event){_vm.toggleCreateApplicationModal(applicationType.getType())}}},[_c('span',{staticClass:"context__menu-item-title"},[_c('i',{staticClass:"context__menu-item-icon",class:applicationType.iconClass}),_vm._v("\n          "+_vm._s(applicationType.getName())),(applicationType.isBeta())?_c('Badge',{attrs:{"color":"cyan","size":"small"}},[_vm._v(_vm._s(_vm.$t('common.beta')))]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"context__menu-item-description context__menu-item-description--offset"},[_vm._v("\n          "+_vm._s(applicationType.getDescription())+"\n        ")])]),_vm._v(" "),_c('CreateApplicationModal',{ref:'createApplicationModal' + applicationType.getType(),refInFor:true,attrs:{"application-type":applicationType,"workspace":_vm.workspace},on:{"created":_vm.hide}})],1)}),_vm._v(" "),_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link context__menu-item-link--with-desc",class:{
          disabled: !_vm.canCreateCreateApplication,
        },on:{"click":function($event){return _vm.openTemplateModal()}}},[_c('span',{staticClass:"context__menu-item-title"},[_c('i',{staticClass:"context__menu-item-icon iconoir-page"}),_vm._v("\n          "+_vm._s(_vm.$t('createApplicationContext.fromTemplate')))]),_vm._v(" "),_c('div',{staticClass:"context__menu-item-description context__menu-item-description--offset"},[_vm._v("\n          "+_vm._s(_vm.$t('createApplicationContext.fromTemplateDesc'))+"\n        ")])]),_vm._v(" "),_c('TemplateModal',{ref:"templateModal",attrs:{"workspace":_vm.workspace}})],1),_vm._v(" "),_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link context__menu-item-link--with-desc",class:{
          disabled: !_vm.canCreateCreateApplication,
        },on:{"click":function($event){return _vm.openImportWorkspaceModal()}}},[_c('span',{staticClass:"context__menu-item-title"},[_c('i',{staticClass:"context__menu-item-icon iconoir-import"}),_vm._v("\n          "+_vm._s(_vm.$t('createApplicationContext.importWorkspace')))]),_vm._v(" "),_c('div',{staticClass:"context__menu-item-description context__menu-item-description--offset"},[_vm._v("\n          "+_vm._s(_vm.$t('createApplicationContext.importWorkspaceDesc'))+"\n        ")])]),_vm._v(" "),_c('ImportWorkspaceModal',{ref:"importWorkspaceModal",attrs:{"workspace":_vm.workspace}})],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }