<template>
  <div>
    <form
      class="newsletter-signup"
      :class="{ 'newsletter-signup--dark': dark }"
      @submit.prevent="!success && newsletterSubmitted($event)"
    >
      <div class="newsletter-signup__input-wrapper">
        <FormInput
          ref="email"
          v-model="newsletter.email"
          name="EMAIL"
          class="newsletter-signup__input"
          :error="$v.newsletter.email.$error"
          type="email"
          size="large"
          placeholder="Your email address"
          :disabled="success"
          @blur="$v.newsletter.email.$touch()"
        />
      </div>

      <Button
        :loading="loading"
        :disabled="success"
        :type="dark ? 'secondary' : 'primary'"
        size="large"
        >Subscribe</Button
      >
    </form>
    <div v-if="$v.newsletter.email.$error" class="newsletter-signup__error">
      The provided email address is invalid.
    </div>
    <div v-if="success" class="newsletter-signup__success">
      You've been signed up for the newsletter successfully.
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import NewsletterService from '@saas/services/newsletter'

export default {
  name: 'NewsletterSignup',
  props: {
    dark: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      loading: false,
      success: false,
      newsletter: {
        email: '',
      },
    }
  },
  validations: {
    newsletter: {
      email: { required, email },
    },
  },
  methods: {
    async newsletterSubmitted(event) {
      this.$v.$touch()
      if (this.$v.$invalid) {
        event.preventDefault()
        this.$refs.email.focus()
        return
      }

      this.loading = true

      try {
        await NewsletterService(this.$client).subscribe(this.newsletter.email)
        this.success = true
        this.$posthog?.capture('newsletter_signup', { via_frontend: true })
      } catch (error) {}

      this.loading = false
    },
  },
}
</script>
