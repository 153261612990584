var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{ref:"context",attrs:{"overflow-scroll":true,"max-height-if-outside-viewport":true}},[_c('div',{staticClass:"context__menu-title"},[_vm._v("\n    "+_vm._s(_vm.application.name)+" ("+_vm._s(_vm.application.id)+")\n  ")]),_vm._v(" "),_c('ul',{staticClass:"context__menu"},[_vm._l((_vm.additionalContextComponents),function(component,index){return _c('li',{key:index,staticClass:"context__menu-item",on:{"click":function($event){return _vm.$refs.context.hide()}}},[_c(component,{tag:"component",attrs:{"application":_vm.application}})],1)}),_vm._v(" "),_vm._t("additional-context-items"),_vm._v(" "),(
        _vm.$hasPermission(
          'application.update',
          _vm.application,
          _vm.application.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":function($event){return _vm.handleRename()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-edit-pencil"}),_vm._v("\n        "+_vm._s(_vm.$t('sidebarApplication.rename', {
            type: _vm.application._.type.name.toLowerCase(),
          }))+"\n      ")])]):_vm._e(),_vm._v(" "),(
        _vm.$hasPermission(
          'application.duplicate',
          _vm.application,
          _vm.application.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item"},[_c('SidebarDuplicateApplicationContextItem',{attrs:{"application":_vm.application,"disabled":_vm.deleting},on:{"click":function($event){return _vm.$refs.context.hide()}}})],1):_vm._e(),_vm._v(" "),(
        _vm.$hasPermission(
          'application.create_snapshot',
          _vm.application,
          _vm.application.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":_vm.openSnapshots}},[_c('i',{staticClass:"context__menu-item-icon baserow-icon-history"}),_vm._v("\n        "+_vm._s(_vm.$t('sidebarApplication.snapshots'))+"\n      ")])]):_vm._e(),_vm._v(" "),_c('SnapshotsModal',{ref:"snapshotsModal",attrs:{"application":_vm.application}}),_vm._v(" "),(
        _vm.applicationType.supportsTrash() &&
        _vm.$hasPermission(
          'application.read_trash',
          _vm.application,
          _vm.application.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":_vm.showApplicationTrashModal}},[_c('i',{staticClass:"context__menu-item-icon iconoir-refresh-double"}),_vm._v("\n        "+_vm._s(_vm.$t('sidebarApplication.viewTrash'))+"\n      ")])]):_vm._e(),_vm._v(" "),(
        _vm.$hasPermission(
          'application.delete',
          _vm.application,
          _vm.application.workspace.id
        )
      )?_c('li',{staticClass:"context__menu-item context__menu-item--with-separator"},[_c('a',{staticClass:"context__menu-item-link context__menu-item-link--delete",class:{ 'context__menu-item-link--loading': _vm.deleting },on:{"click":function($event){return _vm.deleteApplication()}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-bin"}),_vm._v("\n        "+_vm._s(_vm.$t('sidebarApplication.delete', {
            type: _vm.application._.type.name.toLowerCase(),
          }))+"\n      ")])]):_vm._e()],2),_vm._v(" "),_c('TrashModal',{ref:"applicationTrashModal",attrs:{"initial-workspace":_vm.workspace,"initial-application":_vm.application}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }