<template>
  <div class="empty-side-panel-state">
    <i class="iconoir-pc-mouse empty-side-panel-state__icon"></i>
    <p>{{ $t('emptySidePanelState.message') }}</p>
  </div>
</template>

<script>
export default {
  name: 'EmptySidePanelState',
}
</script>
