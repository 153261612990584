var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasAtLeastOneLoginOption)?_c('form',{staticClass:"auth-form-element",style:(_vm.getStyleOverride('input')),on:{"submit":function($event){$event.preventDefault();return _vm.onLogin.apply(null, arguments)}}},[_c('Error',{attrs:{"error":_vm.error}}),_vm._v(" "),_c('ABFormGroup',{attrs:{"label":_vm.$t('authFormElement.email'),"error-message":_vm.$v.values.email.$dirty
        ? !_vm.$v.values.email.required
          ? _vm.$t('error.requiredField')
          : !_vm.$v.values.email.email
          ? _vm.$t('error.invalidEmail')
          : ''
        : '',"autocomplete":_vm.isEditMode ? 'off' : '',"required":""}},[_c('ABInput',{attrs:{"placeholder":_vm.$t('authFormElement.emailPlaceholder')},on:{"blur":function($event){return _vm.$v.values.email.$touch()}},model:{value:(_vm.values.email),callback:function ($$v) {_vm.$set(_vm.values, "email", $$v)},expression:"values.email"}})],1),_vm._v(" "),_c('ABFormGroup',{attrs:{"label":_vm.$t('authFormElement.password'),"error-message":_vm.$v.values.password.$dirty
        ? !_vm.$v.values.password.required
          ? _vm.$t('error.requiredField')
          : ''
        : '',"required":""}},[_c('ABInput',{ref:"passwordRef",attrs:{"type":"password","placeholder":_vm.$t('authFormElement.passwordPlaceholder')},on:{"blur":function($event){return _vm.$v.values.password.$touch()}},model:{value:(_vm.values.password),callback:function ($$v) {_vm.$set(_vm.values, "password", $$v)},expression:"values.password"}})],1),_vm._v(" "),_c('div',{staticClass:"auth-form__footer",style:(_vm.getStyleOverride('login_button'))},[_c('ABButton',{attrs:{"disabled":_vm.$v.$error,"loading":_vm.loading,"size":"large"}},[_vm._v("\n      "+_vm._s(_vm.resolvedLoginButtonLabel)+"\n    ")])],1)],1):_c('p',[_vm._v(_vm._s(_vm.$t('authFormElement.selectOrConfigureUserSourceFirst')))])
}
var staticRenderFns = []

export { render, staticRenderFns }