var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{ref:"context",attrs:{"overflow-scroll":"","max-height-if-outside-viewport":""}},[_c('ul',{staticClass:"context__menu timeline-timescale-context"},_vm._l((_vm.options),function(option,index){return _c('li',{key:option.value,staticClass:"context__menu-item",class:{
        'context__menu-item--with-separator': index > 0,
      },on:{"click":function($event){option.enabled ? _vm.$emit('select', option.value) : null}}},[_c('div',[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            option.enabled
              ? null
              : _vm.$t('timelineTimescaleContext.disabledTooltip')
          ),expression:"\n            option.enabled\n              ? null\n              : $t('timelineTimescaleContext.disabledTooltip')\n          "}],staticClass:"context__menu-item-link timeline-timescale-context__item-link",class:{
            disabled: !option.enabled,
          },attrs:{"tooltip-position":"bottom-left"}},[_vm._v("\n          "+_vm._s(_vm.$t(`timelineTimescaleContext.${option.value}`))+"\n          "),(option.value === _vm.timescale)?_c('i',{staticClass:"context__menu-icon iconoir-check"}):_vm._e()])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }