<template>
  <Modal wide>
    <h2 class="box__title">
      {{ $t('startTrialModal.title', { name: workspace.name }) }}
    </h2>
    <div v-if="!trialPlan" class="loading"></div>
    <div v-else>
      <Error v-if="error.visible" :error="error"></Error>
      <div class="start-trial-modal__header">
        <div>
          <strong>{{
            $tc('startTrialModal.nCollaborators', workspace.num_users)
          }}</strong>
        </div>
        <div></div>
        <div>
          <strong>{{ $t('startTrialModal.cost') }}</strong>
        </div>
        <div>
          <span class="start-trial-modal__header-value">{{
            $t('startTrialModal.free')
          }}</span>
        </div>
        <div>
          <strong>{{ $t('startTrialModal.workspace') }}</strong>
        </div>
        <div>
          <span class="start-trial-modal__header-value">{{
            workspace.name
          }}</span>
        </div>
        <div>
          <strong>{{ $t('startTrialModal.period') }}</strong>
        </div>
        <div>
          <span class="start-trial-modal__header-value">{{
            $tc('startTrialModal.nDays', 30)
          }}</span>
        </div>
      </div>
      <StartTrialForm :plan="trialPlan" @submitted="submit">
        <Button size="large" :loading="loadingStartTrial">
          {{ $t('startTrialModal.startTrial') }}
        </Button>
      </StartTrialForm>
    </div>
  </Modal>
</template>

<script>
import modal from '@baserow/modules/core/mixins/modal'
import error from '@baserow/modules/core/mixins/error'
import { notifyIf } from '@baserow/modules/core/utils/error'
import StartTrialForm from '@saas/components/subscriptions/StartTrialForm'
import SubscriptionService from '@saas/services/subscriptions'
import UserService from '@saas/services/user'

export default {
  name: 'StartTrialModal',
  components: { StartTrialForm },
  mixins: [modal, error],
  props: {
    workspace: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingPlan: true,
      loadingStartTrial: false,
      plans: [],
    }
  },
  computed: {
    trialPlan() {
      const matchingPlans = this.plans.filter(
        (plan) =>
          plan.tier === 'advanced' &&
          plan.type === 'saas' &&
          plan.billing_type === 'month' &&
          plan.recurring_price.USD
      )
      if (matchingPlans.length > 0) {
        return matchingPlans[0]
      }
      return null
    },
  },
  methods: {
    async show(...args) {
      modal.methods.show.call(this, ...args)
      this.loadingPlan = true
      try {
        const { data } = await SubscriptionService(this.$client).getPlans()
        this.plans = data
      } catch (error) {
        this.plans = []
        notifyIf(error)
        modal.methods.hide.call(this)
      }
      this.loadingPlan = false
    },
    async submit(values) {
      if (this.loadingStartTrial) {
        return
      }
      try {
        this.loadingStartTrial = true
        await UserService(this.$client).addAdditionalInformation(values)
        await SubscriptionService(this.$client).startTrial(this.workspace.id)
        this.$store.dispatch('toast/success', {
          title: this.$t('startTrialModal.trialStartedTitle'),
          message: this.$t('startTrialModal.trialStartedMessage'),
        })
        this.$emit('success')
        modal.methods.hide.call(this)
      } catch (error) {
        this.handleError(error, 'workspace')
      } finally {
        this.loadingStartTrial = false
      }
    },
  },
}
</script>
