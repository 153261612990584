var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"row-modal__field-list margin-bottom-0"},_vm._l((_vm.fields),function(field){return _c('li',{directives:[{name:"sortable",rawName:"v-sortable",value:({
      enabled: _vm.fieldIsSortable(field),
      id: field.id,
      update: (newOrder) => {
        _vm.$emit('order-fields', { newOrder })
      },
      handle: '[data-field-handle]',
      marginTop: -10,
    }),expression:"{\n      enabled: fieldIsSortable(field),\n      id: field.id,\n      update: (newOrder) => {\n        $emit('order-fields', { newOrder })\n      },\n      handle: '[data-field-handle]',\n      marginTop: -10,\n    }"}],key:'row-edit-field-' + field.id,staticClass:"row-modal__field-item"},[_c('RowEditModalField',{ref:'field-' + field.id,refInFor:true,attrs:{"field":field,"can-be-hidden":field.primary ? _vm.primaryIsSortable : true,"hidden":_vm.hidden,"read-only":_vm.readOnly,"row":_vm.row,"view":_vm.view,"table":_vm.table,"database":_vm.database,"sortable":_vm.sortable && _vm.fieldIsSortable(field),"can-modify-fields":_vm.canModifyFields,"all-fields-in-table":_vm.allFieldsInTable},on:{"field-updated":function($event){return _vm.$emit('field-updated', $event)},"field-deleted":function($event){return _vm.$emit('field-deleted')},"update":function($event){return _vm.$emit('update', $event)},"refresh-row":function($event){return _vm.$emit('refresh-row', $event)},"toggle-field-visibility":function($event){return _vm.$emit('toggle-field-visibility', $event)}}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }