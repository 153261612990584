var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.tableLoading)?_c('ul',{staticClass:"header__filter header__filter--full-width"},[(
      _vm.$hasPermission(
        'database.table.view.update',
        _vm.view,
        _vm.database.workspace.id
      )
    )?_c('li',{staticClass:"header__filter-item"},[(!_vm.readOnly)?_c('a',{ref:"modeContextLink",staticClass:"header__filter-link",on:{"click":function($event){return _vm.$refs.modeContext.toggle(_vm.$refs.modeContextLink, 'bottom', 'left', 4)}}},[_c('i',{staticClass:"header__filter-icon iconoir-palette"}),_vm._v(" "),_c('span',{staticClass:"header__filter-name"},[_vm._v(_vm._s(_vm.$t('formViewHeader.mode')))])]):_vm._e(),_vm._v(" "),_c('FormViewModeContext',{ref:"modeContext",attrs:{"database":_vm.database,"view":_vm.view}})],1):_vm._e(),_vm._v(" "),_c('li',{staticClass:"header__filter-item"},[(!_vm.readOnly)?_c('a',{staticClass:"header__filter-link",attrs:{"href":_vm.formUrl,"target":"_blank","rel":"noopener"}},[_c('i',{staticClass:"header__filter-icon iconoir-eye-empty"}),_vm._v(" "),_c('span',{staticClass:"header__filter-name"},[_vm._v(_vm._s(_vm.$t('formViewHeader.preview')))])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }