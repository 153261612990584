<template>
  <div ph-autocapture>
    <HeaderDocs></HeaderDocs>
    <nuxt />
    <FooterDocs></FooterDocs>
    <CookieNotification></CookieNotification>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import HeaderDocs from '@saas/components/docs/Header'
import FooterDocs from '@saas/components/docs/Footer'
import CookieNotification from '@saas/components/CookieNotification'

export default {
  components: {
    HeaderDocs,
    FooterDocs,
    CookieNotification,
  },
  middleware: ['saasLayout', 'removeTrailingSlash'],
  head() {
    return {
      titleTemplate: '',
      htmlAttrs: {
        lang: 'en',
      },
      bodyAttrs: {
        class: ['saas-page'],
      },
    }
  },
  computed: {
    ...mapGetters({
      navOpen: 'header/getNavOpen',
    }),
  },
}
</script>
