var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Context',{ref:"viewsContext",staticClass:"select",attrs:{"max-height-if-outside-viewport":""},on:{"shown":_vm.shown}},[_c('div',{staticClass:"select__search"},[_c('i',{staticClass:"select__search-icon iconoir-search"}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],staticClass:"select__search-input",attrs:{"type":"text","placeholder":_vm.$t('viewsContext.searchView')},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing)return;_vm.query=$event.target.value}}})]),_vm._v(" "),(_vm.isLoading)?_c('div',{staticClass:"context--loading"},[_c('div',{staticClass:"loading"})]):_vm._e(),_vm._v(" "),_c('ul',{directives:[{name:"auto-overflow-scroll",rawName:"v-auto-overflow-scroll"}],ref:"dropdown",staticClass:"select__items select__items--no-max-height"},_vm._l((_vm.activeViewOwnershipTypes),function(type){return _c('li',{key:type.getType() + 'group'},[(_vm.viewsByOwnership(_vm.views, type.getType()).length > 0)?_c('div',{key:type.getType(),staticClass:"select__item-label"},[_vm._v("\n        "+_vm._s(type.getName())+"\n      ")]):_vm._e(),_vm._v(" "),_c('ul',_vm._l((_vm.viewsByOwnership(_vm.views, type.getType())),function(view){return _c('ViewsContextItem',{directives:[{name:"sortable",rawName:"v-sortable",value:({
            enabled:
              !_vm.readOnly &&
              _vm.$hasPermission(
                'database.table.order_views',
                _vm.table,
                _vm.database.workspace.id
              ),
            id: view.id,
            update: _vm.createOrderCall(view.ownership_type),
            marginTop: -1.5,
          }),expression:"{\n            enabled:\n              !readOnly &&\n              $hasPermission(\n                'database.table.order_views',\n                table,\n                database.workspace.id\n              ),\n            id: view.id,\n            update: createOrderCall(view.ownership_type),\n            marginTop: -1.5,\n          }"}],key:view.id,ref:'view-' + view.id,refInFor:true,attrs:{"database":_vm.database,"view":view,"table":_vm.table,"read-only":_vm.readOnly},on:{"selected":_vm.selectedView}})}),1)])}),0),_vm._v(" "),(!_vm.isLoading && _vm.views.length == 0)?_c('div',{staticClass:"context__description"},[_vm._v("\n    "+_vm._s(_vm.$t('viewsContext.noViews'))+"\n  ")]):_vm._e(),_vm._v(" "),(!_vm.readOnly && _vm.availableViewOwnershipTypesForCreation.length > 0)?_c('div',{staticClass:"select__footer"},[_c('div',{staticClass:"select__footer-create"},_vm._l((_vm.viewTypes),function(viewType,type){return _c('CreateViewLink',{key:type,attrs:{"database":_vm.database,"table":_vm.table,"view-type":viewType},on:{"created":_vm.selectedView}})}),1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }