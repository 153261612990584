var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SidebarApplication',{attrs:{"workspace":_vm.workspace,"application":_vm.application},on:{"selected":_vm.selected},scopedSlots:_vm._u([{key:"context",fn:function(){return [_c('li',{staticClass:"context__menu-item"},[_c('nuxt-link',{staticClass:"context__menu-item-link",attrs:{"to":{
          name: 'database-api-docs-detail',
          params: {
            databaseId: _vm.application.id,
          },
        }}},[_c('i',{staticClass:"context__menu-item-icon iconoir-book"}),_vm._v("\n        "+_vm._s(_vm.$t('sidebar.viewAPI'))+"\n      ")])],1)]},proxy:true},(_vm.isAppSelected(_vm.application))?{key:"body",fn:function(){return [_c('ul',{staticClass:"tree__subs"},_vm._l((_vm.orderedTables),function(table){return _c('SidebarItem',{directives:[{name:"sortable",rawName:"v-sortable",value:({
          id: table.id,
          update: _vm.orderTables,
          marginTop: -1.5,
          enabled: _vm.$hasPermission(
            'database.order_tables',
            _vm.application,
            _vm.application.workspace.id
          ),
        }),expression:"{\n          id: table.id,\n          update: orderTables,\n          marginTop: -1.5,\n          enabled: $hasPermission(\n            'database.order_tables',\n            application,\n            application.workspace.id\n          ),\n        }"}],key:table.id,attrs:{"database":_vm.application,"table":table}})}),1),_vm._v(" "),(_vm.pendingJobs.length)?_c('ul',{staticClass:"tree__subs"},_vm._l((_vm.pendingJobs),function(job){return _c(_vm.getPendingJobComponent(job),{key:job.id,tag:"component",attrs:{"job":job}})}),1):_vm._e(),_vm._v(" "),(
        _vm.$hasPermission(
          'database.create_table',
          _vm.application,
          _vm.application.workspace.id
        )
      )?_c('a',{staticClass:"tree__sub-add",on:{"click":function($event){return _vm.$refs.createTableModal.show()}}},[_c('i',{staticClass:"tree__sub-add-icon iconoir-plus"}),_vm._v("\n      "+_vm._s(_vm.$t('sidebar.createTable'))+"\n    ")]):_vm._e(),_vm._v(" "),_c('CreateTableModal',{ref:"createTableModal",attrs:{"database":_vm.application}})]},proxy:true}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }