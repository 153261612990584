var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"auto-scroll",rawName:"v-auto-scroll",value:({
    enabled: () => _vm.draggingRow !== null,
    speed: 3,
    padding: 24,
    scrollElement: () => _vm.$refs.scroll.$el,
  }),expression:"{\n    enabled: () => draggingRow !== null,\n    speed: 3,\n    padding: 24,\n    scrollElement: () => $refs.scroll.$el,\n  }"}],ref:"wrapper",staticClass:"kanban-view__stack-wrapper",on:{"mouseleave":function($event){$event.stopPropagation();return _vm.wrapperMouseLeave.apply(null, arguments)}}},[_c('div',{staticClass:"kanban-view__stack",class:{ 'kanban-view__stack--dragging': _vm.draggingRow !== null },on:{"mousemove":function($event){return _vm.stackMoveOver($event, _vm.stack, _vm.id)}}},[_c('div',{staticClass:"kanban-view__stack-head"},[(_vm.option === null)?_c('div',{staticClass:"kanban-view__uncategorized"},[_vm._v("\n        "+_vm._s(_vm.$t('kanbanViewStack.uncategorized'))+"\n      ")]):[_c('div',{staticClass:"kanban-view__option-wrapper"},[_c('div',{staticClass:"kanban-view__option",class:'background-color--' + _vm.option.color},[_vm._v("\n            "+_vm._s(_vm.option.value)+"\n          ")])])],_vm._v(" "),_c('div',{staticClass:"kanban-view__count"},[_vm._v("\n        "+_vm._s(_vm.stack.count)+"\n      ")]),_vm._v(" "),(!_vm.readOnly && _vm.showStackContextMenu)?_c('a',{ref:"editContextLink",staticClass:"kanban-view__options",on:{"click":function($event){return _vm.$refs.editContext.toggle(
            _vm.$refs.editContextLink,
            'bottom',
            'right',
            -2
          )}}},[_c('i',{staticClass:"baserow-icon-more-horizontal"})]):_vm._e(),_vm._v(" "),(!_vm.readOnly && _vm.showStackContextMenu)?_c('KanbanViewStackContext',{ref:"editContext",attrs:{"option":_vm.option,"database":_vm.database,"table":_vm.table,"fields":_vm.fields,"single-select-field":_vm.singleSelectField,"store-prefix":_vm.storePrefix},on:{"create-row":function($event){return _vm.$emit('create-row', { option: _vm.option })},"refresh":function($event){return _vm.$emit('refresh', $event)}}}):_vm._e()],2),_vm._v(" "),_c('InfiniteScroll',{ref:"scroll",staticClass:"kanban-view__stack-cards",attrs:{"max-count":_vm.stack.count,"current-count":_vm.stack.results.length,"loading":_vm.loading,"render-end":false},on:{"load-next-page":function($event){return _vm.fetch('scroll')}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{style:({ 'min-height': _vm.cardHeight * _vm.stack.results.length + 'px' })},_vm._l((_vm.buffer),function(slot){return _c('RowCard',_vm._g({directives:[{name:"show",rawName:"v-show",value:(slot.position != -1),expression:"slot.position != -1"}],key:'card-' + slot.id,staticClass:"kanban-view__stack-card",class:{
              'kanban-view__stack-card--dragging': slot.row._.dragging,
            },style:({
              transform: `translateY(${
                slot.position * _vm.cardHeight + _vm.bufferTop
              }px)`,
            }),attrs:{"fields":_vm.cardFields,"decorations-by-place":_vm.decorationsByPlace,"row":slot.row,"workspace-id":_vm.database.workspace.id,"cover-image-field":_vm.coverImageField},on:{"mousedown":function($event){return _vm.cardDown($event, slot.row)},"mousemove":function($event){return _vm.cardMoveOver($event, slot.row)}}},_vm.$listeners))}),1),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"margin-top-2"},[_c('a',{on:{"click":function($event){return _vm.fetch('click')}}},[_vm._v("\n            "+_vm._s(_vm.$t('kanbanViewStack.tryAgain'))+"\n            "),_c('i',{staticClass:"iconoir-refresh-double"})])]):_vm._e()]},proxy:true}])}),_vm._v(" "),_c('div',{staticClass:"kanban-view__stack-foot"},[(
          !_vm.readOnly &&
          _vm.$hasPermission(
            'database.table.create_row',
            _vm.table,
            _vm.database.workspace.id
          )
        )?_c('Button',{attrs:{"type":"secondary","icon":"iconoir-plus","full-width":"","disabled":_vm.draggingRow !== null},on:{"click":function($event){!_vm.readOnly && _vm.$emit('create-row', { option: _vm.option })}}},[_vm._v("\n        "+_vm._s(_vm.$t('kanbanViewStack.new'))+"\n      ")]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }