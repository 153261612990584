var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription-checkout__plan",class:{
    'subscription-checkout__plan--selected': _vm.selected,
  },on:{"click":function($event){return _vm.$emit('selected', _vm.plan)}}},[_c('div',{staticClass:"subscription-checkout__plan-row"},[_c('div',{staticClass:"subscription-checkout__plan-name"},[_vm._v("\n      "+_vm._s(_vm.plan.name)+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"subscription-checkout__plan-row"},[_vm._v("\n    $"+_vm._s(_vm.plan.recurring_price['USD'])+" "+_vm._s(_vm.$t('common.perUser'))+" /\n    "+_vm._s(_vm.plan.billing_type)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"subscription-checkout__plan-row"},[_c('div',{staticClass:"subscription-checkout__plan-property"},[_vm._v("\n      "+_vm._s(_vm.$t('common.premium'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"subscription-checkout__plan-value"},[_c('i',{staticClass:"fas",class:{
          'iconoir-check color-success': _vm.plan.premium,
          'iconoir-cancel color-error': !_vm.plan.premium,
        }})])]),_vm._v(" "),_c('div',{staticClass:"subscription-checkout__plan-row"},[_c('div',{staticClass:"subscription-checkout__plan-property"},[_vm._v("\n      "+_vm._s(_vm.$t('common.aiFeatures'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"subscription-checkout__plan-value"},[_c('i',{staticClass:"fas",class:{
          'iconoir-check color-success': _vm.plan.premium,
          'iconoir-cancel color-error': !_vm.plan.premium,
        }})])]),_vm._v(" "),(_vm.isSelfHosted)?_c('div',{staticClass:"subscription-checkout__plan-row"},[_c('div',{staticClass:"subscription-checkout__plan-property"},[_vm._v("\n      "+_vm._s(_vm.$t('common.enterprise'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"subscription-checkout__plan-value"},[_c('i',{staticClass:"fas",class:{
          'iconoir-check color-success': _vm.plan.enterprise,
          'iconoir-cancel color-error': !_vm.plan.enterprise,
        }})])]):_c('div',{staticClass:"subscription-checkout__plan-row"},[_c('div',{staticClass:"subscription-checkout__plan-property"},[_vm._v("\n      "+_vm._s(_vm.$t('common.rbac'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"subscription-checkout__plan-value"},[_c('i',{staticClass:"fas",class:{
          'iconoir-check color-success': _vm.plan.rbac,
          'iconoir-cancel color-error': !_vm.plan.rbac,
        }})])]),_vm._v(" "),_c('div',{staticClass:"subscription-checkout__plan-row"},[_c('div',{staticClass:"subscription-checkout__plan-property"},[_vm._v("\n      "+_vm._s(_vm.$t('common.prioritySupport'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"subscription-checkout__plan-value"},[_c('i',{staticClass:"fas",class:{
          'iconoir-check color-success': _vm.plan.priority_support,
          'iconoir-cancel color-error': !_vm.plan.priority_support,
        }})])]),_vm._v(" "),(_vm.plan.rows_quota)?_c('div',{staticClass:"subscription-checkout__plan-row"},[_c('div',{staticClass:"subscription-checkout__plan-property"},[_vm._v("\n      "+_vm._s(_vm.$t('common.rowLimit'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"subscription-checkout__plan-value"},[_vm._v("\n      "+_vm._s(_vm.plan.rows_quota)+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.plan.storage_quota)?_c('div',{staticClass:"subscription-checkout__plan-row"},[_c('div',{staticClass:"subscription-checkout__plan-property"},[_vm._v("\n      "+_vm._s(_vm.$t('common.storage'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"subscription-checkout__plan-value"},[_vm._v("\n      "+_vm._s(_vm.plan.storage_quota)+_vm._s(_vm.$t('common.gb'))+"\n    ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"subscription-checkout__plan-row"},[_c('a',{attrs:{"href":_vm.pricingLink,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.$t('checkoutPlan.detailedPricing')))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }