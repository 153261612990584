var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-element",style:({
    '--space-between-columns': `${_vm.element.column_gap}px`,
    '--alignment': _vm.flexAlignment,
  })},[_vm._l((_vm.childrenElements),function(childrenInColumn,columnIndex){return _c('div',{key:columnIndex,staticClass:"column-element__column",style:({ '--column-width': `${_vm.columnWidth}%` })},[(childrenInColumn.length > 0)?_vm._l((childrenInColumn),function(childCurrent){return _c('div',{key:childCurrent.id,staticClass:"column-element__element"},[(_vm.mode === 'editing')?_c('ElementPreview',{attrs:{"element":childCurrent,"application-context-additions":_vm.applicationContextAdditions},on:{"move":function($event){return _vm.$emit('move', $event)}}}):_c('PageElement',{attrs:{"element":childCurrent,"mode":_vm.mode,"application-context-additions":_vm.applicationContextAdditions}})],1)}):(
        _vm.mode === 'editing' &&
        _vm.$hasPermission(
          'builder.page.create_element',
          _vm.elementPage,
          _vm.workspace.id
        )
      )?_c('AddElementZone',{attrs:{"page":_vm.elementPage},on:{"add-element":function($event){return _vm.showAddElementModal(columnIndex)}}}):_vm._e()],2)}),_vm._v(" "),_c('AddElementModal',{ref:"addElementModal",attrs:{"page":_vm.elementPage}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }