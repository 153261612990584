var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"auto-overflow-scroll",rawName:"v-auto-overflow-scroll"}],staticClass:"context__form context__form--scrollable"},[_c('form',{staticClass:"context__form-container",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('FormGroup',{attrs:{"error":_vm.fieldHasErrors('name')},scopedSlots:_vm._u([{key:"error",fn:function(){return [(_vm.$v.values.name.$dirty && !_vm.$v.values.name.required)?_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('error.requiredField'))+"\n        ")]):(
            _vm.$v.values.name.$dirty && !_vm.$v.values.name.mustHaveUniqueFieldName
          )?_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('fieldForm.fieldAlreadyExists'))+"\n        ")]):(
            _vm.$v.values.name.$dirty &&
            !_vm.$v.values.name.mustNotClashWithReservedName
          )?_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('error.nameNotAllowed'))+"\n        ")]):(_vm.$v.values.name.$dirty && !_vm.$v.values.name.maxLength)?_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('error.nameTooLong'))+"\n        ")]):_vm._e()]},proxy:true}])},[_c('FormInput',{ref:"name",attrs:{"error":_vm.fieldHasErrors('name'),"placeholder":_vm.$t('fieldForm.name')},on:{"blur":function($event){return _vm.$v.values.name.$touch()},"input":function($event){_vm.isPrefilledWithSuggestedFieldName = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleKeydownEnter($event)}},model:{value:(_vm.values.name),callback:function ($$v) {_vm.$set(_vm.values, "name", $$v)},expression:"values.name"}})],1),_vm._v(" "),(_vm.forcedType === null)?_c('FormGroup',{attrs:{"error":_vm.$v.values.type.$error},scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('error.requiredField')))]},proxy:true}],null,false,3428894767)},[_c('Dropdown',{ref:"fieldTypesDropdown",attrs:{"error":_vm.$v.values.type.$error,"fixed-items":true,"disabled":_vm.defaultValues.immutable_type || _vm.defaultValues.immutable_properties},on:{"hide":function($event){return _vm.$v.values.type.$touch()}},model:{value:(_vm.values.type),callback:function ($$v) {_vm.$set(_vm.values, "type", $$v)},expression:"values.type"}},_vm._l((_vm.fieldTypes),function(fieldType,type){return _c('DropdownItem',{key:type,attrs:{"icon":fieldType.iconClass,"name":fieldType.getName(),"value":fieldType.type,"disabled":(_vm.primary && !fieldType.canBePrimaryField) ||
            !fieldType.isEnabled(_vm.workspace) ||
            fieldType.isDeactivated(_vm.workspace.id)},on:{"click":function($event){return _vm.clickOnDeactivatedItem($event, fieldType)}}},[_c('i',{staticClass:"select__item-icon",class:fieldType.iconClass}),_vm._v(" "),_c('span',{staticClass:"select__item-name-text",attrs:{"title":fieldType.getName()}},[_vm._v(_vm._s(fieldType.getName()))]),_vm._v(" "),(fieldType.isDeactivated(_vm.workspace.id))?_c('i',{staticClass:"iconoir-lock"}):_vm._e(),_vm._v(" "),_c(fieldType.getDeactivatedClickModal(_vm.workspace.id),{ref:'deactivatedClickModal-' + fieldType.type,refInFor:true,tag:"component",attrs:{"v-if":fieldType.isDeactivated(_vm.workspace.id) &&
              fieldType.getDeactivatedClickModal(_vm.workspace.id),"name":_vm.$t(fieldType.getName()),"workspace":_vm.workspace}})],1)}),1)],1):_vm._e(),_vm._v(" "),(_vm.hasFormComponent && !_vm.defaultValues.immutable_properties)?[_c(_vm.getFormComponent(_vm.values.type),{ref:"childForm",tag:"component",attrs:{"table":_vm.table,"field-type":_vm.values.type,"view":_vm.view,"primary":_vm.primary,"all-fields-in-table":_vm.allFieldsInTable,"name":_vm.values.name,"default-values":_vm.defaultValues,"database":_vm.database},on:{"validate":_vm.$v.$touch,"suggested-field-name":function($event){return _vm.handleSuggestedFieldName($event)}}})]:_vm._e(),_vm._v(" "),(_vm.showDescription)?_c('FormGroup',{attrs:{"error":_vm.fieldHasErrors('description'),"label":_vm.$t('fieldForm.description'),"small-label":true,"required":""}},[_c('div',{staticClass:"control__elements"},[_c('FormTextarea',{ref:"description",attrs:{"min-rows":1,"max-rows":16,"auto-expandable":"","placeholder":_vm.$t('fieldForm.description'),"size":"small"},model:{value:(_vm.values.description),callback:function ($$v) {_vm.$set(_vm.values, "description", $$v)},expression:"values.description"}})],1)]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }