var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('additionalSignupInformation.whichTeam'),"error":_vm.$v.values.team.$error,"required":"","small-label":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [(_vm.$v.values.team.$dirty && !_vm.$v.values.team.required)?_c('span',[_vm._v(_vm._s(_vm.$t('error.requiredField')))]):_vm._e()]},proxy:true}])},[_c('Dropdown',{class:{ 'dropdown--error': _vm.$v.values.team.$error },on:{"hide":function($event){return _vm.$v.values.team.$touch()}},model:{value:(_vm.values.team),callback:function ($$v) {_vm.$set(_vm.values, "team", $$v)},expression:"values.team"}},_vm._l((_vm.teamChoices),function(choice){return _c('DropdownItem',{key:choice.value,attrs:{"name":choice.name,"value":choice.value}})}),1)],1),_vm._v(" "),_c('FormGroup',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPersonal),expression:"!isPersonal"}],staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('additionalSignupInformation.companySize'),"error":_vm.$v.values.companySize.$error,"required":"","small-label":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [(
            _vm.$v.values.companySize.$dirty && !_vm.$v.values.companySize.required
          )?_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('error.requiredField')))]):_vm._e()]},proxy:true}])},[_c('Dropdown',{class:{ 'dropdown--error': _vm.$v.values.companySize.$error },on:{"hide":function($event){return _vm.$v.values.companySize.$touch()}},model:{value:(_vm.values.companySize),callback:function ($$v) {_vm.$set(_vm.values, "companySize", $$v)},expression:"values.companySize"}},_vm._l((_vm.companySizes),function(choice){return _c('DropdownItem',{key:choice.value,attrs:{"name":choice.name,"value":choice.value}})}),1)],1),_vm._v(" "),_c('FormGroup',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPersonal && !_vm.isSmallCompany),expression:"!isPersonal && !isSmallCompany"}],staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('additionalSignupInformation.country'),"error":_vm.$v.values.country.$error,"small-label":"","required":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [(_vm.$v.values.country.$dirty && !_vm.$v.values.country.required)?_c('span',[_vm._v(_vm._s(_vm.$t('error.requiredField')))]):_vm._e()]},proxy:true}])},[_c('Dropdown',{class:{ 'dropdown--error': _vm.$v.values.country.$error },on:{"hide":function($event){return _vm.$v.values.country.$touch()}},model:{value:(_vm.values.country),callback:function ($$v) {_vm.$set(_vm.values, "country", $$v)},expression:"values.country"}},_vm._l((_vm.countries),function(country){return _c('DropdownItem',{key:country,attrs:{"name":country,"value":country}})}),1)],1),_vm._v(" "),_c('FormGroup',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPersonal && !_vm.isSmallCompany),expression:"!isPersonal && !isSmallCompany"}],staticClass:"margin-bottom-2",attrs:{"label":_vm.$t('additionalSignupInformation.companyName'),"error":_vm.$v.values.companyName.$error,"small-label":"","required":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [(
            _vm.$v.values.companyName.$dirty && !_vm.$v.values.companyName.required
          )?_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('error.requiredField')))]):_vm._e(),_vm._v(" "),(
            _vm.$v.values.companyName.$dirty && !_vm.$v.values.companyName.maxLength
          )?_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('error.maxLength', { max: 100 }))+"\n        ")]):_vm._e()]},proxy:true}])},[_c('FormInput',{attrs:{"type":"text","size":"large","error":_vm.$v.values.companyName.$error},on:{"blur":function($event){return _vm.$v.values.companyName.$touch()}},model:{value:(_vm.values.companyName),callback:function ($$v) {_vm.$set(_vm.values, "companyName", $$v)},expression:"values.companyName"}})],1),_vm._v(" "),_c('FormGroup',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPersonal && !_vm.isSmallCompany),expression:"!isPersonal && !isSmallCompany"}],attrs:{"label":_vm.$t('additionalSignupInformation.role'),"error":_vm.$v.values.role.$error,"small-label":"","required":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [(_vm.$v.values.role.$dirty && !_vm.$v.values.role.required)?_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('error.requiredField')))]):_vm._e(),_vm._v(" "),(_vm.$v.values.role.$dirty && !_vm.$v.values.role.maxLength)?_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('error.maxLength', { max: 100 }))+"\n        ")]):_vm._e()]},proxy:true}])},[_c('FormInput',{attrs:{"type":"text","size":"large","error":_vm.$v.values.role.$error},on:{"blur":function($event){return _vm.$v.values.role.$touch()}},model:{value:(_vm.values.role),callback:function ($$v) {_vm.$set(_vm.values, "role", $$v)},expression:"values.role"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }