var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"member",staticClass:"trash-entry",class:{ 'trash-entry--disabled': _vm.disabled }},[_c('div',{staticClass:"trash-entry__initials"},[_vm._v("\n    "+_vm._s(_vm._f("nameAbbreviation")(_vm.trashEntry.user_who_trashed))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"trash-entry__content"},[_c('div',{staticClass:"trash-entry__name"},[_vm._v("\n      "+_vm._s(_vm.$t('trashEntry.name', {
          user: _vm.trashEntry.user_who_trashed || _vm.$t('trashEntry.deletedUser'),
          type: _vm.$t('trashType.' + _vm.trashEntry.trash_item_type),
          title: _vm.trashItemTitle,
          parent: _vm.trashEntry.parent_name
            ? _vm.$t('trashEntry.fromParent', { parent: _vm.trashEntry.parent_name })
            : '',
        }))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"trash-entry__deleted-at-display"},[_vm._v(_vm._s(_vm.timeAgo))]),_vm._v(" "),(_vm.trashEntry.names)?_c('ul',{staticClass:"trash-entry__items"},[_vm._l((_vm.firstNames),function(name,index){return _c('li',{key:index},[_vm._v("\n        "+_vm._s(name)+"\n      ")])}),_vm._v(" "),(_vm.otherNamesCount)?_c('li',[_vm._v("\n        "+_vm._s(_vm.$t('trashEntry.andMore', { count: _vm.otherNamesCount }))+"\n      ")]):_vm._e()],2):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"trash-entry__actions"},[(!_vm.disabled)?_c('a',{staticClass:"trash-entry__action",class:{ 'trash-entry__action--loading': _vm.trashEntry.loading },on:{"click":_vm.emitRestoreIfNotLoading}},[_vm._v("\n      "+_vm._s(_vm.trashEntry.loading ? '' : _vm.$t('trashEntry.restore'))+"\n    ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }