<template>
  <div>
    <div class="control">
      <div class="control-elements">
        <Checkbox v-model="confirmed" @input="$v.confirmed.$touch()">
          <i18n path="additionalAuthControls.message" tag="span">
            <a :href="url" target="_blank" @click.stop>{{
              $t('additionalAuthControls.termsAndConditions')
            }}</a>
          </i18n>
        </Checkbox>
        <div v-if="$v.confirmed.$error" class="error">
          {{ $t('error.requiredField') }}
        </div>
        <Checkbox
          v-model="newsletter"
          class="margin-top-1"
          @input="
            $emit('updated-account', {
              key: 'newsletterSignup',
              value: $event,
            })
          "
        >
          {{ $t('additionalAuthControls.newsletter') }}
        </Checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdditionalAuthControls',
  data() {
    return {
      confirmed: false,
      newsletter: false,
    }
  },
  computed: {
    url() {
      return this.$nuxt.$router.resolve({
        name: 'terms',
      }).href
    },
  },
  methods: {
    isValid() {
      this.$v.$touch()
      return !this.$v.$invalid
    },
  },
  validations: {
    confirmed: {
      required: (value) => !!value,
    },
  },
}
</script>
