var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row-modal__hidden-separator"},[_c('div',{staticClass:"row-modal__hidden-separator-line"}),_vm._v(" "),_c('a',{staticClass:"row-modal__hidden-separator-link",on:{"click":function($event){return _vm.$emit('toggle-hidden-fields-visibility')}}},[_vm._v("\n      "+_vm._s(_vm.$t(
          _vm.showHiddenFields
            ? 'rowEditModal.hideHiddenFields'
            : 'rowEditModal.showHiddenFields'
        ))+"\n      "),_c('i',{class:[
          _vm.showHiddenFields
            ? 'iconoir-nav-arrow-up'
            : 'iconoir-nav-arrow-down',
        ]})]),_vm._v(" "),_c('div',{staticClass:"row-modal__hidden-separator-line"})]),_vm._v(" "),(_vm.showHiddenFields)?_c('div',[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }