var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar__section",attrs:{"ph-autocapture":"sidebar"}},[_c('ul',{staticClass:"tree"},[_c('nuxt-link',{attrs:{"to":{
        name: 'workspace',
        params: {
          workspaceId: _vm.selectedWorkspace.id,
        },
      }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isExactActive }){return [_c('li',{staticClass:"tree__item",class:{
          active: isExactActive,
        }},[_c('div',{staticClass:"tree__action sidebar__action"},[_c('a',{staticClass:"tree__link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"tree__icon iconoir-home-simple"}),_vm._v(" "),_c('span',{staticClass:"tree__link-text"},[_c('span',{staticClass:"sidebar__item-name"},[_vm._v(_vm._s(_vm.$t('sidebar.home')))])])])])])]}}])}),_vm._v(" "),_c('li',{staticClass:"tree__item"},[_c('div',{staticClass:"tree__action tree__action--has-counter"},[_c('a',{staticClass:"tree__link",on:{"click":function($event){return _vm.$refs.notificationPanel.toggle($event.currentTarget)}}},[_c('i',{staticClass:"tree__icon tree__icon--type iconoir-bell"}),_vm._v(" "),_c('span',{staticClass:"tree__link-text"},[_vm._v(_vm._s(_vm.$t('sidebar.notifications')))])]),_vm._v(" "),_c('BadgeCounter',{directives:[{name:"show",rawName:"v-show",value:(_vm.unreadNotificationCount),expression:"unreadNotificationCount"}],staticClass:"tree__counter",attrs:{"count":_vm.unreadNotificationCount,"limit":10}})],1),_vm._v(" "),_c('NotificationPanel',{ref:"notificationPanel"})],1),_vm._v(" "),(
        _vm.$hasPermission(
          'workspace.list_workspace_users',
          _vm.selectedWorkspace,
          _vm.selectedWorkspace.id
        )
      )?_c('nuxt-link',{attrs:{"to":{
        name: 'settings-members',
        params: {
          workspaceId: _vm.selectedWorkspace.id,
        },
      }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isExactActive }){return [_c('li',{staticClass:"tree__item",class:{
          active: isExactActive,
        },attrs:{"data-highlight":"members"}},[_c('div',{staticClass:"tree__action sidebar__action"},[_c('a',{staticClass:"tree__link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"tree__icon iconoir-group"}),_vm._v(" "),_c('span',{staticClass:"tree__link-text"},[_c('span',{staticClass:"sidebar__item-name"},[_vm._v(_vm._s(_vm.$t('sidebar.members')))])]),_vm._v(" "),(_vm.selectedWorkspace.users.length)?_c('span',{staticClass:"sidebar__item-count"},[_vm._v("\n              "+_vm._s(_vm.selectedWorkspace.users.length))]):_vm._e()])])])]}}],null,false,258944952)}):_vm._e(),_vm._v(" "),(
        _vm.$hasPermission(
          'workspace.create_invitation',
          _vm.selectedWorkspace,
          _vm.selectedWorkspace.id
        )
      )?_c('li',{staticClass:"tree__item"},[_c('div',{staticClass:"tree__action sidebar__action"},[_c('a',{staticClass:"tree__link",on:{"click":function($event){return _vm.$refs.inviteModal.show()}}},[_c('i',{staticClass:"tree__icon iconoir-add-user"}),_vm._v(" "),_c('span',{staticClass:"tree__link-text"},[_c('span',{staticClass:"sidebar__item-name"},[_vm._v(_vm._s(_vm.$t('sidebar.inviteOthers')))])])])]),_vm._v(" "),_c('WorkspaceMemberInviteModal',{ref:"inviteModal",attrs:{"workspace":_vm.selectedWorkspace},on:{"invite-submitted":_vm.handleInvite}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.sidebarWorkspaceComponents),function(component,index){return _c(component,{key:'sidebarWorkspaceComponents' + index,tag:"component",attrs:{"workspace":_vm.selectedWorkspace}})}),_vm._v(" "),_c('li',{staticClass:"tree__item"},[_c('div',{staticClass:"tree__action sidebar__action"},[_c('a',{staticClass:"tree__link",on:{"click":function($event){return _vm.$refs.trashModal.show()}}},[_c('i',{staticClass:"tree__icon iconoir-bin"}),_vm._v(" "),_c('span',{staticClass:"tree__link-text"},[_c('span',{staticClass:"sidebar__item-name"},[_vm._v(_vm._s(_vm.$t('sidebar.trash')))])])]),_vm._v(" "),_c('TrashModal',{ref:"trashModal"})],1)])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }