<template functional>
  <div class="card-text">
    {{ props.value ? '••••••••••' : '' }}
  </div>
</template>

<script>
export default {
  height: 16,
}
</script>
