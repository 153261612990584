var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"box__title"},[_vm._v(_vm._s(_vm.$t('deleteAccountSettings.title')))]),_vm._v(" "),_c('p',[_vm._v("\n    "+_vm._s(_vm.$t('deleteAccountSettings.description', {
        days: _vm.settings.account_deletion_grace_delay,
      }))+"\n  ")]),_vm._v(" "),(_vm.$fetchState.pending)?_c('div',{staticClass:"loading__wrapper"},[_c('div',{staticClass:"loading loading-absolute-center"})]):(_vm.$fetchState.error)?_c('Alert',{attrs:{"type":"error"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('deleteAccountSettings.workspaceLoadingError')))]},proxy:true}])},[_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('deleteAccountSettings.workspaceLoadingErrorDescription')))])]):(_vm.orphanWorkspaces.length)?_c('div',{staticClass:"delete-section"},[_c('div',{staticClass:"delete-section__label"},[_vm._m(0),_vm._v("\n      "+_vm._s(_vm.$t('deleteAccountSettings.orphanWorkspaces'))+"\n    ")]),_vm._v(" "),_c('p',{staticClass:"delete-section__description"},[_vm._v("\n      "+_vm._s(_vm.$t('deleteAccountSettings.workspaceNoticeDescription'))+"\n    ")]),_vm._v(" "),_c('ul',{staticClass:"delete-section__list"},_vm._l((_vm.orphanWorkspaces),function(workspace){return _c('li',{key:workspace.id},[_c('i',{staticClass:"delete-section__list-icon iconoir-community"}),_vm._v("\n        "+_vm._s(workspace.name)+"\n        "),_c('small',[_vm._v("\n          "+_vm._s(_vm.$tc(
              'deleteAccountSettings.orphanWorkspaceMemberCount',
              _vm.workspaceMembers[workspace.id].length,
              {
                count: _vm.workspaceMembers[workspace.id].length,
              }
            )))])])}),0)]):_vm._e(),_vm._v(" "),_c('Error',{attrs:{"error":_vm.error}}),_vm._v(" "),(!_vm.success)?_c('form',{staticClass:"delete-account-settings__form",on:{"submit":function($event){$event.preventDefault();return _vm.deleteAccount.apply(null, arguments)}}},[_c('div',{staticClass:"actions actions--right"},[_c('Button',{attrs:{"type":"danger","size":"large","loading":_vm.loading,"disabled":_vm.loading,"icon":"iconoir-bin"}},[_vm._v("\n        "+_vm._s(_vm.$t('deleteAccountSettings.submitButton'))+"\n      ")])],1)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"delete-section__label-icon"},[_c('i',{staticClass:"iconoir-warning-triangle"})])
}]

export { render, staticRenderFns }