var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"absolute"}},[_c('ApplicationContext',{ref:"context",attrs:{"application":_vm.application,"workspace":_vm.workspace},scopedSlots:_vm._u([{key:"additional-context-items",fn:function(){return [(
          _vm.$hasPermission(
            'application.update',
            _vm.application,
            _vm.application.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link",on:{"click":_vm.openSettingsModal}},[_c('i',{staticClass:"context__menu-item-icon iconoir-settings"}),_vm._v("\n          "+_vm._s(_vm.$t('sidebarComponentBuilder.settings'))+"\n        ")])]):_vm._e()]},proxy:true}])}),_vm._v(" "),_c('BuilderSettingsModal',{ref:"builderSettingsModal",attrs:{"builder":_vm.application}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }