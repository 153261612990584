var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SidebarApplication',{ref:"sidebarApplication",attrs:{"workspace":_vm.workspace,"application":_vm.application},on:{"selected":_vm.selected},scopedSlots:_vm._u([(_vm.isAppSelected(_vm.application))?{key:"body",fn:function(){return [_c('ul',{staticClass:"tree__subs"},_vm._l((_vm.orderedPages),function(page){return _c('SidebarItemBuilder',{directives:[{name:"sortable",rawName:"v-sortable",value:({
            id: page.id,
            update: _vm.orderPages,
            marginTop: -1.5,
            enabled: _vm.$hasPermission(
              'builder.order_pages',
              _vm.application,
              _vm.application.workspace.id
            ),
          }),expression:"{\n            id: page.id,\n            update: orderPages,\n            marginTop: -1.5,\n            enabled: $hasPermission(\n              'builder.order_pages',\n              application,\n              application.workspace.id\n            ),\n          }"}],key:page.id,attrs:{"builder":_vm.application,"page":page}})}),1),_vm._v(" "),(_vm.pendingJobs.length)?_c('ul',{staticClass:"tree__subs"},_vm._l((_vm.pendingJobs),function(job){return _c(_vm.getPendingJobComponent(job),{key:job.id,tag:"component",attrs:{"job":job}})}),1):_vm._e(),_vm._v(" "),(
          _vm.$hasPermission(
            'builder.create_page',
            _vm.application,
            _vm.application.workspace.id
          )
        )?_c('a',{staticClass:"tree__sub-add",on:{"click":function($event){return _vm.$refs.createPageModal.show()}}},[_c('i',{staticClass:"tree__sub-add-icon iconoir-plus"}),_vm._v("\n        "+_vm._s(_vm.$t('sidebarComponentBuilder.createPage'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('CreatePageModal',{ref:"createPageModal",attrs:{"builder":_vm.application,"workspace":_vm.application.workspace}})]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }