var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iframe-element"},[(
      (_vm.element.source_type === _vm.IFRAME_SOURCE_TYPES.URL && !_vm.resolvedURL) ||
      (_vm.element.source_type === _vm.IFRAME_SOURCE_TYPES.EMBED && !_vm.resolvedEmbed)
    )?_c('p',{staticClass:"iframe-element__empty",style:({ height: `${_vm.element.height}px` })},[_vm._v("\n    "+_vm._s(_vm.element.url || _vm.element.embed
        ? _vm.mode === 'editing'
          ? _vm.$t('iframeElementForm.emptyValue')
          : ''
        : _vm.$t('iframeElementForm.missingValue'))+"\n  ")]):[_c('client-only',{scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"loading-spinner iframe-element__placeholder",style:({ height: `${_vm.element.height}px` })})]},proxy:true}])},[_c('iframe',{staticClass:"iframe-element__iframe",style:(_vm.isEditMode ? 'pointer-events: none' : ''),attrs:{"height":_vm.element.height,"src":_vm.element.source_type === _vm.IFRAME_SOURCE_TYPES.URL ? _vm.resolvedURL : null,"srcdoc":_vm.element.source_type === _vm.IFRAME_SOURCE_TYPES.EMBED
            ? _vm.resolvedEmbed
            : null}})])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }