export function getTeamChoices(context) {
  return [
    {
      name: context.$t('saasSales.teamMarketing'),
      value: 'Marketing',
    },
    {
      name: context.$t('saasSales.teamProductDesign'),
      value: 'Product & Design',
    },
    {
      name: context.$t('saasSales.teamEngineering'),
      value: 'Engineering',
    },
    {
      name: context.$t('saasSales.teamOperations'),
      value: 'Operations',
    },
    {
      name: context.$t('saasSales.teamITSupport'),
      value: 'IT & Support',
    },
    {
      name: context.$t('saasSales.teamSalesAccountManagement'),
      value: 'Sales & Account Management',
    },
    {
      name: context.$t('saasSales.teamHRLegal'),
      value: 'HR & Legal',
    },
    {
      name: context.$t('saasSales.teamCreativeProduction'),
      value: 'Creative Production',
    },
    {
      name: context.$t('saasSales.teamCustomerService'),
      value: 'Customer Service',
    },
    {
      name: context.$t('saasSales.teamFinance'),
      value: 'Finance',
    },
    {
      name: context.$t('saasSales.teamManufacturing'),
      value: 'Manufacturing',
    },
    {
      name: context.$t('saasSales.teamOtherPersonal'),
      value: 'Other / Personal',
    },
  ]
}

export function getCompanySizeChoices(context) {
  return [
    {
      name: context.$t('saasSales.employees', {
        amount: '0 - 10',
      }),
      value: '0 - 10 employees',
    },
    {
      name: context.$t('saasSales.employees', {
        amount: '11 - 50',
      }),
      value: '11 - 50 employees',
    },
    {
      name: context.$t('saasSales.employees', {
        amount: '51 - 200',
      }),
      value: '51 - 200 employees',
    },
    {
      name: context.$t('saasSales.employees', {
        amount: '201 - 500',
      }),
      value: '201 - 500 employees',
    },
    {
      name: context.$t('saasSales.employees', {
        amount: '501 - 1000',
      }),
      value: '501 - 1000 employees',
    },
    {
      name: context.$t('saasSales.employees', {
        amount: '1001 - 5000',
      }),
      value: '1001 - 5000 employees',
    },
    {
      name: context.$t('saasSales.employees', {
        amount: '5001 - 1000',
      }),
      value: '5001 - 10000 employees',
    },
    {
      name: context.$t('saasSales.employees', {
        amount: '10000+',
      }),
      value: '10000+ employees',
    },
  ]
}
