/**
 * This middleware will make sure that highlighted blog post summaries are
 * fetched and available in the blog store for various components to use.
 */
export default async function SaasLayout({ store, req }) {
  // If nuxt generate, pass this middleware
  if (process.server && !req) return

  if (!store.getters['saasLayout/isLoaded']) {
    await store.dispatch('saasLayout/fetchAll')
  }
}
