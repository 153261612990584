var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"members-role-field"},[(
      _vm.userId === _vm.row.user_id ||
      !_vm.$hasPermission(
        'workspace_user.update',
        _vm.row,
        _vm.column.additionalProps.workspaceId
      )
    )?_c('span',[_vm._v("\n    "+_vm._s(_vm.roleName(_vm.roles, _vm.row))+"\n  ")]):_c('a',{ref:"editRoleContextLink",on:{"click":function($event){return _vm.$refs.editRoleContext.toggle(_vm.$refs.editRoleContextLink)}}},[_vm._v("\n    "+_vm._s(_vm.roleName(_vm.roles, _vm.row))+"\n    "),_c('i',{staticClass:"iconoir-nav-arrow-down"})]),_vm._v(" "),_c('EditRoleContext',{ref:"editRoleContext",attrs:{"subject":_vm.row,"roles":_vm.roles,"workspace":_vm.workspace,"role-value-column":"role_uid"},on:{"update-role":function($event){return _vm.roleUpdate($event)}}}),_vm._v(" "),(_vm.roleUidSelected === 'NO_ACCESS')?_c('HelpIcon',{attrs:{"tooltip":_vm.$t('membersRoleField.noAccessHelpText')}}):_vm._e(),_vm._v(" "),(_vm.roleUidSelected === 'ADMIN')?_c('HelpIcon',{attrs:{"tooltip":_vm.$t('membersRoleField.adminHelpText'),"icon":"warning-triangle"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }