var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.$hasPermission(
      'database.table.view.list_decoration',
      _vm.view,
      _vm.database.workspace.id
    )
  )?_c('div',[_c('a',{ref:"contextLink",staticClass:"header__filter-link",class:{ 'active--primary': _vm.decoratorCount > 0 },on:{"click":function($event){return _vm.$refs.context.toggle(_vm.$refs.contextLink, 'bottom', 'left', 4, -16)}}},[_c('i',{staticClass:"header__filter-icon iconoir-palette"}),_vm._v(" "),_c('span',{staticClass:"header__filter-name"},[_vm._v("\n      "+_vm._s(_vm.$tc('viewDecorator.decorator', _vm.decoratorCount, {
          count: _vm.decoratorCount,
        }))+"\n    ")])]),_vm._v(" "),_c('ViewDecoratorContext',{ref:"context",attrs:{"database":_vm.database,"view":_vm.view,"table":_vm.table,"fields":_vm.fields,"read-only":_vm.readOnly ||
      !_vm.$hasPermission(
        'database.table.view.create_decoration',
        _vm.view,
        _vm.database.workspace.id
      )},on:{"changed":function($event){return _vm.$emit('changed')}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }