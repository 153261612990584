<template>
  <Alert type="warning">
    <p>{{ $t('snapshotModalWarning.message') }}</p>
  </Alert>
</template>

<script>
export default {
  name: 'SnapshotModalWarning',
}
</script>
