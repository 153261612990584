var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dateFields.length > 0)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('Error',{staticClass:"timeline-date-settings-form__alert",attrs:{"error":_vm.incompatibleFieldsError}}),_vm._v(" "),_c('FormElement',{staticClass:"control"},[_c('label',{staticClass:"control__label control__label--small"},[_vm._v("\n        "+_vm._s(_vm.$t('timelineDateSettingsForm.startDateField'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"control__elements"},[_c('Dropdown',{attrs:{"fixed-items":"","show-search":true,"disabled":_vm.readOnly,"placeholder":_vm.readOnly ? ' ' : _vm.$t('action.makeChoice')},model:{value:(_vm.values.startDateFieldId),callback:function ($$v) {_vm.$set(_vm.values, "startDateFieldId", $$v)},expression:"values.startDateFieldId"}},[_c('DropdownItem',{key:null,attrs:{"name":"","value":null}},[_c('div',{style:({ height: '15px' })})]),_vm._v(" "),_vm._l((_vm.availableStartDateFields),function(dateField){return _c('DropdownItem',{key:dateField.id,attrs:{"name":_vm.getDateFieldNameAndAttrs(dateField),"value":dateField.id,"icon":_vm.fieldIcon(dateField.type)}})})],2),_vm._v(" "),(
            _vm.fieldHasErrors('startDateFieldId') &&
            !_vm.$v.values.startDateFieldId.required
          )?_c('div',{staticClass:"error"},[_vm._v("\n          "+_vm._s(_vm.$t('error.requiredField'))+"\n        ")]):_vm._e()],1)]),_vm._v(" "),_c('FormElement',{staticClass:"control margin-top-2"},[_c('label',{staticClass:"control__label control__label--small"},[_vm._v("\n        "+_vm._s(_vm.$t('timelineDateSettingsForm.endDateField'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"control__elements"},[_c('Dropdown',{attrs:{"fixed-items":"","show-search":true,"disabled":_vm.readOnly},model:{value:(_vm.values.endDateFieldId),callback:function ($$v) {_vm.$set(_vm.values, "endDateFieldId", $$v)},expression:"values.endDateFieldId"}},[_c('DropdownItem',{key:null,attrs:{"name":"","value":null}},[_c('div',{style:({ height: '15px' })})]),_vm._v(" "),_vm._l((_vm.availableEndDateFields),function(dateField){return _c('DropdownItem',{key:dateField.id,attrs:{"name":_vm.getDateFieldNameAndAttrs(dateField),"value":dateField.id,"icon":_vm.fieldIcon(dateField.type)}})})],2),_vm._v(" "),(
            _vm.fieldHasErrors('endDateFieldId') &&
            !_vm.$v.values.endDateFieldId.required
          )?_c('div',{staticClass:"error"},[_vm._v("\n          "+_vm._s(_vm.$t('error.requiredField'))+"\n        ")]):_vm._e()],1)]),_vm._v(" "),_vm._t("default")],2):_c('p',[_vm._v("\n    "+_vm._s(_vm.$t('timelineDateSettingsForm.noCompatibleDateFields'))+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }