<template>
  <form @submit.prevent="submit">
    <FormGroup
      :error="fieldHasErrors('gitlab_url')"
      :helper-text="$t('gitlabIssuesDataSync.urlHelper')"
      required
      small-label
      class="margin-bottom-2"
    >
      <template #label>{{ $t('gitlabIssuesDataSync.url') }}</template>
      <FormInput
        ref="gitlab_url"
        v-model="values.gitlab_url"
        size="large"
        :error="fieldHasErrors('gitlab_url')"
        @focus.once="$event.target.select()"
        @blur="$v.values.gitlab_url.$touch()"
      />
      <template #error>
        <span
          v-if="$v.values.gitlab_url.$dirty && !$v.values.gitlab_url.required"
        >
          {{ $t('error.requiredField') }}
        </span>
        <span
          v-else-if="$v.values.gitlab_url.$dirty && !$v.values.gitlab_url.url"
        >
          {{ $t('error.invalidURL') }}
        </span>
      </template>
    </FormGroup>

    <FormGroup
      :error="fieldHasErrors('gitlab_project_id')"
      :label="$t('gitlabIssuesDataSync.projectId')"
      required
      class="margin-bottom-2"
      :helper-text="$t('gitlabIssuesDataSync.projectIdHelper')"
      small-label
    >
      <FormInput
        v-model="values.gitlab_project_id"
        :error="fieldHasErrors('gitlab_project_id')"
        size="large"
        @blur="$v.values.gitlab_project_id.$touch()"
      />
      <template #error>
        <span
          v-if="
            $v.values.gitlab_project_id.$dirty &&
            !$v.values.gitlab_project_id.required
          "
        >
          {{ $t('error.requiredField') }}
        </span>
      </template>
    </FormGroup>

    <FormGroup
      :error="fieldHasErrors('gitlab_access_token')"
      :label="$t('gitlabIssuesDataSync.accessToken')"
      required
      class="margin-bottom-2"
      :helper-text="$t('gitlabIssuesDataSync.accessTokenHelper')"
      small-label
    >
      <FormInput
        v-model="values.gitlab_access_token"
        :error="fieldHasErrors('gitlab_access_token')"
        size="large"
        @blur="$v.values.gitlab_access_token.$touch()"
      />
      <template #error>
        <span
          v-if="
            $v.values.gitlab_access_token.$dirty &&
            !$v.values.gitlab_access_token.required
          "
        >
          {{ $t('error.requiredField') }}
        </span>
      </template>
    </FormGroup>
  </form>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators'
import form from '@baserow/modules/core/mixins/form'

export default {
  name: 'GitLabIssuesDataSyncForm',
  mixins: [form],
  data() {
    return {
      allowedValues: ['gitlab_url', 'gitlab_project_id', 'gitlab_access_token'],
      values: {
        gitlab_url: 'https://gitlab.com',
        gitlab_project_id: '',
        gitlab_access_token: '',
      },
    }
  },
  validations: {
    values: {
      gitlab_url: { required, url },
      gitlab_project_id: { required },
      gitlab_access_token: { required },
    },
  },
}
</script>
