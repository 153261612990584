var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.singleSelectField === null)?_c('div',{staticClass:"kanban-view__stacked-by-page"},[_c('KanbanViewStackedBy',{attrs:{"table":_vm.table,"view":_vm.view,"fields":_vm.fields,"database":_vm.database,"read-only":_vm.readOnly,"store-prefix":_vm.storePrefix,"include-field-options-on-refresh":true},on:{"refresh":function($event){return _vm.$emit('refresh', $event)}}})],1):_c('div',{directives:[{name:"auto-scroll",rawName:"v-auto-scroll",value:({
    orientation: 'horizontal',
    enabled: () => _vm.draggingRow !== null,
    speed: 6,
    padding: 12,
  }),expression:"{\n    orientation: 'horizontal',\n    enabled: () => draggingRow !== null,\n    speed: 6,\n    padding: 12,\n  }"}],ref:"kanban",staticClass:"kanban-view"},[_c('div',{staticClass:"kanban-view__stacks"},[_c('KanbanViewStack',{attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view,"card-fields":_vm.cardFields,"fields":_vm.fields,"read-only":_vm.readOnly,"store-prefix":_vm.storePrefix},on:{"create-row":_vm.openCreateRowModal,"edit-row":function($event){return _vm.openRowEditModal($event)},"refresh":function($event){return _vm.$emit('refresh', $event)},"row-context":function($event){return _vm.showRowContext($event.event, $event.row)}}}),_vm._v(" "),_vm._l((_vm.existingSelectOption),function(option){return _c('KanbanViewStack',{key:option.id,attrs:{"option":option,"database":_vm.database,"table":_vm.table,"view":_vm.view,"card-fields":_vm.cardFields,"fields":_vm.fields,"read-only":_vm.readOnly,"store-prefix":_vm.storePrefix},on:{"create-row":_vm.openCreateRowModal,"edit-row":function($event){return _vm.openRowEditModal($event)},"refresh":function($event){return _vm.$emit('refresh', $event)},"row-context":function($event){return _vm.showRowContext($event.event, $event.row)}}})}),_vm._v(" "),(
        !_vm.readOnly &&
        !_vm.singleSelectField.immutable_properties &&
        _vm.$hasPermission(
          'database.table.field.update',
          _vm.table,
          _vm.database.workspace.id
        )
      )?_c('a',{ref:"addOptionContextLink",staticClass:"kanban-view__add-stack",on:{"click":function($event){return _vm.$refs.addOptionContext.toggle(_vm.$refs.addOptionContextLink)}}},[_c('i',{staticClass:"iconoir-plus"})]):_vm._e(),_vm._v(" "),_c('KanbanViewCreateStackContext',{ref:"addOptionContext",attrs:{"fields":_vm.fields,"store-prefix":_vm.storePrefix}})],2),_vm._v(" "),_c('RowCreateModal',{ref:"rowCreateModal",attrs:{"database":_vm.database,"table":_vm.table,"view":_vm.view,"primary-is-sortable":true,"visible-fields":_vm.cardFields,"hidden-fields":_vm.hiddenFields,"show-hidden-fields":_vm.showHiddenFieldsInRowModal,"all-fields-in-table":_vm.fields},on:{"toggle-hidden-fields-visibility":function($event){_vm.showHiddenFieldsInRowModal = !_vm.showHiddenFieldsInRowModal},"created":_vm.createRow,"order-fields":_vm.orderFields,"toggle-field-visibility":_vm.toggleFieldVisibility,"field-updated":function($event){return _vm.$emit('refresh', $event)},"field-deleted":function($event){return _vm.$emit('refresh')}}}),_vm._v(" "),_c('RowEditModal',{ref:"rowEditModal",attrs:{"enable-navigation":"","database":_vm.database,"table":_vm.table,"view":_vm.view,"all-fields-in-table":_vm.fields,"primary-is-sortable":true,"visible-fields":_vm.cardFields,"hidden-fields":_vm.hiddenFields,"rows":_vm.allRows,"read-only":_vm.readOnly ||
      !_vm.$hasPermission(
        'database.table.update_row',
        _vm.table,
        _vm.database.workspace.id
      ),"show-hidden-fields":_vm.showHiddenFieldsInRowModal},on:{"hidden":function($event){return _vm.$emit('selected-row', undefined)},"toggle-hidden-fields-visibility":function($event){_vm.showHiddenFieldsInRowModal = !_vm.showHiddenFieldsInRowModal},"update":_vm.updateValue,"order-fields":_vm.orderFields,"toggle-field-visibility":_vm.toggleFieldVisibility,"field-updated":function($event){return _vm.$emit('refresh', $event)},"field-deleted":function($event){return _vm.$emit('refresh')},"field-created":function($event){_vm.fieldCreated($event)
      _vm.showHiddenFieldsInRowModal = true},"field-created-callback-done":_vm.afterFieldCreatedUpdateFieldOptions,"navigate-previous":function($event){return _vm.$emit('navigate-previous', $event)},"navigate-next":function($event){return _vm.$emit('navigate-next', $event)},"refresh-row":_vm.refreshRow}}),_vm._v(" "),_c('Context',{ref:"cardContext",attrs:{"overflow-scroll":true,"max-height-if-outside-viewport":true}},[_c('ul',{staticClass:"context__menu"},[(
          !_vm.readOnly &&
          _vm.$hasPermission(
            'database.table.delete_row',
            _vm.table,
            _vm.database.workspace.id
          )
        )?_c('li',{staticClass:"context__menu-item"},[_c('a',{staticClass:"context__menu-item-link js-ctx-delete-row",on:{"click":function($event){return _vm.deleteRow(_vm.selectedRow)}}},[_c('i',{staticClass:"context__menu-item-icon iconoir-bin"}),_vm._v("\n          "+_vm._s(_vm.$t('gridView.deleteRow'))+"\n        ")])]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }