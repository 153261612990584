export default (client) => {
  return {
    getPlans() {
      return client.get(`/saas/subscriptions/plans/`)
    },
    getSubscription(subscriptionId) {
      return client.get(`/saas/subscriptions/subscription/${subscriptionId}/`)
    },
    getSubscriptions() {
      return client.get(`/saas/subscriptions/subscriptions/`)
    },
    getWorkspaces() {
      return client.get(`/saas/subscriptions/workspaces/`)
    },
    getPayLink(productId, quantity, workspaceId, instanceId) {
      const body = {
        product_id: productId,
      }
      if (workspaceId) {
        body.workspace_id = workspaceId
      }
      if (instanceId) {
        body.instance_id = instanceId
        body.quantity = quantity
      }
      return client.post(`/saas/subscriptions/paylinks/`, body)
    },
    getPayments(subscriptionId) {
      return client.get(`/saas/subscriptions/payments/${subscriptionId}/`)
    },
    cancelSubscription(subscriptionId) {
      return client.delete(
        `/saas/subscriptions/subscription/${subscriptionId}/`
      )
    },
    updateSubscription(subscriptionId, seats, newPlanId) {
      const body = {}
      if (seats) {
        body.seats = seats
      }
      if (newPlanId) {
        body.new_plan_id = newPlanId
      }

      return client.patch(
        `/saas/subscriptions/subscription/${subscriptionId}/`,
        body
      )
    },
    updateInstanceId(subscriptionId, newInstanceId) {
      return client.patch(`/saas/subscriptions/license/${subscriptionId}/`, {
        new_instance_id: newInstanceId,
      })
    },
    getDashboard(workspaceId) {
      let queryParams = ''
      if (workspaceId) {
        queryParams = `?workspace_id=${workspaceId}`
      }

      return client.get(`/saas/subscriptions/dashboard/${queryParams}`)
    },
    getLicense(subscriptionId) {
      return client.get(`/saas/subscriptions/license/${subscriptionId}/`)
    },
    startTrial(workspaceId) {
      return client.post(`/saas/subscriptions/trials/`, {
        workspace_id: workspaceId,
      })
    },
  }
}
