var render = function render(){var _vm=this,_c=_vm._self._c;return _c('textarea',{ref:"textarea",staticClass:"form-textarea",class:{
    'form-textarea--error': _vm.error,
    'form-textarea--disabled': _vm.disabled,
    'form-textarea--small': _vm.size === 'small',
  },style:({
    height: _vm.textBoxSize ? `${_vm.textBoxSize}px` : 'auto',
    overflow: _vm.textBoxOverflow ? _vm.textBoxOverflow : 'visible',
  }),attrs:{"rows":_vm.autoExpandable ? _vm.minRows : _vm.rows,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxlength},domProps:{"value":_vm.fromValue(_vm.value)},on:{"blur":function($event){return _vm.$emit('blur', $event)},"focus":function($event){return _vm.$emit('focus', $event)},"input":function($event){_vm.$emit('input', _vm.toValue($event.target.value))},"keyup":function($event){return _vm.$emit('keyup', $event)},"keydown":function($event){return _vm.$emit('keydown', $event)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }