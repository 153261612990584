<template>
  <ConfirmTermsAndConditionsModal
    ref="confirmTermsAndConditionsModal"
  ></ConfirmTermsAndConditionsModal>
</template>

<script>
import ConfirmTermsAndConditionsModal from '@saas/components/auth/ConfirmTermsAndConditionsModal'

export default {
  name: 'App',
  components: { ConfirmTermsAndConditionsModal },
  mounted() {
    if (
      !this.$store.getters['auth/getAdditionalUserData'].saas
        .accepted_terms_and_conditions
    ) {
      this.$refs.confirmTermsAndConditionsModal.show()
    }
  },
}
</script>
