var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Expandable',{attrs:{"toggle-on-click":"","default-expanded":_vm.workflowActions.length < 2},scopedSlots:_vm._u([{key:"header",fn:function({ expanded }){return [_c('div',{staticClass:"event__header"},[_c('div',{staticClass:"event__header-left"},[_c('div',{staticClass:"event__label"},[_vm._v("\n          "+_vm._s(_vm.event.label)+"\n        ")]),_vm._v(" "),(_vm.workflowActions.length)?_c('div',{staticClass:"margin-left-1 event__amount-actions"},[_vm._v("\n          "+_vm._s(_vm.workflowActions.length)+"\n        ")]):_vm._e()]),_vm._v(" "),_c('a',{staticClass:"event__toggle"},[_c('i',{class:_vm.getIcon(expanded)})])])]}},{key:"default",fn:function(){return [_c('div',_vm._l((_vm.workflowActions),function(workflowAction,index){return _c('WorkflowAction',{directives:[{name:"sortable",rawName:"v-sortable",value:({
          id: workflowAction.id,
          handle: '[data-sortable-handle]',
          update: _vm.orderWorkflowActions,
          enabled: _vm.$hasPermission(
            'builder.page.element.update',
            _vm.element,
            _vm.workspace.id
          ),
        }),expression:"{\n          id: workflowAction.id,\n          handle: '[data-sortable-handle]',\n          update: orderWorkflowActions,\n          enabled: $hasPermission(\n            'builder.page.element.update',\n            element,\n            workspace.id\n          ),\n        }"}],key:`${workflowAction.id}_${workflowAction.order}_${_vm.workflowActions.length}`,staticClass:"event__workflow-action",class:{ 'event__workflow-action--first': index === 0 },attrs:{"element":_vm.element,"available-workflow-action-types":_vm.availableWorkflowActionTypes,"workflow-action":workflowAction,"workflow-action-index":index,"application-context-additions":{ workflowAction }},on:{"delete":function($event){return _vm.deleteWorkflowAction(workflowAction)}}})}),1),_vm._v(" "),_c('ButtonText',{attrs:{"type":"secondary","icon":"iconoir-plus","loading":_vm.addingAction},on:{"click":_vm.addWorkflowAction}},[_vm._v("\n      "+_vm._s(_vm.$t('event.addAction'))+"\n    ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }