var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.loaded && _vm.loading)?_c('div',{staticClass:"loading-absolute-center"}):[_c('div',{staticClass:"row-history"},[(_vm.entriesWithContents.length > 0)?_c('div',[_c('InfiniteScroll',{ref:"infiniteScroll",attrs:{"current-count":_vm.currentCount,"max-count":_vm.totalCount,"loading":_vm.loading,"reverse":true,"render-end":false},on:{"load-next-page":_vm.loadNextPage},scopedSlots:_vm._u([{key:"default",fn:function(){return _vm._l((_vm.entriesWithContents),function(entry,index){return _c('div',{key:entry.id},[(
                  _vm.shouldDisplayDateSeparator(
                    _vm.entriesWithContents,
                    'timestamp',
                    index
                  )
                )?_c('div',{staticClass:"row-history__day-separator"},[_c('span',[_vm._v(_vm._s(_vm.formatDateSeparator(entry.timestamp)))])]):_vm._e(),_vm._v(" "),_c('RowHistoryEntry',{class:{
                  'row-history-entry--first':
                    index === 0 ||
                    _vm.shouldDisplayDateSeparator(
                      _vm.entriesWithContents,
                      'timestamp',
                      index - 1
                    ),
                },attrs:{"entry":entry,"workspace-id":_vm.database.workspace.id,"fields":_vm.fields}})],1)})},proxy:true}],null,false,1339650964)})],1):_c('div',{staticClass:"row-history__empty"},[_c('i',{staticClass:"row-history__empty-icon baserow-icon-history"}),_vm._v(" "),_c('div',{staticClass:"row-history__empty-text"},[_vm._v("\n          "+_vm._s(_vm.$t('rowHistorySidebar.empty'))+"\n        ")])])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }