var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"colorPicker",staticClass:"color-picker",style:({
    '--vacp-hsl-h': _vm.colors.hsl.h,
    '--vacp-hsl-s': _vm.colors.hsl.s,
    '--vacp-hsl-l': _vm.colors.hsl.l,
    '--vacp-hsl-a': _vm.colors.hsl.a,
    // Added this style via the style because scss doesn't support
    // this one, it fails because of a `hsl` function not found.
    '--vacp-color':
      'hsl(calc(var(--vacp-hsl-h) * 360) calc(var(--vacp-hsl-s) * 100%) calc(var(--vacp-hsl-l) * 100%))',
  })},[_c('div',{ref:"colorSpace",staticClass:"color-picker__space",style:({
      'background-color': 'hsl(calc(var(--vacp-hsl-h) * 360) 100% 50%)',
    }),attrs:{"draggable":"false"},on:{"mousedown":function($event){return _vm.startMovingThumbWithMouse($event, _vm.MOVE_EVENT.COLOR)},"touchstart":function($event){return _vm.startMovingThumbWithTouch($event, _vm.MOVE_EVENT.COLOR)}}},[_c('div',{staticClass:"color-picker__thumb color-picker__thumb--negative-left-margin color-picker__thumb--negative-bottom-margin",style:({
        left: `${_vm.colors.hsv.s * 100}%`,
        bottom: `${_vm.colors.hsv.v * 100}%`,
      })})]),_vm._v(" "),_c('div',{ref:"hueSpace",staticClass:"color-picker__slider color-picker__slider--hue color-picker__thumb--negative-bottom-margin",attrs:{"draggable":"false"},on:{"mousedown":function($event){return _vm.startMovingThumbWithMouse($event, _vm.MOVE_EVENT.HUE)},"touchstart":function($event){return _vm.startMovingThumbWithMouse($event, _vm.MOVE_EVENT.HUE)}}},[_c('div',{staticClass:"color-picker__thumb",style:({
        bottom: `${_vm.colors.hsv.h * 100}%`,
      })})]),_vm._v(" "),(_vm.allowOpacity)?_c('div',{ref:"alphaSpace",staticClass:"color-picker__slider color-picker__slider--alpha color-picker__thumb--negative-bottom-margin",attrs:{"draggable":"false"},on:{"mousedown":function($event){return _vm.startMovingThumbWithMouse($event, _vm.MOVE_EVENT.ALPHA)},"touchstart":function($event){return _vm.startMovingThumbWithMouse($event, _vm.MOVE_EVENT.ALPHA)}}},[_c('div',{staticClass:"color-picker__thumb",style:({
        bottom: `${_vm.colors.hsv.a * 100}%`,
      })})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }