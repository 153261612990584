var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-view-survey"},[(!_vm.submitted)?[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateAndNext(_vm.questionIndex)}}},[_vm._l((_vm.visibleFieldsWithHiddenViaQueryParam),function(field){return _c('FormPageField',{key:field.field.id,ref:'field-' + field.field.id,refInFor:true,class:{ hidden: true },attrs:{"value":_vm.values['field_' + field.field.id],"slug":_vm.$route.params.slug,"field":field}})}),_vm._v(" "),_vm._l((_vm.visibleFieldsWithoutHiddenViaQueryParam),function(field,index){return _c('div',{key:field.field.id,staticClass:"form-view-survey__center",class:{
          'form-view-survey__center--before': index < _vm.questionIndex,
          'form-view-survey__center--after': index > _vm.questionIndex,
        }},[_c('div',{staticClass:"form-view-survey__center-inner-1"},[_c('div',{staticClass:"form-view-survey__center-inner-2"},[_c('FormPageField',{ref:'field-' + field.field.id,refInFor:true,attrs:{"value":_vm.values['field_' + field.field.id],"slug":_vm.$route.params.slug,"field":field},on:{"input":function($event){return _vm.updateValue('field_' + field.field.id, $event)},"focussed":function($event){_vm.questionIndex = index}}}),_vm._v(" "),_c('div',{staticClass:"form-view__field-actions"},[(
                  index < _vm.visibleFieldsWithoutHiddenViaQueryParam.length - 1
                )?_c('Button',{attrs:{"type":"primary","size":"large"},on:{"click":function($event){return _vm.validateAndNext(index)}}},[_vm._v("Next")]):(
                  index >= _vm.visibleFieldsWithoutHiddenViaQueryParam.length - 1
                )?_c('Button',{attrs:{"type":"primary","size":"large","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v("\n                "+_vm._s(_vm.submitText))]):_vm._e()],1)],1)])])})],2),_vm._v(" "),_c('div',{staticClass:"form-view-survey__footer",class:{ 'form-view-survey__footer--single': !_vm.showLogo }},[(_vm.showLogo)?_c('FormViewPoweredBy'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-view-survey__pagination"},[_c('div',{staticClass:"form-view-survey__pagination-buttons"},[_c('a',{staticClass:"form-view-survey__pagination-button",class:{
              'form-view-survey__pagination-button--disabled': !_vm.canPrevious,
            },on:{"click":_vm.previous}},[_c('i',{staticClass:"iconoir-nav-arrow-up"})]),_vm._v(" "),_c('a',{staticClass:"form-view-survey__pagination-button",class:{
              'form-view-survey__pagination-button--disabled': !_vm.canNext,
            },on:{"click":_vm.next}},[_c('i',{staticClass:"iconoir-nav-arrow-down"})])])])],1)]:(_vm.submitted)?_c('div',{staticClass:"form-view-survey__center"},[_c('div',{staticClass:"form-view-survey__center-inner"},[_c('FormViewSubmitted',{attrs:{"show-logo":_vm.showLogo,"is-redirect":_vm.isRedirect,"submit-action-redirect-url":_vm.submitActionRedirectUrl,"submit-action-message":_vm.submitActionMessage}})],1)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }