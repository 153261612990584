<template>
  <li class="context__menu-item">
    <a
      class="context__menu-item-link"
      :class="{ 'context__menu-item-link--loading': loading }"
      @click="handleClick"
    >
      <i class="context__menu-item-icon iconoir-page-edit"></i>
      {{ $t('subscriptionMenuItem.content') }}
    </a>
  </li>
</template>

<script>
export default {
  name: 'SubscriptionMenuItem',
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async handleClick() {
      this.loading = true
      await this.$router.push({ name: 'subscriptions' })
      this.loading = false
      this.$emit('hide')
    },
  },
}
</script>
