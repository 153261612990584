var render = function render(){var _vm=this,_c=_vm._self._c;return _c('RecursiveWrapper',{attrs:{"components":_vm.wrapperDecorations.map((comp) => ({
      ...comp,
      props: comp.propsFn(_vm.row),
    })),"first-component-class":"grid-view__row-background-wrapper"}},[_c('div',{staticClass:"grid-view__row",class:{
      'grid-view__row--selected': _vm.row._.selectedBy.length > 0,
      'grid-view__row--loading': _vm.row._.loading,
      'grid-view__row--hover': _vm.row._.hover,
      'grid-view__row--warning':
        !_vm.row._.matchFilters || !_vm.row._.matchSortings || !_vm.row._.matchSearch,
    },on:{"mouseover":function($event){return _vm.$emit('row-hover', { row: _vm.row, value: true })},"mouseleave":function($event){return _vm.$emit('row-hover', { row: _vm.row, value: false })},"contextmenu":function($event){$event.preventDefault();return _vm.$emit('row-context', { row: _vm.row, event: $event })}}},[(_vm.includeRowDetails)?[(
          !_vm.row._.matchFilters || !_vm.row._.matchSortings || !_vm.row._.matchSearch
        )?_c('div',{staticClass:"grid-view__row-warning"},[(!_vm.row._.matchFilters)?[_vm._v("\n          "+_vm._s(_vm.$t('gridViewRow.rowNotMatchingFilters'))+"\n        ")]:(!_vm.row._.matchSearch)?[_vm._v("\n          "+_vm._s(_vm.$t('gridViewRow.rowNotMatchingSearch'))+"\n        ")]:(!_vm.row._.matchSortings)?[_vm._v(_vm._s(_vm.$t('gridViewRow.rowHasMoved')))]:_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"grid-view__column grid-view__column--no-border-right",class:{ 'grid-view__column--group-end': _vm.groupEnd },style:({ width: _vm.gridViewRowDetailsWidth + 'px' })},[_c('div',{staticClass:"grid-view__row-info",class:{
            'grid-view__row-info--matches-search':
              _vm.row._.matchSearch &&
              _vm.row._.fieldSearchMatches.includes('row_id'),
          }},[_c('div',{staticClass:"grid-view__row-count",class:{ 'grid-view__row-count--small': _vm.rowIdentifier > 9999 },attrs:{"title":_vm.rowIdentifier}},[_vm._v("\n            "+_vm._s(_vm.rowIdentifier)+"\n          ")]),_vm._v(" "),(!_vm.readOnly && _vm.canDrag)?_c('div',{staticClass:"grid-view__row-drag",on:{"mousedown":function($event){return _vm.startDragging($event, _vm.row)}}}):_vm._e(),_vm._v(" "),(!_vm.row._.loading)?_c(_vm.rowExpandButton,{tag:"component",attrs:{"row":_vm.row,"workspace-id":_vm.workspaceId,"table":_vm.view.table},on:{"edit-modal":function($event){return _vm.$emit('edit-modal', _vm.row)}}}):_vm._e(),_vm._v(" "),_vm._l((_vm.firstCellDecorations),function(dec){return _c(dec.component,_vm._b({key:dec.decoration.id,tag:"component"},'component',dec.propsFn(_vm.row),false))})],2)])]:_vm._e(),_vm._v(" "),_vm._l((_vm.fieldsToRender),function(field){return _c('GridViewCell',{key:'row-field-' + _vm.row._.persistentId + '-' + field.id.toString(),style:({
        width: _vm.fieldWidths[field.id] + 'px',
        ..._vm.getSelectedCellStyle(field),
      }),attrs:{"workspace-id":_vm.workspaceId,"field":field,"row":_vm.row,"all-fields-in-table":_vm.allFieldsInTable,"state":_vm.state,"multi-select-position":_vm.getMultiSelectPosition(_vm.row.id, field),"read-only":_vm.readOnly || field.read_only,"store-prefix":_vm.storePrefix,"group-end":_vm.groupEnd},on:{"update":function($event){return _vm.$emit('update', $event)},"paste":function($event){return _vm.$emit('paste', $event)},"edit":function($event){return _vm.$emit('edit', $event)},"select":function($event){return _vm.$emit('select', $event)},"unselect":function($event){return _vm.$emit('unselect', $event)},"selected":function($event){return _vm.$emit('selected', $event)},"unselected":function($event){return _vm.$emit('unselected', $event)},"select-next":function($event){return _vm.$emit('select-next', $event)},"refresh-row":function($event){return _vm.$emit('refresh-row', $event)},"cell-mousedown-left":function($event){return _vm.$emit('cell-mousedown-left', { row: _vm.row, field })},"cell-mouseover":function($event){return _vm.$emit('cell-mouseover', { row: _vm.row, field })},"cell-mouseup-left":function($event){return _vm.$emit('cell-mouseup-left', { row: _vm.row, field })},"cell-shift-click":function($event){return _vm.$emit('cell-shift-click', { row: _vm.row, field })},"add-row-after":function($event){return _vm.$emit('add-row-after', $event)},"edit-modal":function($event){return _vm.$emit('edit-modal', _vm.row)}}})})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }