<template>
  <form @submit.prevent="submit">
    <AdditionalInformationForm
      v-if="plan"
      ref="additionalInformationForm"
      :required="true"
    ></AdditionalInformationForm>
    <div class="trial-modal__plan-info">
      <CheckoutPlan :plan="plan" :selected="true" />
      <p class="trial-modal__plan_description">
        {{ $t('startTrialModal.planDescription') }}
      </p>
    </div>
    <div class="trial-modal__actions">
      <div>
        <Checkbox v-model="values.agreePromo">{{
          $t('startTrialModal.acceptPromoEmails')
        }}</Checkbox>
        <div
          v-if="$v.values.agreePromo.$dirty && !$v.values.agreePromo.required"
          class="error margin-top-1"
        >
          {{ $t('error.requiredField') }}
        </div>
      </div>
      <slot></slot>
    </div>
  </form>
</template>

<script>
import form from '@baserow/modules/core/mixins/form'
import AdditionalInformationForm from '@saas/components/auth/AdditionalInformationForm'
import CheckoutPlan from '@saas/components/subscriptions/CheckoutPlan'

export default {
  name: 'StartTrialForm',
  components: { AdditionalInformationForm, CheckoutPlan },
  mixins: [form],
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      allowedValues: ['agreePromo'],
      values: {
        agreePromo: false,
      },
    }
  },
  validations: {
    values: {
      agreePromo: { required: (value) => value === true },
    },
  },
}
</script>
