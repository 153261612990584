<template>
  <a
    href="https://baserow.io"
    target="_blank"
    title="Baserow - open source no-code database tool and Airtable alternative"
  >
    <Logo
      height="14"
      alt="Baserow - open source no-code database tool and Airtable alternative"
    />
  </a>
</template>

<script>
export default {
  name: 'ExternalLinkBaserowLogo',
}
</script>
