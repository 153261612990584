var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element-preview__menu",on:{"click":function($event){$event.stopPropagation();}}},[(_vm.isDuplicating)?_c('span',{staticClass:"element-preview__menu-item disabled"},[_c('div',{staticClass:"loading"})]):_c('a',{staticClass:"element-preview__menu-item",on:{"click":function($event){return _vm.$emit('duplicate')}}},[_c('i',{staticClass:"iconoir-copy"}),_vm._v(" "),_c('span',{staticClass:"element-preview__menu-item-description"},[_vm._v("\n      "+_vm._s(_vm.$t('action.duplicate'))+" (d)\n    ")])]),_vm._v(" "),(_vm.hasParent)?_c('a',{staticClass:"element-preview__menu-item",on:{"click":function($event){return _vm.$emit('select-parent')}}},[_c('i',{staticClass:"iconoir-scale-frame-enlarge"}),_vm._v(" "),_c('span',{staticClass:"element-preview__menu-item-description"},[_vm._v("\n      "+_vm._s(_vm.$t('elementMenu.selectParent'))+" (p)\n    ")])]):_vm._e(),_vm._v(" "),(_vm.isDirectionVisible(_vm.DIRECTIONS.LEFT))?_c('a',{staticClass:"element-preview__menu-item",class:{
      'element-preview__menu-item--disabled': !_vm.isAllowedDirection(
        _vm.DIRECTIONS.LEFT
      ),
    },on:{"click":function($event){return _vm.$emit('move', _vm.DIRECTIONS.LEFT)}}},[_c('i',{staticClass:"iconoir-nav-arrow-left"}),_vm._v(" "),_c('span',{staticClass:"element-preview__menu-item-description"},[_vm._v("\n      "+_vm._s(_vm.$t('elementMenu.moveLeft'))+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.isDirectionVisible(_vm.DIRECTIONS.RIGHT))?_c('a',{staticClass:"element-preview__menu-item",class:{
      'element-preview__menu-item--disabled': !_vm.isAllowedDirection(
        _vm.DIRECTIONS.RIGHT
      ),
    },on:{"click":function($event){return _vm.$emit('move', _vm.DIRECTIONS.RIGHT)}}},[_c('i',{staticClass:"iconoir-nav-arrow-right"}),_vm._v(" "),_c('span',{staticClass:"element-preview__menu-item-description"},[_vm._v("\n      "+_vm._s(_vm.$t('elementMenu.moveRight'))+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.isDirectionVisible(_vm.DIRECTIONS.BEFORE))?_c('a',{staticClass:"element-preview__menu-item",class:{
      'element-preview__menu-item--disabled': !_vm.isAllowedDirection(
        _vm.DIRECTIONS.BEFORE
      ),
    },on:{"click":function($event){return _vm.$emit('move', _vm.DIRECTIONS.BEFORE)}}},[_c('i',{staticClass:"iconoir-nav-arrow-up"}),_vm._v(" "),_c('span',{staticClass:"element-preview__menu-item-description"},[_vm._v("\n      "+_vm._s(_vm.$t('elementMenu.moveUp'))+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.isDirectionVisible(_vm.DIRECTIONS.AFTER))?_c('a',{staticClass:"element-preview__menu-item",class:{
      'element-preview__menu-item--disabled': !_vm.isAllowedDirection(
        _vm.DIRECTIONS.AFTER
      ),
    },on:{"click":function($event){return _vm.$emit('move', _vm.DIRECTIONS.AFTER)}}},[_c('i',{staticClass:"iconoir-nav-arrow-down"}),_vm._v(" "),_c('span',{staticClass:"element-preview__menu-item-description"},[_vm._v("\n      "+_vm._s(_vm.$t('elementMenu.moveDown'))+"\n    ")])]):_vm._e(),_vm._v(" "),_c('a',{staticClass:"element-preview__menu-item",on:{"click":function($event){return _vm.$emit('delete')}}},[_c('i',{staticClass:"iconoir-bin"}),_vm._v(" "),_c('span',{staticClass:"element-preview__menu-item-description"},[_vm._v("\n      "+_vm._s(_vm.$t('action.delete'))+" (del)\n    ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }