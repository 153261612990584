export default (client) => {
  return {
    getPage(path) {
      const baseURL = new URL(client.defaults.baseURL)
      baseURL.pathname = '/cms-api'
      return client(
        {
          method: 'get',
          url: '/pages/',
          baseURL: baseURL.toString(),
          params: { html_path: path },
        },
        {
          params: { html_path: path },
        }
      )
    },
    getPreview(contentType, token) {
      const baseURL = new URL(client.defaults.baseURL)
      baseURL.pathname = '/cms-api'
      return client({
        method: 'get',
        url: '/preview/1/',
        baseURL: baseURL.toString(),
        params: {
          content_type: contentType,
          token,
          format: 'json',
        },
      })
    },
    getSaaSLayout() {
      const baseURL = new URL(client.defaults.baseURL)
      baseURL.pathname = '/cms-api'
      return client({
        method: 'get',
        url: '/saas-layout/',
        baseURL: baseURL.toString(),
      })
    },
  }
}
