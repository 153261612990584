var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.redirecting)?_c('div',{staticClass:"placeholder"},[_c('div',{staticClass:"placeholder__logo"},[_c('nuxt-link',{attrs:{"to":{
        name: 'application-builder-page',
        params: { pathMatch: '/' },
      }}},[_c('Logo',{staticClass:"placeholder__logo-image"})],1)],1),_vm._v(" "),_c('h1',{staticClass:"placeholder__title"},[_vm._v(_vm._s(_vm.message))]),_vm._v(" "),(_vm.error.statusCode === 404)?_c('p',{staticClass:"placeholder__content"},[_vm._v("\n    "+_vm._s(_vm.$t('errorLayout.notFound'))+"\n  ")]):_c('p',{staticClass:"placeholder__content"},[_vm._v(_vm._s(_vm.content))]),_vm._v(" "),_c('div',{staticClass:"placeholder__action"},[_c('Button',{attrs:{"type":"primary","icon":"iconoir-home","size":"large"},on:{"click":function($event){return _vm.$router.go({
          name: 'application-builder-page',
          params: { pathMatch: '/' },
        })}}},[_vm._v("\n      "+_vm._s(_vm.$t('action.backToHome'))+"\n    ")])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }