export default {
  data() {
    return {
      freePlan: {
        storage_quota: parseInt(this.$config.BASEROW_FREE_TIER_QUOTA_STORAGE),
        rows_quota: parseInt(this.$config.BASEROW_FREE_TIER_QUOTA_ROWS),
      },
    }
  },
  methods: {
    storageUsageInGB(workspace, decimalPlaces = 1) {
      if (!workspace) {
        return 0
      }

      const inMegaBytes = workspace.storage_usage || 0
      const gigaBytes = inMegaBytes / 1024
      return parseFloat(gigaBytes).toFixed(decimalPlaces)
    },
    overLimits(rowCount, storageUsage, rowsQuota, storageQuota) {
      const quotaInMegaBytes = storageQuota * 1024
      return storageUsage > quotaInMegaBytes || rowCount > rowsQuota
    },
  },
}
