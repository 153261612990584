import { render, staticRenderFns } from "./ABHeading.vue?vue&type=template&id=1a1c8f78"
import script from "./ABHeading.vue?vue&type=script&lang=js"
export * from "./ABHeading.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../baserow-saas/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports