var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.elementMode === 'editing' || _vm.isVisible)?_c('div',{staticClass:"element__wrapper",class:{
    'element__wrapper--full-bleed':
      _vm.element.style_width === _vm.WIDTH_TYPES.FULL.value,
    'element__wrapper--full-width':
      _vm.element.style_width === _vm.WIDTH_TYPES.FULL_WIDTH.value,
    'element__wrapper--medium-width':
      _vm.element.style_width === _vm.WIDTH_TYPES.MEDIUM.value,
    'element__wrapper--small-width':
      _vm.element.style_width === _vm.WIDTH_TYPES.SMALL.value,
  },style:(_vm.elementStyles)},[_c('div',{staticClass:"element__inner-wrapper"},[_c(_vm.component,_vm._g({key:_vm.element._.uid,tag:"component",staticClass:"element",attrs:{"element":_vm.element,"application-context-additions":{
        element: _vm.element,
        page: _vm.elementPage,
      }}},_vm.$listeners))],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }