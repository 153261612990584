var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.hasPremiumFeaturesEnabled)?[_c('div',{staticClass:"row-comments"},[_c('div',{staticClass:"row-comments__empty"},[_c('i',{staticClass:"row-comments__empty-icon iconoir-multi-bubble"}),_vm._v(" "),_c('div',{staticClass:"row-comments__empty-text"},[_vm._v("\n          "+_vm._s(_vm.$t('rowCommentSidebar.onlyPremium'))+"\n        ")]),_vm._v(" "),_c('Button',{attrs:{"type":"primary","icon":"iconoir-no-lock"},on:{"click":function($event){return _vm.$refs.premiumModal.show()}}},[_vm._v("\n          "+_vm._s(_vm.$t('rowCommentSidebar.more'))+"\n        ")])],1),_vm._v(" "),_c('PremiumModal',{ref:"premiumModal",attrs:{"name":_vm.$t('rowCommentSidebar.name'),"workspace":_vm.workspace}})],1)]:[(!_vm.loaded && _vm.loading)?_c('div',{staticClass:"loading-absolute-center"}):_c('div',[_c('div',{staticClass:"row-comments"},[(_vm.currentCount === 0)?_c('div',{staticClass:"row-comments__empty"},[_c('i',{staticClass:"row-comments__empty-icon iconoir-multi-bubble"}),_vm._v(" "),_c('div',{staticClass:"row-comments__empty-text"},[(
                !_vm.$hasPermission(
                  'database.table.create_comment',
                  _vm.table,
                  _vm.workspace.id
                )
              )?[_vm._v(_vm._s(_vm.$t('rowCommentSidebar.readOnlyNoComment')))]:[_vm._v("\n              "+_vm._s(_vm.$t('rowCommentSidebar.noComment'))+"\n            ")]],2)]):_c('div',{staticClass:"row-comments__body"},[_c('InfiniteScroll',{ref:"infiniteScroll",attrs:{"current-count":_vm.currentCount,"max-count":_vm.totalCount,"loading":_vm.loading,"reverse":true,"render-end":false},on:{"load-next-page":_vm.nextPage},scopedSlots:_vm._u([{key:"default",fn:function(){return _vm._l((_vm.comments),function(c,index){return _c('div',{key:'row-comment-' + c.id},[(
                    _vm.shouldDisplayDateSeparator(_vm.comments, 'created_on', index)
                  )?_c('div',{staticClass:"row-comment__day-separator"},[_c('span',[_vm._v(_vm._s(_vm.formatDateSeparator(c.created_on)))])]):_vm._e(),_vm._v(" "),_c('RowComment',{attrs:{"comment":c,"workspace":_vm.workspace,"can-edit":_vm.canEditComments,"can-delete":_vm.canDeleteComments}})],1)})},proxy:true}])})],1),_vm._v(" "),(
            _vm.$hasPermission(
              'database.table.create_comment',
              _vm.table,
              _vm.workspace.id
            )
          )?_c('div',{staticClass:"row-comments__foot"},[_c('RichTextEditor',{attrs:{"editor-class":"rich-text-editor__content--comment","mentionable-users":_vm.workspace.users,"placeholder":_vm.$t('rowCommentSidebar.comment'),"enter-stop-edit":true},on:{"stop-edit":function($event){return _vm.postComment()}},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1):_vm._e()])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }