var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{ref:"calendarMonthDay",staticClass:"calendar-month-day",class:{
    'calendar-month-day--not-current': !_vm.day.isCurrentMonth,
    'calendar-month-day--today': _vm.day.isToday,
    'calendar-month-day--weekend': _vm.day.isWeekend,
  }},[(
      !_vm.readOnly &&
      _vm.$hasPermission(
        'database.table.create_row',
        _vm.table,
        _vm.database.workspace.id
      )
    )?_c('a',{staticClass:"calendar-month-day__create-row-btn",on:{"click":function($event){!_vm.readOnly && _vm.$emit('create-row', { day: _vm.day })}}},[_c('i',{staticClass:"iconoir-plus"})]):_c('span',{staticClass:"calendar-month-day__read-only-filler"}),_vm._v(" "),_c('span',{ref:"dateLabel",staticClass:"calendar-month-day__date-label"},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),(_vm.visibleRowsCount)?_c('div',{staticClass:"calendar-month-day__cards"},_vm._l((_vm.visibleRows),function(row){return _c('CalendarCard',_vm._g({key:row.id,attrs:{"row":row,"fields":_vm.fields,"store-prefix":_vm.storePrefix,"decorations-by-place":_vm.decorationsByPlace}},_vm.$listeners))}),1):_vm._e(),_vm._v(" "),(_vm.hiddenRowsCount > 0 && _vm.visibleRowsCalculated)?_c('a',{staticClass:"calendar-month-day__more",on:{"click":_vm.expand}},[_vm._v("\n    "+_vm._s(_vm.$tc('calendarMonthDay.hiddenRowsCount', _vm.hiddenRowsCount, {
        hiddenRowsCount: _vm.hiddenRowsCount,
      }))+"\n  ")]):_vm._e(),_vm._v(" "),_c('CalendarMonthDayExpanded',_vm._g({ref:"calendarMonthDayExpanded",attrs:{"day":_vm.day,"fields":_vm.fields,"store-prefix":_vm.storePrefix,"parent-width":_vm.width,"parent-height":_vm.height,"decorations-by-place":_vm.decorationsByPlace}},_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }