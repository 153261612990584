var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-settings__group"},[_c('h2',{staticClass:"admin-settings__group-title"},[_vm._v("\n    "+_vm._s(_vm.$t('enterpriseSettings.branding'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"admin-settings__item"},[_c('div',{staticClass:"admin-settings__label"},[_c('div',{staticClass:"admin-settings__name"},[_vm._v("\n        "+_vm._s(_vm.$t('enterpriseSettings.showBaserowHelpMessage'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"admin-settings__description"},[_vm._v("\n        "+_vm._s(_vm.$t('enterpriseSettings.showBaserowHelpMessageDescription'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"admin-settings__control"},[_c('SwitchInput',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          !_vm.$hasFeature(_vm.ENTERPRISE_SETTINGS)
            ? _vm.$t('enterpriseSettings.deactivated')
            : null
        ),expression:"\n          !$hasFeature(ENTERPRISE_SETTINGS)\n            ? $t('enterpriseSettings.deactivated')\n            : null\n        "}],attrs:{"value":_vm.$hasFeature(_vm.ENTERPRISE_SETTINGS)
            ? _vm.settings.show_baserow_help_request
            : true,"disabled":!_vm.$hasFeature(_vm.ENTERPRISE_SETTINGS)},on:{"input":function($event){return _vm.updateSettings({ show_baserow_help_request: $event })}}},[_vm._v(_vm._s(_vm.$t('settings.enabled')))])],1)]),_vm._v(" "),_c('div',{staticClass:"admin-settings__item"},[_c('div',{staticClass:"admin-settings__label"},[_c('div',{staticClass:"admin-settings__name"},[_vm._v("\n        "+_vm._s(_vm.$t('enterpriseSettings.coBrandingLogo'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"admin-settings__description"},[_vm._v("\n        "+_vm._s(_vm.$t('enterpriseSettings.coBrandingLogoDescription'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"admin-settings__control"},[(
          _vm.settings.co_branding_logo === null ||
          !_vm.$hasFeature(_vm.ENTERPRISE_SETTINGS)
        )?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          !_vm.$hasFeature(_vm.ENTERPRISE_SETTINGS)
            ? _vm.$t('enterpriseSettings.deactivated')
            : null
        ),expression:"\n          !$hasFeature(ENTERPRISE_SETTINGS)\n            ? $t('enterpriseSettings.deactivated')\n            : null\n        "}],attrs:{"disabled":!_vm.$hasFeature(_vm.ENTERPRISE_SETTINGS)},on:{"click":function($event){_vm.$hasFeature(_vm.ENTERPRISE_SETTINGS) && _vm.openLogoUploadModal()}}},[_vm._v("\n        "+_vm._s(_vm.$t('enterpriseSettings.uploadFileButton'))+"\n      ")]):_c('Thumbnail',{attrs:{"removable":"","src":_vm.settings.co_branding_logo.url},on:{"remove":function($event){return _vm.updateSettings({ co_branding_logo: null })}}}),_vm._v(" "),_c('UserFilesModal',{ref:"userFilesModal",attrs:{"multiple-files":false,"file-types-acceptable":_vm.IMAGE_FILE_TYPES},on:{"uploaded":_vm.fileUploaded}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }