<template>
  <Alert type="warning">
    <p>{{ $t('exportWorkspaceModalWarning.message') }}</p>
  </Alert>
</template>

<script>
export default {
  name: 'ExportWorkspaceModalWarning',
}
</script>
