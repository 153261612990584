<template>
  <Badge class="saas-dashboard__plan-badge" bold :color="badgeColor">
    {{ $t(`workspacePlanTier.${workspaceUsage.plan_tier}`) }}
  </Badge>
</template>

<script>
import usage from '@saas/mixins/usage'

export default {
  name: 'DashboardWorkspacePlanBadge',
  components: {},
  mixins: [usage],
  props: {
    workspace: {
      type: Object,
      required: true,
    },
    componentArguments: {
      type: Object,
      required: true,
    },
  },
  computed: {
    workspaceUsage() {
      return this.componentArguments.usageData.find(
        (u) => u.workspace_id === this.workspace.id
      )
    },
    badgeColor() {
      switch (this.workspaceUsage.plan_tier) {
        case 'free':
          return 'yellow'
        case 'premium':
          return 'cyan'
        case 'enterprise':
          return 'neutral'
        case 'advanced':
        case 'trial_advanced':
          return 'purple'
        default:
          return 'neutral'
      }
    },
  },
}
</script>
