<template>
  <header class="layout__col-2-1 header">
    <div class="header__loading"></div>
  </header>
</template>

<script>
export default {
  name: 'PageSkeleton',
}
</script>
